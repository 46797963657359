import React from 'react';

export enum ESize {
    SMALL = "small",
    MEDIUM = "medium",
    LARGE = "large",
    HUGE = "huge"
}

interface H2Props {
    children: React.ReactNode;
    size?: ESize;
    extra?: string;
}

export const H2: React.FC<H2Props> = ({ children, size = ESize.MEDIUM, extra }) => {
    let fontSize;
    switch (size) {
        case ESize.SMALL:
            fontSize = 'text-sm';
            break;
        case ESize.LARGE:
            fontSize = 'text-xl';
            break;
        case ESize.HUGE:
            fontSize = 'text-2xl';
            break;
        default:
            fontSize = 'text-lg';
    }
    return <h2 className={`${fontSize} font-semibold dark:text-[#e9ecef] ${extra}`}> { children }</h2>;
};