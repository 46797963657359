import React from 'react';

export enum ESizeText {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  HUGE = 'huge',
}

export enum EWeightText {
  NORMAL = 'normal',
  MEDIUM = 'medium',
  BOLD = 'bold',
}

interface TextProps {
  children: React.ReactNode;
  size?: ESizeText;
  weight?: EWeightText;
  color?: string;
  extra?: string;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
}

export const Text: React.FC<TextProps> = ({
  children,
  size = ESizeText.MEDIUM,
  weight = EWeightText.NORMAL,
  color = 'text-darkBlue dark:text-lightGray',
  extra,
  onClick,
}) => {
  let fontSize;
  let weightText;

  switch (size) {
    case ESizeText.SMALL:
      fontSize = 'text-sm';
      break;
    case ESizeText.LARGE:
      fontSize = 'text-xl';
      break;
    case ESizeText.HUGE:
      fontSize = 'text-2xl';
      break;
    default:
      fontSize = 'text-base';
  }

  switch (weight) {
    case EWeightText.MEDIUM:
      weightText = 'font-medium';
      break;
    case EWeightText.BOLD:
      weightText = 'font-bold';
      break;
    default:
      weightText = 'font-normal';
  }

  return (
    <span
      className={`${fontSize} ${weightText} ${color} ${extra}`}
      onClick={onClick}
    >
      {' '}
      {children}
    </span>
  );
};
