import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Checkbox } from 'shared/Checkbox';
import { Input } from 'shared/Input';
import { SubmitButton } from 'shared/SubmitButton';
import { Text, ESizeText, EWeightText } from 'shared/Text';

import Loader from 'entities/Loader/Loader';
import { Modal } from 'shared/Modal';
import { GoogleButton } from 'shared/GoogleButton';
import { fetchData } from 'app/utils/fetch/request';
import { Message } from 'app/types/messages';
import { TimeoutMessage } from 'widgets/TimeoutMessage';

interface SignInProps {
  setIsRegistered?: (value: boolean) => void;
}

export default function SignIn({ setIsRegistered }: SignInProps) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [keepLoggedIn, setKeepLoggedIn] = useState(false);
  const [error, setError] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isUsernameValid, setIsUsernameValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetLoading, setResetLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState<Message>();

  const navigate = useNavigate();

  // Effect to check if all fields are filled

  useEffect(() => {
    if (username && password && isUsernameValid) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [username, password, isUsernameValid]);

  const handleSignIn = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    if (!username || !password) {
      setError('Please fill in all fields.');
      setIsLoading(false);
      return;
    }

    try {
      const loginField = username.includes('@')
        ? { email: username }
        : { username };

      const logicResponse = await fetch(
        `${import.meta.env.VITE_APP_USER_API}/auth/login`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...loginField,
            password,
          }),
        },
      );

      if (!logicResponse.ok) {
        if (logicResponse.status === 401) {
          throw new Error('Invalid credentials');
        } else {
          throw new Error('Failed to sign in');
        }
      }

      const login = await logicResponse.json();

      const payloadResponse = await fetch(
        `${import.meta.env.VITE_APP_USER_API}/auth/payload`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${login.accessToken}`,
          },
        },
      );

      if (!payloadResponse.ok) {
        throw new Error('Failed to fetch user data');
      }

      const payload = await payloadResponse.json();
      // payload.subscriptionTitle = 'rrr';
      
      console.log(JSON.stringify(payload, null, 2));

      Cookies.set('accessToken', login.accessToken, {
        expires: 1,
      });
      Cookies.set('profileId', payload.mongoUserId, {
        expires: 1,
      });
      Cookies.set('username', payload.username, {
        expires: 1,
      });
      Cookies.set('email', payload.email, {
        expires: 1,
      });
      Cookies.set('subscriptionTitle', payload.subscriptionTitle, {
        expires: 1,
      });
      Cookies.set('isVerified', payload.isVerified, {
        expires: 1,
      });

      // navigate('/');

      if (setIsRegistered && payload.subscriptionTitle !== 'Premium') {
        setIsRegistered(true);
      } else {
        navigate('/');
      }

    } catch (error: unknown) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unknown error occurred');
      }
      console.error('Error signing in:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {statusMessage && <TimeoutMessage messages={statusMessage} />}
      <Modal
        title="Password reset"
        isOpen={isResetModalOpen}
        onClose={() => {
          setIsResetModalOpen(false);
        }}
        submitHandler={async () => {
          setResetLoading(true);
          try {
            await fetchData(
              `${import.meta.env.VITE_APP_USER_API}/auth/reset`,
              'POST',
              undefined,
              { email: resetEmail },
            );
            setStatusMessage({
              text: 'Password was updated successfully. Check your email.',
              type: 'success',
            });
          } catch (err) {
            setStatusMessage({
              text: 'Error changing password',
              type: 'error',
            });
            console.error(err);
          }
          setResetLoading(false);
          setIsResetModalOpen(false);
          setResetEmail('');
        }}
        isSubmitDisabled={!resetEmail}
        isSubmitLoading={resetLoading}
      >
        <div>
          <Text
            size={ESizeText.SMALL}
          >
            Enter your username or email. You will receive a message with a new
            password by email.
          </Text>
        </div>
        <Input
          type="email"
          autoComplete="email"
          value={resetEmail}
          onChange={(e) => {
            setResetEmail(e.target.value);
          }}
          extra='mt-4'
        />
      </Modal>

      <div className="flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
        {/* Sign in section */}
        <div className="relative w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px] sm-max:mt-0">
          {isLoading && <Loader />}
          <h4 className="mb-2.5 text-4xl font-bold text-normalBlue dark:text-white">
            Sign In
          </h4>
          <p className="mb-6 ml-1 text-base text-lightBlue">
            Enter your email and password to sign in!
          </p>
          {error && (
            <p className="mb-4 text-sm font-medium text-red-500">{error}</p>
          )}
          <form onSubmit={handleSignIn} className="flex flex-col gap-2">
            {/* Login */}
            <Input
              extra=""
              label="Username or Email*"
              placeholder="username or email"
              id="login"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              shouldValidate={true}
              setSubmitActive={setIsUsernameValid}
            />

            {/* Password */}
            <Input
              extra=""
              label="Password*"
              placeholder="password"
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              shouldValidate={false}
            />

            {/* Checkbox */}
            <div className="mb-2 flex items-center justify-between px-2">
              <div className="flex items-center">
                <Checkbox
                  checked={keepLoggedIn}
                  onChange={() => setKeepLoggedIn(!keepLoggedIn)}
                />
                <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                  Keep me logged In
                </p>
              </div>
              <Text
                size={ESizeText.SMALL}
                weight={EWeightText.MEDIUM}
                extra="text-lightBlue hover:text-darkBlue cursor-pointer transition duration-200 dark:text-white dark:hover:text-white dark:hover:text-lightBlue"
                onClick={() => {
                  setIsResetModalOpen(true);
                }}
              >
                Forgot Password?
              </Text>
            </div>
            <SubmitButton
              label="Sign In"
              isActive={!isButtonDisabled}
              extra="py-3"
            />
          </form>
          <div className="my-4">
            <Text
              size={ESizeText.SMALL}
              weight={EWeightText.MEDIUM}
              extra="text-darkBlue"
            >
              Not registered yet?
            </Text>
            <a
              href="/auth/sign-up"
              className="ml-1 text-sm font-medium transition duration-200 text-lightBlue hover:text-darkBlue dark:text-white dark:hover:text-lightBlue"
            >
              Create an account
            </a>
          </div>
          <GoogleButton />
        </div>
      </div>
    </>
  );
}
