function Card(props: { children?: React.ReactNode }) {
  const { children } = props;
  return (
    <div
      // className={`relative flex flex-col rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none`}
      className={`relative flex flex-col rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-darkBlue dark:text-white dark:shadow-none`}
    >
      {children}
    </div>
  );
}

export default Card;
