import { IAgentRequest } from 'app/types';
import { IModelVersion } from 'app/types/models';
import { ChangeEvent } from 'react';
import { Popover } from 'shared/Popover';
import { Slider } from 'shared/Slider';
import { H1, ESizeHeader } from 'shared/H1';
import { formatNumber } from 'app/utils/formatNumber/formatNumber';
import { Textarea } from 'shared/Textarea';
import { IoIosSettings } from 'react-icons/io';
import { FaPlus } from 'react-icons/fa6';
import { IPrompt } from 'app/types/prompt';
import { Text } from 'shared/Text';
import { truncateText } from 'app/utils/truncateText/truncateText';

interface IPromptSettings {
  agent: IAgentRequest;
  setAgent: React.Dispatch<React.SetStateAction<IAgentRequest>>;
  models: IModelVersion[];
  prompts: IPrompt[];
}

export const PromptSettings: React.FC<IPromptSettings> = ({
  agent,
  setAgent,
  models,
  prompts,
}) => {
  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setAgent((prev) => ({
      ...prev,
      prompt: {
        ...prev.prompt,
        [name]: Number(value),
      },
    }));
  };

  const selectedModel = models.find(
    (model) => model?._id === agent.languageModelVersion,
  );

  const handleModelChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;

    setAgent((prev) => ({
      ...prev,
      languageModelVersion:
        models.find((model) => model.versionId === value)?._id || '',
    }));
  };

  return (
    <div className="px-2 h-[80%] sm:h-[100px]">
      <header className="flex justify-between items-center h-8 text-center text-lg sm:text-xl font-bold">
        <div>Prompt</div>
        <div className="flex sm:gap-2">
          <Popover
            trigger={
              <div
                className="w-8 h-8 flex justify-center items-center cursor-pointer transition duration-200 rounded-lg hover:bg-lightBlue/20"
              >
                <FaPlus className="w-5 h-5" />
              </div>
            }
            extra="border border-lightGray/70"
          >
            <div className="min-w-60">
              {prompts.length > 0 ? (
                prompts.map((prompt) => (
                  <div
                    key={prompt._id}
                    className="flex justify-between items-center h-8"
                  >
                    <Text extra="">{truncateText(prompt.name, 12)}</Text>
                    <div
                      className="w-5 h-5 flex justify-center items-center cursor-pointer rounded-full hover:bg-gray-100"
                      onClick={() => {
                        setAgent((prev) => ({
                          ...prev,
                          prompt: {
                            ...prev.prompt,
                            system: prompt.content,
                          },
                        }));
                      }}
                    >
                      <FaPlus className="w-3 h-3" />
                    </div>
                  </div>
                ))
              ) : (
                <div>No prompts</div>
              )}
            </div>
          </Popover>
          <Popover
            trigger={
              <div
                className="w-8 h-8 flex justify-center items-center cursor-pointer transition duration-200 rounded-lg hover:bg-lightBlue/20"
              >
                <IoIosSettings className="w-5 h-5" />
              </div>
            }
            extra="flex flex-col gap-5 border border-lightGray/70 pb-4"
          >
            
            <div className="flex flex-col gap-2">
                <select
                  onChange={handleModelChange}
                  value={selectedModel?.versionId}
                className="my-1 block w-full px-3 py-2 bg-white border border-lightGray/70 rounded-md shadow-sm focus:outline-none focus:border-normalGray/70 dark:bg-darkBlue focus:bg-lightGray/10 dark:border-lightGray/30 dark:focus:border-normalGray/70 sm:text-sm"
                >
                  {models &&
                    models.map((model) => (
                      <option key={model._id} value={model.versionId}>
                        {model.versionId} (
                        {formatNumber(model?.contextWindowLimit)})
                      </option>
                    ))}
                </select>

                <div className="w-96 sm-max:w-72">
                  <H1 size={ESizeHeader.SMALL} extra="mb-1 sm-max:text-base">Temperature</H1>
                  <Slider
                    value={agent.prompt?.temperature}
                    min={0}
                    max={1}
                    step={0.1}
                    name="temperature"
                    onChange={handleSliderChange}
                  />
                </div>
                <div className="w-96 sm-max:w-72">
                  <H1 size={ESizeHeader.SMALL} extra="mb-1 sm-max:text-base">Max Tokens</H1>
                  <Slider
                    value={
                      (agent.prompt?.maxTokens && agent.prompt?.maxTokens) || 4096
                    }
                    min={0}
                    max={4096}
                    step={32}
                    name="maxTokens"
                    onChange={handleSliderChange}
                  />
                </div>
            </div>
          </Popover>
        </div>
      </header>
      <div className="h-full">
        <Textarea
          name="prompt"
          label="Prompt"
          placeholder="Enter prompt"
          value={agent.prompt?.system || ''}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            setAgent((prev) => ({
              ...prev,
              prompt: {
                ...prev.prompt,
                system: e.target.value,
              },
            }));
          }}
          extra="dark:bg-darkBlue/100 dark:border-normalBlue dark:focus:border-lightGray/60"
        />
      </div>
    </div>
  );
};
