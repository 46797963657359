import React from 'react';
import { H1, ESizeHeader } from 'shared/H1';
import LogoLight from './components/LogoLight.svg';
import LogoDark from './components/LogoDark.svg';

const WelcomePage: React.FC = () => {
    const supportEmail = 'wealthmand@icloud.com';
    const emailBody = `Dear Support Team,%0D%0A%0D%0AI would like to request a one-on-one demo session. Please let me know the available time slots for the demo.%0D%0A%0D%0AThank you,`;

    return (
        <div className="fixed flex h-screen w-full items-center justify-center bg-gray-100 dark:bg-gray-900">
            <div className="bg-white h-screen w-full flex items-center justify-center p-8 dark:bg-navy-800 text-center">
                <div className="m-auto pb-32 xl:pb-10">
                    <img
                        src={LogoLight}
                        alt="ShmoneLoops"
                        className="w-[300px] m-auto mb-16 dark:hidden"
                    />
                    <img
                        src={LogoDark}
                        alt="ShmoneLoops"
                        className="hidden w-[300px] m-auto mb-16 dark:block"
                    />
                    <H1 size={ESizeHeader.SMALL} extra="mb-7 dark:text-white">Welcome to ShmoneLoops</H1>

                    <div className="text-lg font-semibold leading-[22px] dark:text-white">
                        <p>You have been added to ShmoneLoops.</p>
                        <p>Your account will be accessed in your 1:1 onboarding.</p>
                        <p>For questions, please reach out to our <a
                                href={`mailto:${supportEmail}?subject=Subscription%20Request&body=${emailBody}`} 
                                className="cursor-pointer relative after:absolute after:bottom-0 after:left-0 after:right-0 after:h-[2px] after:bg-black"
                            >
                                Support.
                            </a>
                        </p>
                    </div>
                </div>
                
            </div>
        </div>
    );
};

export default WelcomePage;