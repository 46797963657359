import React from 'react';
import { AdvantageCard } from 'shared/AdvantageCard';
import { MdOutlineMoreHoriz } from "react-icons/md";

const AdvantagesList: React.FC = () => {
    return (
        <div className="w-full grid grid-cols-1 gap-7 lg:grid-cols-3 3xl:gap-8 4xl:gap-16">
            <AdvantageCard
                title="Agentic Workflow"
                description={["Build agents with", "tools and File Search"]}
                content={
                    <div className="w-full h-full flex flex-col justify-start">
                        <MdOutlineMoreHoriz className="text-[#C1C1CC] text-2xl lg:text-3xl" />
                        <div className="flex flex-col gap-2">
                            <div className="flex justify-end">
                                <div className="w-max p-2 pl-4 rounded-lg rounded-br-none bg-[#ACACBE] text-black text-[10px] leading-[12.1px] text-right lg:pl-2 lg:text-xs">What's our quarterly profit?</div>
                            </div>
                            <div className="flex justify-start">
                                <div className="w-[90%] p-2 rounded-lg rounded-bl-none bg-[#ECECF1] text-black text-[10px] leading-[12.1px] text-left md:w-[50%] lg:w-[80%] lg:text-xs">For Q3 2024, your profit is $503,485 Up 15% from last quarter.</div>
                            </div>
                        </div>
                    </div>
                }
                color="linear-gradient(52.4deg, #4F515E 0%, #4A4B53 28.49%, #505260 37%, #565869 100%)"
            />
            <AdvantageCard
                title="Intuitive Platform"
                description={["Tools seamlessly integrate, tracking", "and tasks made even easier"]}
                content={
                    <div className="w-full h-full flex flex-col justify-start">
                        <MdOutlineMoreHoriz className="text-[#6E6E80] text-2xl lg:text-3xl" />
                        <div className="flex flex-col gap-2 pl-1">
                            <span className="text-[10px] text-[#9B8C9E] font-semibold lg:text-xs">Website Assistant</span>
                            <div className="flex items-start gap-5">
                                <div className="flex flex-col gap-1 text-[#9B8C9E] font-medium">
                                    <div className="flex items-center gap-2">
                                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.52149 0.0512695C2.25569 0.0512695 0.418892 1.88797 0.418892 4.15377C0.418892 6.41956 2.25569 8.25636 4.52149 8.25636C6.78719 8.25636 8.62399 6.41956 8.62399 4.15377C8.62399 1.88797 6.78719 0.0512695 4.52149 0.0512695ZM1.23937 4.15377C1.23937 2.34119 2.70881 0.871747 4.52149 0.871747C6.33407 0.871747 7.80351 2.34119 7.80351 4.15377C7.80351 5.96644 6.33407 7.43588 4.52149 7.43588C2.70881 7.43588 1.23937 5.96644 1.23937 4.15377Z" fill="#6E6E80" />
                                        </svg>
                                        <span className="text-[8px] lg:text-[10px]">Agent ID</span>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.23937 4.8923C1.23937 3.07972 2.70881 1.61028 4.52149 1.61028C6.33407 1.61028 7.80351 3.07972 7.80351 4.8923C7.80351 6.70497 6.33407 8.17441 4.52149 8.17441C2.70881 8.17441 1.23937 6.70497 1.23937 4.8923ZM4.52149 0.789795C2.25569 0.789795 0.418892 2.6265 0.418892 4.8923C0.418892 7.15809 2.25569 8.9949 4.52149 8.9949C6.78719 8.9949 8.62399 7.15809 8.62399 4.8923C8.62399 2.6265 6.78719 0.789795 4.52149 0.789795ZM4.93168 2.84105C4.93168 2.61449 4.74805 2.43076 4.52149 2.43076C4.29483 2.43076 4.1112 2.61449 4.1112 2.84105V4.72235L3.20575 5.6279C3.04545 5.7881 3.04545 6.04784 3.20575 6.20803C3.36594 6.36823 3.62569 6.36823 3.78588 6.20803L4.81156 5.18237C4.88846 5.10547 4.93168 5.0011 4.93168 4.8923V2.84105Z" fill="#6E6E80" />
                                        </svg>
                                        <span className="text-[8px] lg:text-[10px]">Period Time</span>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.90923 0.187926C5.13234 0.22731 5.28141 0.440082 5.24203 0.663196L5.09089 1.52031C5.0516 1.74342 4.83883 1.89239 4.61562 1.85311C4.3925 1.81372 4.24353 1.60094 4.28292 1.37783L4.43395 0.520723C4.47334 0.297609 4.68611 0.148639 4.90923 0.187926ZM1.99811 0.827627C2.18371 0.697756 2.43952 0.742853 2.56949 0.928453L3.04004 1.60055C3.17 1.78615 3.12491 2.04196 2.93931 2.17192C2.75371 2.30189 2.49791 2.2567 2.36794 2.0711L1.89729 1.399C1.76732 1.2134 1.81251 0.957596 1.99811 0.827627ZM3.52565 3.01347C3.65404 2.9225 3.8231 2.91284 3.96095 2.98886L8.16328 5.30507C8.28725 5.3735 8.3673 5.501 8.37488 5.6424C8.38246 5.78388 8.31649 5.91917 8.2005 6.0005L7.48272 6.50306L7.94972 7.18185C8.07743 7.36735 8.03164 7.62118 7.84712 7.75046L6.50096 8.69294C6.41185 8.75536 6.30157 8.77988 6.19445 8.76097C6.08732 8.74207 5.99201 8.68131 5.92959 8.59221L5.45894 7.92011L4.78871 8.38937C4.67341 8.47021 4.52474 8.48616 4.39487 8.43171C4.26499 8.37736 4.17215 8.26019 4.14881 8.12136L3.35836 3.41609C3.33227 3.26091 3.39725 3.10445 3.52565 3.01347ZM4.30812 4.11713L4.85035 7.3446L5.32444 7.01269C5.41355 6.95026 5.52383 6.92584 5.63095 6.94465C5.73818 6.96355 5.83339 7.02431 5.89581 7.11341L6.36646 7.78551L7.04319 7.31172L6.57618 6.63292C6.44848 6.44732 6.49426 6.19349 6.67878 6.06431L7.19068 5.70591L4.30812 4.11713ZM0.401363 3.34451C0.440748 3.12139 0.653524 2.97241 0.876638 3.0117L1.73355 3.16284C1.95666 3.20223 2.10573 3.415 2.06635 3.63811C2.02696 3.86123 1.81419 4.0102 1.59108 3.97092L0.734165 3.81978C0.510952 3.78049 0.361979 3.56762 0.401363 3.34451Z" fill="#6E6E80" />
                                        </svg>
                                        <span className="text-[8px] lg:text-[10px]">Tools Used</span>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-[2.5px]">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.649662 0.907715C0.876222 0.907715 1.05995 1.09184 1.05995 1.3183V2.95867C1.05995 3.18513 1.24358 3.36925 1.47014 3.36925H5.40279L4.46209 2.42894C4.3019 2.26845 4.3019 2.00852 4.46209 1.84802C4.62239 1.68852 4.88213 1.68852 5.04233 1.84802L6.68339 3.48938C6.84358 3.64987 6.84358 3.90981 6.68339 4.06931L5.04233 5.71067C4.88213 5.87116 4.62239 5.87116 4.46209 5.71067C4.3019 5.55018 4.3019 5.29024 4.46209 5.13073L5.40279 4.18944H1.47014C0.790461 4.18944 0.239372 3.63904 0.239372 2.95867V1.3183C0.239372 1.09184 0.423102 0.907715 0.649662 0.907715Z" fill="#6E6E80" />
                                        </svg>
                                        <span className="text-[8px] lg:text-[10px]">Chats over 24h</span>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-1 text-black text-[8px] font-medium lg:text-[10px]">
                                    <span>668690d078...</span>
                                    <span>24h</span>
                                    <span>121 tools used</span>
                                    <span>684 chats</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                color="linear-gradient(232.4deg, #9B8D9E 0%, #9B8D9E 100%)"
            />
            <AdvantageCard
                title="Your RAG Experience"
                description={["Parse, Upload, Edit Context and", "get your best experience in RAG"]}
                content={
                    <div className="w-full h-full flex flex-col justify-start">
                        <MdOutlineMoreHoriz className="text-[#C1C1CC] text-2xl lg:text-3xl" />
                        <div className="flex flex-col gap-2 pl-1">
                            <span className="text-black text-[10px] pl-2 font-medium lg:text-xs">Indexing files</span>
                            <div className="flex flex-col gap-2">
                                <div className="flex items-center gap-2 px-3 py-[5px] rounded-lg rounded-br-sm bg-[#486C8D3D]">
                                    <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.8139 2.56072L9.2906 0.175753C9.17129 0.0632291 9.00989 0 8.84133 0H1.24762C0.559674 0 0 0.529002 0 1.1792V13.8208C0 14.471 0.559674 15 1.24762 15H10.7524C11.4403 15 12 14.471 12 13.8208V2.98538C12 2.82603 11.9332 2.67328 11.8139 2.56072ZM10.6452 13.6871H1.35485V1.31295H7.95864V3.07805C7.95864 3.50555 8.32524 3.85195 8.77743 3.85195H10.6452V13.6871H10.6452Z" fill="#020202" />
                                        <path d="M2.51431 6.04191C2.51431 6.37746 2.80214 6.64949 3.15717 6.64949H8.84292C9.19795 6.64949 9.48578 6.37746 9.48578 6.04191C9.48578 5.70636 9.19795 5.43433 8.84292 5.43433H3.15717C2.8021 5.43433 2.51431 5.70636 2.51431 6.04191Z" fill="#020202" />
                                        <path d="M8.84292 9.07983H3.15717C2.80214 9.07983 2.51431 9.35187 2.51431 9.68742C2.51431 10.023 2.80214 10.295 3.15717 10.295H8.84292C9.19795 10.295 9.48578 10.023 9.48578 9.68742C9.48578 9.35187 9.19795 9.07983 8.84292 9.07983Z" fill="#020202" />
                                    </svg>
                                    <span className="text-black text-[6px] font-semibold lg:text-[8px]">Innovation_Insight__806843_ndx.txt</span>
                                </div>
                                <div className="flex items-center gap-2 px-3 py-[5px] rounded-lg rounded-br-sm bg-[#486C8D3D]">
                                    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.03039 3.25098H3.85346C3.51792 3.25098 3.24588 3.50488 3.24588 3.81805C3.24588 4.13123 3.51792 4.38513 3.85346 4.38513H6.03039C6.36594 4.38513 6.63797 4.13123 6.63797 3.81805C6.63797 3.50488 6.36594 3.25098 6.03039 3.25098Z" fill="#020202" />
                                        <path d="M6.03039 5.60742H3.85346C3.51792 5.60742 3.24588 5.86132 3.24588 6.1745C3.24588 6.48768 3.51792 6.74158 3.85346 6.74158H6.03039C6.36594 6.74158 6.63797 6.48768 6.63797 6.1745C6.63797 5.86132 6.36594 5.60742 6.03039 5.60742Z" fill="#020202" />
                                        <path d="M8.5849 9.57816V10.1304H8.89748C9.27657 10.1304 9.27657 9.91875 9.27657 9.84919C9.27657 9.78208 9.27657 9.57812 8.89748 9.57812H8.5849V9.57816Z" fill="#020202" />
                                        <path d="M13.1999 8.53459H12.2111V2.78635C12.2111 2.63763 12.148 2.49506 12.0352 2.39L9.65038 0.164037C9.53761 0.0590138 9.38507 0 9.22572 0H2.04875C1.39852 0 0.869598 0.493697 0.869598 1.10058V12.8994C0.869598 13.5063 1.39856 14 2.04875 14H11.0319C11.6821 14 12.2111 13.5063 12.2111 12.8994V11.7634H13.1998C13.7137 11.7634 14.1304 11.3746 14.1304 10.8949V9.40305C14.1304 8.92345 13.7138 8.53459 13.1999 8.53459ZM10.9306 12.7746H2.1501V1.22542H8.39151V2.87281C8.39151 3.27185 8.73799 3.59516 9.16537 3.59516H10.9306V8.53459H7.92598C7.41205 8.53459 6.99545 8.92345 6.99545 9.40308V10.8949C6.99545 11.3746 7.41205 11.7634 7.92598 11.7634H10.9306V12.7746ZM9.66095 9.84911C9.66095 10.2438 9.36834 10.4891 8.89742 10.4891H8.58484V10.8285C8.58484 10.916 8.50881 10.9871 8.41492 10.9871H8.37049C8.27656 10.9871 8.20049 10.916 8.20049 10.8285V9.37802C8.20049 9.29046 8.27656 9.21935 8.37049 9.21935H8.89742C9.36838 9.21931 9.66095 9.46059 9.66095 9.84911ZM10.6248 10.9871H10.0134C9.91942 10.9871 9.84335 10.916 9.84335 10.8285V9.37802C9.84335 9.29046 9.91938 9.21935 10.0134 9.21935H10.6248C11.1573 9.21935 11.5906 9.6163 11.5906 10.1043C11.5906 10.5911 11.1574 10.9871 10.6248 10.9871ZM13.0612 9.41938C13.0612 9.50709 12.9851 9.57805 12.8912 9.57805H12.2008V9.9581H12.789C12.8828 9.9581 12.959 10.029 12.959 10.1167V10.1582C12.959 10.2458 12.8828 10.3169 12.789 10.3169H12.2008V10.8284C12.2008 10.916 12.1247 10.9871 12.0308 10.9871H11.9864C11.8924 10.9871 11.8164 10.916 11.8164 10.8284V9.37798C11.8164 9.29042 11.8924 9.21931 11.9864 9.21931H12.8912C12.9851 9.21931 13.0612 9.29042 13.0612 9.37798V9.41938Z" fill="#020202" />
                                        <path d="M10.6248 9.57812H10.2277V10.6284H10.6248C10.9454 10.6284 11.2063 10.3932 11.2063 10.1044C11.2063 9.81418 10.9454 9.57812 10.6248 9.57812Z" fill="#020202" />
                                    </svg>
                                    <span className="text-black text-[6px] font-semibold lg:text-[8px]">August_CCFU_Conf_3824.pdf</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                color="linear-gradient(0deg, #486C8D, #486C8D)"
            />
        </div>
    );
};

export default AdvantagesList;