interface ITextareaProps {
  id?: string;
  name?: string;
  label?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  border?: boolean;
  minHeight?: string;
  maxHeight?: string;
  extra?: string;
}

const Textarea: React.FC<ITextareaProps> = ({
  id,
  name,
  label,
  value,
  onChange,
  placeholder,
  border = true,
  minHeight = "100px",
  maxHeight,
  extra,
}) => {
  return (
    <div key={id} className="h-full">
      {label && (
        <label
          htmlFor={id}
          className="text-sm text-lightBlue dark:text-white font-medium"
        >
          {label}
        </label>
      )}
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        style={{ minHeight, maxHeight }}
        className={`bg-transparent w-full h-full text-sm cursor-text text-darkBlue select-text rounded-xl px-3 py-2 leading-[22px] outline-none focus:!bg-lightBlue/5 dark:text-white dark:bg-darkBlue ${
          border ? 'border border-lightGray focus:border-lightBlue dark:border-lightBlue/70' : ''
        } ${extra}`}
        placeholder={placeholder}
      />
    </div>
  );
};

export default Textarea;
