import React, { useState, useRef, useEffect } from 'react';
import { FiCopy } from "react-icons/fi";
import { FaCheck } from "react-icons/fa6";
import ClipboardJS from 'clipboard';

interface ClipboardButtonProps {
  textToCopy: string;
  extra?: string;
}

const ClipboardButton: React.FC<ClipboardButtonProps> = ({ textToCopy, extra }) => {
  const [copied, setCopied] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const clipboard = new ClipboardJS(buttonRef.current!, {
      text: () => textToCopy,
    });

    clipboard.on('success', () => {
      setCopied(true);
      setTimeout(() => setCopied(false), 5000);
    });

    clipboard.on('error', (error) => {
      console.error('Failed to copy: ', error);
    });

    return () => {
      clipboard.destroy();
    };
  }, [textToCopy]);

  return (
    <button
      ref={buttonRef}
      className={`${extra} flex items-center justify-center p-2 text-normalBlue text-base rounded-lg lg:hover:bg-gray-100 focus:outline-none dark:text-lightGray lg:dark:hover:bg-normalBlue`}
    >
      {copied ? <FaCheck /> : <FiCopy />}
    </button>
  );
};

export default ClipboardButton;
