import { IKnowledge } from 'app/types/knowledge';
import { Message } from 'app/types/messages';
import { MouseEvent, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { SubmitButton } from 'shared/SubmitButton';

interface IFileUploadProps {
  setKnowledge: (value: React.SetStateAction<IKnowledge>) => void;
  setStatus: (value: React.SetStateAction<Message | undefined>) => void;
}

export const FileUpload: React.FC<IFileUploadProps> = ({
  setKnowledge,
  setStatus,
}) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setFile(acceptedFiles[0]);
    },
    [setFile],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleReset = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setFile(null);
  };

  const handleSubmit = async () => {
    if (!file) {
      console.error('No file selected');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    setLoading(true);

    try {
      const response = await fetch(
        import.meta.env.VITE_APP_USER_API + '/microservices/upload',
        {
          method: 'POST',
          body: formData,
        },
      );

      if (!response.ok) {
        throw new Error('Error uploading file');
      }

      const data = await response.text();
      setKnowledge((prev) => ({ ...prev, data }));
      setStatus({ text: 'Successful file parsing', type: 'success' });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setStatus({ text: 'File parsing error', type: 'error' });
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div className="h-full flex flex-col justify-between">
      <div className="flex justify-center items-center h-full py-8">
        <div
          {...getRootProps()}
          className="border-2 cursor-pointer border-dashed border-gray-300 rounded-lg p-2 text-center w-2/3 flex flex-col items-center justify-center"
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p className="text-gray-500">Drag the file here...</p>
          ) : (
            <p className="text-gray-500">
              Drag a file here or click to select a file
            </p>
          )}
          {file && (
            <div className="mt-4">
              <p className="text-gray-700">Selected file: {file.name}</p>
              <button
                onClick={handleReset}
                className="mt-2 px-4 py-2 bg-red-500 text-white rounded-lg"
              >
                Reset file
              </button>
            </div>
          )}
        </div>
      </div>
      <SubmitButton
        label="Upload"
        onClick={handleSubmit}
        isActive={!loading && !!file}
      />
    </div>
  );
};
