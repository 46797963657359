import { useState } from 'react';
import Cookies from 'js-cookie';
import { fetchData } from 'app/utils/fetch/request';

interface ILLMMessage {
  _id?: string;
  content: string;
  role: string;
}

interface ILLMResponse {
  model: string;
  message: ILLMMessage;
  finish_reason: string;
  usage: {
    input_tokens: number;
    completion_tokens: number;
    total_tokens: number;
  };
}

const isILLMResponse = (data: any): data is ILLMResponse => {
  return (
    typeof data === 'object' &&
    typeof data.model === 'string' &&
    typeof data.message === 'object' &&
    typeof data.message.content === 'string' &&
    typeof data.message.role === 'string' &&
    typeof data.finish_reason === 'string' &&
    typeof data.usage === 'object' &&
    typeof data.usage.input_tokens === 'number' &&
    typeof data.usage.completion_tokens === 'number' &&
    typeof data.usage.total_tokens === 'number'
  );
};

export const useChatAPI = (chatId?: string, agentId?: string) => {
  const [messages, setMessages] = useState<ILLMMessage[]>([]);
  const [typing, setTyping] = useState(false);

  const fetchChatHistory = async () => {
    if (chatId) {
      const token = Cookies.get('accessToken');
      const messages = await fetchData<ILLMMessage[]>(
        `${import.meta.env.VITE_APP_USER_API}/chats/${chatId}/messages`,
        'GET',
        token,
      );
      setMessages(messages);
    } else
      try {
        const assistantChat = Cookies.get('assistantChat');
        if (assistantChat) {
          setMessages(JSON.parse(assistantChat));
        }
      } catch (err) {
        console.error('CHAT HISTORY', err);
      }
  };

  const sendMessage = async (
    newMessage: string,
    setNewMessage: (message: string) => void,
  ) => {
    if (!newMessage.trim()) {
      return;
    }

    const requestMessage = newMessage;

    setNewMessage('');

    setMessages((val) => [...val, { role: 'user', content: requestMessage }]);
    setTyping(true);

    if (chatId)
      try {
        const token = Cookies.get('accessToken');
        const response = await fetchData<ILLMMessage>(
          `${import.meta.env.VITE_APP_USER_API}/messages/${chatId}`,
          'POST',
          token,
          { type: 'text', content: requestMessage, role: 'user' },
        );
        setMessages((val) => [...val, response]);
      } catch (err) {
        console.error('MESSAGE', err);
      }
    else
      try {
        const response = await fetch(
          `${import.meta.env.VITE_APP_USER_API}/agents/${
            agentId ? agentId : import.meta.env.VITE_APP_ASSISTANT_ID
          }/llm`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              messages: [
                ...messages,
                { content: requestMessage, role: 'user' },
              ],
            }),
          },
        );
        if (!response.ok) {
          throw new Error('Failed to get agents');
        }
        const data = await response.json();
        if (!isILLMResponse(data)) {
          throw new Error('Response format is incorrect');
        }
        setMessages((val) => [...val, data.message]);
      } catch (err) {
        console.error(err);
      }
    setTyping(false);
  };

  const clearMessages = async () => {
    if (chatId)
      try {
        const token = Cookies.get('accessToken');
        await fetchData(
          `${import.meta.env.VITE_APP_USER_API}/chats/${chatId}/messages`,
          'DELETE',
          token,
        );
        setMessages([]);
      } catch (err) {
        console.error(err);
      }
    else Cookies.remove('assistantChat');
    setMessages([]);
  };

  return {
    messages,
    typing,
    fetchChatHistory,
    sendMessage,
    clearMessages,
  };
};
