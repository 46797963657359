import React, { useState } from 'react';
// import defaultImage from './components/avatar.png';
import { FaEllipsisH } from "react-icons/fa";
import { TbPointFilled } from "react-icons/tb";
import { H2 } from 'shared/H2';
import { truncateText } from 'app/utils/truncateText/truncateText';

interface AgentCardProps {
    id: string;
    title: string;
    username: string;
    model: string;
    description?: string;
    creationDate?: string;
    onClick: () => void;
    onDelete: () => void;
    onEmbed?: () => void;
    menuChildren?: React.ReactNode;
}

const AgentCard: React.FC<AgentCardProps> = ({ id, title, username, model, description, creationDate, onClick, onDelete, onEmbed, menuChildren }) => {
    const [showMenu, setShowMenu] = useState<string | null>(null);
    return (
        <div
            className='relative w-full flex flex-col gap-3 bg-white shadow border rounded-lg p-4 text-sm hover:bg-lightGray/20 cursor-pointer transition duration-200 dark:bg-darkBlue dark:hover:bg-normalGray dark:hover:bg-opacity-20 dark:border-darkBlue'
            onClick={onClick}
        >
            <div className='flex justify-between'>
                <H2>{truncateText(title, 20)}</H2>
                <div className="relative">
                    <button
                        className='text-normalGray hover:text-normalBlue'
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowMenu(showMenu === id ? null : id);
                        }}
                    >
                        <FaEllipsisH />
                    </button>
                    {showMenu === id && (
                        <div
                            className="absolute right-0 z-10 mt-2 w-48 rounded-md bg-white shadow-lg"
                            onMouseLeave={() => setShowMenu(null)}
                        >
                            {menuChildren}
                            {onEmbed && (
                                <button
                                    className="block w-full px-4 py-2 text-left text-darkBlue rounded-md hover:bg-lightBlue/20"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onEmbed();
                                    }}
                                >
                                    Embed
                                </button>
                            )}
                            <button
                                className="block w-full px-4 py-2 text-left rounded-md text-red-500 hover:bg-red-100"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onDelete();
                                }}
                            >
                                Delete
                            </button>
                        </div>
                    )}
                </div>
            </div>
            
            <div className='flex justify-between gap-5'>
                <div>
                    {description && <p className='text-normalGray'
                        style={{
                            display: '-webkit-box',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}>{description}</p>}
                </div>
                {/* <div className='min-w-16 w-16'>
                    <img src={defaultImage} alt='Agent' className='bg-contain rounded-lg' />
                </div> */}
            </div>

            <div className='flex items-center gap-2 text-lightGray text-sm md-max:text-xs'>
                <p>{truncateText(model, 16)}</p>
                <TbPointFilled className='w-2 h-2'/>
                <p>created {creationDate}</p>
            </div>
            <p>{truncateText(username, 16)}</p>
        </div>
    );
};

export default AgentCard;