const Checkbox = (props: {
  onChange: () => void;
  checked?: boolean;
  extra?: string;
  // color?:
  //   | 'red'
  //   | 'blue'
  //   | 'green'
  //   | 'yellow'
  //   | 'orange'
  //   | 'teal'
  //   | 'navy'
  //   | 'lime'
  //   | 'cyan'
  //   | 'pink'
  //   | 'purple'
  //   | 'amber'
  //   | 'indigo'
  //   | 'gray';
}) => {
  const { extra, ...rest } = props;
  return (
    <input
      type="checkbox"
      className={`defaultCheckbox relative flex h-[20px] min-h-[20px] w-[20px] min-w-[20px] appearance-none items-center
      justify-center rounded-md border border-lightBlue/50 text-white/0 outline-none transition duration-[0.2s]
      checked:border-none checked:bg-normalBlue checked:text-white hover:cursor-pointer dark:border-lightBlue/90 dark:checked:bg-normalBlue ${extra}`}
      name="weekly"
      {...rest}
    />
  );
};

export default Checkbox;
