import { INote } from 'app/types/note';
import { fetchData } from 'app/utils/fetch/request';
import Cookies from 'js-cookie';

export const getAllNotes = async () => {
  const token = Cookies.get('accessToken');
  const profileId = Cookies.get('profileId');

  return await fetchData<INote[]>(
    `${import.meta.env.VITE_APP_USER_API}/profiles/${profileId}/knowledge-base`,
    'GET',
    token,
  );
};
