import { IActionForm } from 'app/types/actions';

export const initialActionForm: IActionForm = {
  name: '',
  type: 'notification',
  email: '',
  spreadsheetId: '',
  sheetName: '',
  calendarId: '',
  prompt: '',
};

export const ActionTypes = [
  { label: 'Notification', value: 'notification' },
  { label: 'Table', value: 'table' },
  { label: 'Calendar', value: 'calendar' },
  { label: 'Custom response', value: 'custom_response' },
  { label: 'Llm response', value: 'llm_response' },
];
