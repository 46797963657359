// SearchContext.tsx
import { createContext } from 'react';

interface SearchContextType {
  searchValue: string;
  setSearchValue: (value: string) => void;
}

const defaultState: SearchContextType = {
  searchValue: '',
  setSearchValue: () => {},
};

export const SearchContext = createContext<SearchContextType>(defaultState);
