import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { SubmitButton } from 'shared/SubmitButton';
import { H2, ESize } from 'shared/H2';

interface ModalProps {
  title: string;
  children: React.ReactNode;
  submitHandler?: () => void;
  isOpen: boolean;
  onClose: () => void;
  isDanger?: boolean;
  isSubmitDisabled?: boolean;
  isSubmitLoading?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  title,
  children,
  submitHandler,
  isOpen,
  onClose,
  isDanger = false,
  isSubmitDisabled = false,
  isSubmitLoading = false,
}) => {
  const [mouseDownInside, setMouseDownInside] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.body.style.overflow = 'hidden';
      window.addEventListener('keydown', handleKeyDown);
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);

  const handleMouseDown = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (e.target === e.currentTarget) {
      setMouseDownInside(false);
    } else {
      setMouseDownInside(true);
    }
  };

  const handleMouseUp = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (!mouseDownInside && e.target === e.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div
        className="w-full max-h-full max-w-lg rounded-lg bg-white shadow-lg sm-max:max-w-[370px] dark:bg-normalBlue"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex items-center justify-between p-4 border-b border-lightGray/50">
          <H2 size={ESize.LARGE}>{title}</H2>
          <button
            onClick={onClose}
            className="text-lightGray hover:text-normalGray dark:text-white/50 dark:hover:text-white"
          >
            <FaTimes size={24} />
          </button>
        </div>
        <div className="p-4 max-h-[85vh]">{children}</div>
        {submitHandler && (
          <div className="flex justify-end p-4 border-t border-lightGray/50">
            {isDanger ? (
              <button
                onClick={submitHandler}
                disabled={isSubmitDisabled}
                className={`rounded bg-red-500 px-4 py-2 text-white transition duration-200 ${
                  isSubmitDisabled
                    ? 'cursor-not-allowed opacity-50'
                    : 'hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75'
                }`}
              >
                Delete
              </button>
            ) : (
              <SubmitButton
                label="Submit"
                isLoading={isSubmitLoading}
                onClick={submitHandler}
                isActive={!isSubmitDisabled}
                extra="max-w-[20%] py-1 mx-4 dark:bg-darkBlue dark:hover:bg-black"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
