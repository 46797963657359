import React from 'react';
import clsx from 'clsx';

interface ColumnHeadersProps {
    columns: number;
    headers: string[];
}

const ColumnHeaders: React.FC<ColumnHeadersProps> = ({ columns, headers }) => {
    const gridClass = clsx({
        'grid-cols-1': columns === 1,
        'grid-cols-2': columns === 2,
        'grid-cols-3': columns === 3,
        'grid-cols-4': columns === 4,
        'grid-cols-5': columns === 5,
        'grid-cols-6': columns === 6,
        'grid-cols-7': columns === 7,
    });

    return (
        <div className={`grid ${gridClass} gap-4 pl-2 pr-6 py-2 rounded bg-lightGray/20 text-sm font-bold lg-max:text-xs`}>
            {headers.map((header, index) => (
                <p key={index} className="w-full">{header}</p>
            ))}
        </div>
    );
};

export default ColumnHeaders;