import { SwitchLayout } from 'shared/SwitchLayout';
import { Knowledge } from '../components/Knowledge';
import { Notes } from '../components/Notes';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IKnowledge } from 'app/types/knowledge';
import { INote } from 'app/types/note';
import { getAllKnowledge } from '../api/knowledge';
import { getAllNotes } from '../api/notes';
import { Message } from 'app/types/messages';
import { TimeoutMessage } from 'widgets/TimeoutMessage';
import { ContentWrapper } from 'widgets/ContentWrapper';

export const KnowledgePage = () => {
  const [knowledgeType, setKnowledgeType] = useState<string>('Knowledge');
  const [knowledge, setKnowledge] = useState<IKnowledge[]>([]);
  const [notes, setNotes] = useState<INote[]>([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState<Message>();
  const [error, setError] = useState<{
    text: string;
    type: 'knowledge' | 'notes';
  }>();

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(async () => {
      setLoading(true);
      const results = await Promise.allSettled([
        getAllKnowledge(),
        getAllNotes(),
      ]);
      setLoading(false);
      results.forEach((result, index) => {
        if (result.status === 'fulfilled') {
          if (index === 0) {
            setKnowledge(result.value as IKnowledge[]);
          } else if (index === 1) {
            setNotes(result.value as INote[]);
          }
        } else {
          if (index === 0) {
            setError({
              text: 'Error on getting knowledge',
              type: 'knowledge',
            });
          } else if (index === 1) {
            setError({
              text: 'Error on getting notes',
              type: 'notes',
            });
          }
          console.error(`Function ${index} rejected with`, result.reason);
        }
      });
    });
  }, []);

  return (
    <>
      {statusMessage && <TimeoutMessage messages={statusMessage} />}
      <ContentWrapper
        title="Knowledge"
        description="Add knowledge to your agents"
        sumbitLabel="New"
        sumbitHandler={() => {
          if (knowledgeType === 'Knowledge') {
            navigate('/admin/dashboard/knowledge/new');
          } else if (knowledgeType === 'Notes') {
            setIsAddModalOpen(true);
          }
        }}
      >
        <SwitchLayout
          setActiveTitle={setKnowledgeType}
          loading={loading}
          sides={[
            {
              title: 'Knowledge',
              component: (
                <Knowledge
                  error={error?.type === 'knowledge' ? error.text : ''}
                  knowledge={knowledge}
                  setKnowledge={setKnowledge}
                  setStatus={setStatusMessage}
                />
              ),
            },
            {
              title: 'Notes',
              component: (
                <Notes
                  notes={notes}
                  setNotes={setNotes}
                  error={error?.type === 'notes' ? error.text : ''}
                  isAddModalOpen={isAddModalOpen}
                  setIsAddModalOpen={setIsAddModalOpen}
                  setStatus={setStatusMessage}
                />
              ),
            },
          ]}
        />
      </ContentWrapper>
    </>
  );
};
