import { fetchData } from 'app/utils/fetch/request';
import { generateUniqueText } from 'app/utils/random/agent';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ResponsiveLayout } from 'shared/ResponsiveLayout';
import { Chat } from 'widgets/Chat';
import { IModelVersion } from 'app/types/models';
import { Connections } from './components/Connections';
import { IAgentRequest, IAgentResponse, ITrigger } from 'app/types';
import { PromptSettings } from './components/PromptSettings';
import Loader from 'entities/Loader/Loader';
import { EWeightText, Text } from 'shared/Text';
import { Input } from 'shared/Input';
import { getAllTriggers } from 'pages/Dashboard/api/triggers';
import { getAllNotes } from 'pages/Dashboard/api/notes';
import { getAllKnowledge } from 'pages/Dashboard/api/knowledge';
import { INote } from 'app/types/note';
import { IKnowledge } from 'app/types/knowledge';
import { FiEdit } from 'react-icons/fi';
import { TimeoutMessage } from 'widgets/TimeoutMessage';
import { Message } from 'app/types/messages';
import { getAllPrompts } from 'pages/Dashboard/api/prompt';
import { IPrompt } from 'app/types/prompt';
import { ContentWrapper } from 'widgets/ContentWrapper';
import { formatNumber } from 'app/utils/formatNumber/formatNumber';

interface IAgentLayoutProps {
  type: 'create' | 'update';
}

const uniqueText = generateUniqueText();

const getAgentStateFromResponse = (agent: IAgentResponse): IAgentRequest => {
  const languageModelVersion =
    typeof agent.languageModelVersion === 'object'
      ? agent.languageModelVersion?._id
      : agent.languageModelVersion || '';

  return {
    ...agent,
    languageModelVersion,
    knowledgeBase: agent.knowledgeBase?.map((el) => el) || [],
    knowledgeVector: agent.knowledgeVector?.map((el) => el) || [],
    triggers:
      agent.triggers
        ?.map((trigger) => trigger._id)
        .filter((id): id is string => id !== undefined) || [],
    prompt: {
      temperature: agent.prompt?.temperature || 0.5,
      maxTokens: agent.prompt?.maxTokens || 1024,
      system: agent.prompt?.system || '',
    },
  };
};

export const AgentControl: React.FC<IAgentLayoutProps> = ({ type }) => {
  document.documentElement.dir = 'agents';
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [statusMessage, setStatusMessage] = useState<Message>();

  const [temporaryName, setTemporaryName] = useState(uniqueText);
  const [temporaryDescription, setTemporaryDescription] = useState('');

  const initialAgentData: IAgentRequest = {
    name: temporaryName,
    description: temporaryDescription,
  };

  const [agent, setAgent] = useState<IAgentRequest>(initialAgentData);
  const [initialAgent, setInitialAgent] = useState<IAgentRequest | null>(
    initialAgentData,
  );
  const [isUpdated, setIsUpdated] = useState(false);
  const [triggers, setTriggers] = useState<ITrigger[]>([]);
  const [notes, setNotes] = useState<INote[]>([]);
  const [knowledge, setKnowledge] = useState<IKnowledge[]>([]);
  const [prompts, setPrompts] = useState<IPrompt[]>([]);

  const [models, setModels] = useState<IModelVersion[]>();

  useEffect(() => {
    setLoading(true);
    const getAllModels = async () => {
      const token = Cookies.get('accessToken');
      try {
        const url = `${import.meta.env.VITE_APP_USER_API}/models/versions`;
        const models = await fetchData<any[]>(url, 'GET', token);
        setModels(models);
        return models;
      } catch (error) {
        console.error('Error on fetching props:', error);
      }
    };

    const fetchAgentData = async (id: string) => {
      try {
        const token = Cookies.get('accessToken');
        const url = `${import.meta.env.VITE_APP_USER_API}/agents/${id}`;
        const agent = await fetchData<IAgentResponse>(url, 'GET', token);

        setAgent(getAgentStateFromResponse(agent));
        setInitialAgent(getAgentStateFromResponse(agent));
        setTemporaryName(agent.name);
        setTemporaryDescription(agent.description || '');
      } catch (error: any) {
        console.error('Error on fetching agent:', error.message);
      }
    };

    setTimeout(async () => {
      const results = await Promise.allSettled([
        getAllTriggers(),
        getAllNotes(),
        getAllKnowledge(),
        getAllPrompts(),
      ]);
      results.forEach((result, index) => {
        if (result.status === 'fulfilled') {
          if (index === 0) {
            setTriggers(result.value as ITrigger[]);
          } else if (index === 1) {
            setNotes(result.value as INote[]);
          } else if (index === 2) {
            setKnowledge(result.value as IKnowledge[]);
          } else if (index === 3) {
            setPrompts(result.value as IPrompt[]);
          }
        } else {
          console.error(`Function ${index} rejected with`, result.reason);
        }
      });

      const availableModels = await getAllModels();
      if (type !== 'create' && id) {
        await fetchAgentData(id);
      } else {
        const defaultAgent = {
          name: uniqueText,
          languageModelVersion: availableModels?.at(0)?._id || '',
          knowledgeBase: [],
          knowledgeVector: [],
          triggers: [],
          prompt: {
            temperature: 0.5,
            maxTokens: 1024,
            system: '',
          },
        };
        setAgent(defaultAgent);
        setInitialAgent(defaultAgent);
      }
      setLoading(false);
    });
  }, [id, type]);

  useEffect(() => {
    if (initialAgent && agent) {
      setIsUpdated(JSON.stringify(agent) !== JSON.stringify(initialAgent));
    }
  }, [agent, initialAgent]);

  const updateSubmitHandler = async () => {
    try {
      const token = Cookies.get('accessToken');
      const url = import.meta.env.VITE_APP_USER_API + `/agents/${id}`;
      setSubmitLoading(true);
      const updatedAgent = await fetchData<IAgentResponse>(url, 'PUT', token, {
        name: agent.name,
        description: agent.description,
        languageModelVersion: agent.languageModelVersion,
        knowledgeBase: agent.knowledgeBase,
        knowledgeVector: agent.knowledgeVector,
        triggers: agent.triggers,
        prompt: {
          system: agent.prompt?.system,
          temperature: agent.prompt?.temperature,
          maxTokens: agent.prompt?.maxTokens,
        },
      });
      setAgent(getAgentStateFromResponse(updatedAgent));
      setInitialAgent(getAgentStateFromResponse(updatedAgent));
      setTemporaryName(updatedAgent.name);
      setStatusMessage({
        text: 'Agent was successfully updated',
        type: 'success',
      });
      setTemporaryDescription(updatedAgent.description || '');
    } catch (error: any) {
      setStatusMessage({
        text: 'Updating agent error',
        type: 'error',
      });
      console.error('Error on fetching agent:', error.message);
    }
    setSubmitLoading(false);
  };

  const createSubmitHandler = async () => {
    try {
      const token = Cookies.get('accessToken');
      const profileId = Cookies.get('profileId');
      const url = import.meta.env.VITE_APP_USER_API + `/agents/${profileId}`;
      setSubmitLoading(true);
      const createdAgent = await fetchData<IAgentRequest>(
        url,
        'POST',
        token,
        agent,
      );
      setSubmitLoading(false);
      setStatusMessage({
        text: 'Agent was successfully created',
        type: 'success',
      });
      if (createdAgent?._id) {
        navigate('/admin/dashboard/agents/' + createdAgent?._id);
      }
    } catch (error: any) {
      setSubmitLoading(false);
      setStatusMessage({
        text: 'Creating agent error',
        type: 'error',
      });
      console.error('Error on creating agent:', error);
    }
  };

  const getModelName = (modelId: string) => {
    if (!models) return '';
    const model = models.find((model) => model._id === modelId);
    return model
      ? `${model.versionId} (${formatNumber(model.contextWindowLimit)})`
      : '';
  };

  return (
    <>
      {loading || !agent || !models ? (
        <Loader />
      ) : (
        <>
          {statusMessage && <TimeoutMessage messages={statusMessage} />}
          <ContentWrapper
            title={agent.name}
            description={agent.description || 'Empty description'}
            onBackClick={() => navigate('/admin/dashboard/agents')}
            submitLoading={submitLoading}
            sumbitLabel={type === 'create' ? 'Create' : 'Update'}
            sumbitHandler={
              type === 'update' ? updateSubmitHandler : createSubmitHandler
            }
            isSubmitActive={(type === 'create' || isUpdated) && !!agent.name}
            modalTitle="Edit name"
            modalButton={
              <div className="flex justify-center items-center w-8 h-8 transition duration-200 rounded-lg hover:bg-lightBlue/20">
                <FiEdit className="w-5 h-5" />
              </div>
            }
            modalContent={
              <div className="flex flex-col gap-4">
                <Input
                  label="Name"
                  type="text"
                  onChange={(e) => {
                    setTemporaryName(e.target.value);
                  }}
                  value={temporaryName}
                />
                <Input
                  label="Description"
                  type="text"
                  onChange={(e) => {
                    setTemporaryDescription(e.target.value);
                  }}
                  value={temporaryDescription}
                />
              </div>
            }
            modalSubmitHandler={() => {
              setAgent({
                ...agent,
                name: temporaryName,
                description: temporaryDescription,
              });
            }}
            modalCloseHandler={() => {
              setTemporaryName(agent?.name);
              setTemporaryDescription(agent?.description || '');
            }}
          >
            <ResponsiveLayout
              leftSide={{
                title: 'left',
                component: (
                  <PromptSettings
                    agent={agent}
                    setAgent={setAgent}
                    prompts={prompts}
                    models={models}
                  />
                ),
              }}
              centerSide={{
                title: 'center',
                component: (
                  <Connections
                    agent={agent}
                    setAgent={setAgent}
                    triggers={triggers}
                    notes={notes}
                    knowledge={knowledge}
                  />
                ),
              }}
              rightSide={{
                title: 'right',
                component: (
                  <>
                    {type === 'update' ? (
                      <Chat
                        agentId={id}
                        extra="h-full"
                        model={getModelName(agent.languageModelVersion || '')}
                      />
                    ) : (
                      <div className="w-full h-full flex justify-center items-center">
                        <Text weight={EWeightText.BOLD}>
                          no chat in create mode
                        </Text>
                      </div>
                    )}
                  </>
                ),
              }}
            />
          </ContentWrapper>
        </>
      )}
    </>
  );
};
