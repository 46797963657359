import React from 'react';
import { Block } from 'shared/Block';
import Link from 'shared/Link/Link';

interface BlockLinkProps {
  children: React.ReactNode;
  href: string;
}

const BlockLink: React.FC<BlockLinkProps> = ({ children, href }) => {
  return (
    <Link to={href} target="_self">
      <Block>{children}</Block>
    </Link>
  );
};

export default BlockLink;
