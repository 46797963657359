import React from 'react';
import { ClipboardButton } from 'shared/ClipboardButton';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';

interface CodeBlockProps {
    text: string;
    language?: string;
}

const CodeBlock: React.FC<CodeBlockProps> = ({ text, language = 'javascript' }) => {
    return (
        <div className="relative">
            <ClipboardButton textToCopy={text} extra="absolute right-0 text-white lg:hover:bg-opacity-0 dark:lg:hover:bg-opacity-0" />
            <SyntaxHighlighter
                style={dark}
                language={language}
                PreTag="div"
                customStyle={{
                    backgroundColor: '#293358',
                    borderRadius: '5px',
                    border: 'none',
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    overflowX: 'auto',
                    marginTop: '0',
                    scrollbarWidth: 'thin',
                    scrollbarColor: 'rgba(255, 255, 255, 0.5) transparent',
                    fontFamily: '"Ubuntu Mono", monospace',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
                    paddingLeft: '12px',
                    paddingRight: '20px',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    
                }}
                codeTagProps={{
                    className: 'scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-transparent !whitespace-pre-wrap break-words break-all',
                }}
            >
                {text}
            </SyntaxHighlighter>
        </div>
    );
};

export default CodeBlock;