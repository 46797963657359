import { FcGoogle } from "react-icons/fc";

export const GoogleButton = () => {
  return (
    <button
      className="relative w-full px-3 py-1 flex items-center justify-center rounded-xl transition duration-200 bg-white hover:bg-lightGray/30 active:bg-lightGray/50 border border-lightGray/70 hover:border-lightGray/20 dark:bg-normalGray/40 dark:hover:bg-darkBlue dark:active:bg-[#233657] dark:border-normalGray/0 dark:hover:border-darkBlue"
      onClick={() => {
        window.location.href = `${import.meta.env.VITE_APP_USER_API}/auth/google/login`;
      }}
    >
      <div className="absolute left-3">
        <FcGoogle size={20} />
      </div>
      <span className="flex-1 font-medium text-normalBlue dark:text-white">Login Google</span>
    </button>
  );
};