import { SwitchLayout } from 'shared/SwitchLayout';
import { Triggers } from '../components/Triggers';
import { useEffect, useState } from 'react';
import { IAction } from 'app/types/actions';
import { Actions } from '../components/Actions';
import { getAllActions } from '../api/actions';
import { getAllTriggers } from '../api/triggers';
import { ITrigger } from 'app/types';
import { TimeoutMessage } from 'widgets/TimeoutMessage';
import { Message } from 'app/types/messages';
import { ContentWrapper } from 'widgets/ContentWrapper';

export const TriggerPage = () => {
  const [loading, setLoading] = useState(false);
  const [activeType, setActiveType] = useState<string>('Triggers');
  const [actions, setActions] = useState<IAction[]>([]);
  const [modalActive, setModalActive] = useState<'create' | 'update' | false>(
    false,
  );
  const [triggers, setTriggers] = useState<ITrigger[]>([]);
  const [statusMessage, setStatusMessage] = useState<Message>();
  const [error, setError] = useState<{
    text: string;
    type: 'triggers' | 'actions';
  }>();

  useEffect(() => {
    setTimeout(async () => {
      setLoading(true);
      const results = await Promise.allSettled([
        getAllTriggers(),
        getAllActions(),
      ]);
      setLoading(false);
      results.forEach((result, index) => {
        if (result.status === 'fulfilled') {
          if (index === 0) {
            setTriggers(result.value as ITrigger[]);
          } else if (index === 1) {
            setActions(result.value as IAction[]);
          }
        } else {
          if (index === 0) {
            setError({ text: 'Error on getting triggers', type: 'triggers' });
          } else if (index === 1) {
            setError({ text: 'Error on getting actions', type: 'actions' });
          }
        }
      });
    });
  }, []);

  return (
    <>
      {statusMessage && <TimeoutMessage messages={statusMessage} />}
      <ContentWrapper
        title="Triggers"
        description="Add events to agent behavior"
        sumbitLabel="New"
        sumbitHandler={() => {
          setModalActive('create');
        }}
      >
        <SwitchLayout
          setActiveTitle={setActiveType}
          loading={loading}
          sides={[
            {
              title: 'Triggers',
              component: (
                <Triggers
                  actions={actions}
                  setStatus={setStatusMessage}
                  error={error?.type === 'triggers' ? error.text : ''}
                  triggers={triggers}
                  setTriggers={setTriggers}
                  modalActive={activeType === 'Triggers' && modalActive}
                  setModalActive={setModalActive}
                />
              ),
            },
            {
              title: 'Actions',
              component: (
                <Actions
                  actions={actions}
                  setStatus={setStatusMessage}
                  error={error?.type === 'actions' ? error.text : ''}
                  setActions={setActions}
                  modalActive={activeType === 'Actions' && modalActive}
                  setModalActive={setModalActive}
                />
              ),
            },
          ]}
        />
      </ContentWrapper>
    </>
  );
};
