import React from 'react';
import { useNavigate } from 'react-router-dom';
import { H2 } from 'shared/H2';
import { Text, ESizeText } from 'shared/Text';

interface CodeCardProps {
    title: string;
    description: string;
    code: string;
}

const CodeCard: React.FC<CodeCardProps> = ({ title, description, code }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/admin/api');
    };

    const renderCodeLines = (code: string) => {
        return code
            .trim()
            .split('\n')
            .map((line, index) => (
                <div key={index} className="flex gap-8">
                    <span className="w-8 text-right text-normalGray">{index + 1}</span>
                    <span className="flex-1" dangerouslySetInnerHTML={{ __html: styleCode(line) }} />
                </div>
            ));
    };

    const styleCode = (line: string) => {
        const styledLine = line
            .replace(/(".*?")/g, '<span class="text-[#00660A] dark:text-[#7ad483]">$&</span>')
            .replace(/('.*?')/g, '<span class="text-[#00660A] dark:text-[#7ad483]">$&</span>');
        return styledLine;
    };

    return (
        <div
            className="w-full px-3 py-2 md:px-12 md:py-8 m-auto flex flex-col gap-3 shadow md:justify-center cursor-pointer md:flex-row md:gap-10 bg-white rounded-lg border border-[#ECECF1] transform transition-transform duration-300 ease-in-out lg:hover:scale-105 lg:hover:shadow-lg dark:bg-darkBlue dark:border-darkBlue"
            onClick={handleClick}
        >
            <div className="md:max-w-[200px]">
                <H2 extra="text-[#202123] dark:text-white">{title}</H2>
                <Text size={ESizeText.SMALL} extra="text-[#353740]">{description}</Text>
            </div>
            <div className="text-sm">
                <pre className="whitespace-pre-wrap break-words break-all">{renderCodeLines(code)}</pre>
            </div>
        </div>
    );
};

export default CodeCard;