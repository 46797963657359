import React, { ReactElement, useState } from "react";
import authImg from "./assets/auth.jpg";
import { ReactNode } from "react";
import { FooterAuth } from "widgets/Footer";
import { FixedButton } from "features/FixedButton";
import { WelcomePage } from "pages/WelcomePage";

export default function Auth(props: { children: ReactNode }) {
  document.documentElement.dir = "ltr";
  const [isRegistered, setIsRegistered] = useState(false);
  const { children } = props;

  if (isRegistered) {
    return <WelcomePage />;
  }

  return (
    <div className="fixed w-full h-full">
      <div className="relative float-right w-full h-full !bg-white dark:!bg-navy-900">
        <FixedButton />
        <main className="mx-auto h-full">
          <div className="relative h-full flex">
            <div className="mx-auto flex w-full h-full flex-col justify-between pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
              <div className="flex flex-col h-full pl-5 pr-5 md:pl-12 md:pr-0 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
                {/* {children} */}
                {React.isValidElement(children)
                  ? React.cloneElement(children as ReactElement, { setIsRegistered })
                  : children}
              </div>
              <div className="absolute right-0 hidden h-full md:block lg:w-[49vw] 2xl:w-[44vw]">
                <div
                  className="absolute flex h-full w-full items-end justify-center bg-cover bg-center"
                  style={{ backgroundImage: `url(${authImg})` }}
                />
              </div>
              <FooterAuth />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
