import { ITriggerForm, ITrigger } from 'app/types';
import { fetchData } from 'app/utils/fetch/request';
import { ComponentRow } from 'shared/ComponentRow';
import { ColumnHeaders } from 'shared/ColumnHeaders';
import { CardBlock } from 'features/CardBlock';
import Cookies from 'js-cookie';
import { useContext, useMemo, useState } from 'react';
import { Modal } from 'shared/Modal';
import { H2 } from 'shared/H2';
import { truncateText } from 'app/utils/truncateText/truncateText';
import { getAllTriggers, getTriggerById } from '../api/triggers';
import { TriggerPanel } from './TriggerPanel';
import { initialTriggerForm } from '../data/triggers';
import { getActionById } from '../api/actions';
import { IAction } from 'app/types/actions';
import { ErrorMessage } from 'features/ErrorMessage';
import { Message } from 'app/types/messages';
import { TooltipMessage } from 'shared/TooltipMessage';
import { SearchContext } from 'app/context/SearchContext';

interface ITriggersProps {
  actions: IAction[];
  triggers: ITrigger[];
  setTriggers: React.Dispatch<React.SetStateAction<ITrigger[]>>;
  modalActive: 'create' | 'update' | false;
  setModalActive: React.Dispatch<
    React.SetStateAction<'create' | 'update' | false>
  >;
  setStatus: (value: React.SetStateAction<Message | undefined>) => void;
  error: string;
}
export const Triggers: React.FC<ITriggersProps> = ({
  actions,
  triggers,
  setTriggers,
  modalActive,
  setModalActive,
  setStatus,
  error,
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState<string>('');
  const [updateTriggerId, setUpdateTriggerId] = useState<string>('');
  const [triggerForm, setTriggerForm] =
    useState<ITriggerForm>(initialTriggerForm);
  const { searchValue } = useContext(SearchContext);

  const [username] = useState<string>(String(Cookies.get('username')));

  const deleteHandler = async () => {
    const token = Cookies.get('accessToken');
    try {
      await fetchData(
        `${import.meta.env.VITE_APP_USER_API}/triggers/${deleteModalOpen}`,
        'DELETE',
        token,
      );
      setDeleteModalOpen('');
      setStatus({ text: 'Successfully deleted', type: 'success' });
    } catch (error) {
      setStatus({ text: 'Error when deleting', type: 'error' });
    }
    const triggers = await getAllTriggers();
    if (triggers) {
      setTriggers(triggers);
    }
  };

  const handleClick = async (selectedId: string) => {
    try {
      const trigger: ITrigger = await getTriggerById(selectedId);
      if (trigger) {
        if (trigger?.actions && trigger?.actions?.length > 0) {
          const promises = trigger.actions.map((id) => getActionById(id));
          Promise.allSettled(promises).then((results) => {
            const successfulResults = results
              .filter(
                (result): result is PromiseFulfilledResult<IAction> =>
                  result.status === 'fulfilled',
              )
              .map((result) => result.value);

            console.log(successfulResults);
          });
        }
        setUpdateTriggerId(selectedId);
        setModalActive('update');
        setTriggerForm({
          name: trigger.name,
          type: trigger.type,
          actions: trigger.actions || [],
          specificField: trigger.condition,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filteredTriggers = useMemo(() => {
    return triggers
      ? triggers.filter((note) =>
          note.name.toLowerCase().includes(searchValue.toLowerCase()),
        )
      : [];
  }, [searchValue, triggers]);

  const headers = [
    'Trigger Name',
    'Description',
    'Type',
    'Actions',
    'Created At',
    'Username',
    '',
  ];

  return (
    <div>
      <TriggerPanel
        actions={actions}
        selectedId={updateTriggerId}
        triggerForm={triggerForm}
        setTriggerForm={setTriggerForm}
        modalActive={modalActive}
        setModalActive={setModalActive}
        setTriggers={setTriggers}
        setStatus={setStatus}
      />
      <Modal
        title="Delete"
        isOpen={!!deleteModalOpen}
        submitHandler={deleteHandler}
        onClose={() => {
          setDeleteModalOpen('');
        }}
        isDanger
      >
        <div>Are you sure?</div>
      </Modal>
      {error && (
        <div className="pb-2">
          <ErrorMessage message={error} />
        </div>
      )}

      <div className="hidden md:block">
        {triggers &&
          filteredTriggers &&
          triggers.length > 0 &&
          filteredTriggers.length > 0 && (
            <ColumnHeaders columns={headers.length} headers={headers} />
          )}
        {filteredTriggers && filteredTriggers.length > 0 ? (
          filteredTriggers.map((trigger: ITrigger) => (
            <ComponentRow
              columns={headers.length}
              key={trigger._id}
              id={trigger._id || ''}
              title={trigger.name}
              username={username}
              description="no description"
              creationDate={
                trigger.createdAt
                  ? new Date(trigger.createdAt).toISOString().split('T')[0]
                  : '-'
              }
              additionalFields={[
                { value: trigger.type, key: 'type' },
                {
                  value:
                    trigger.actions
                      ?.map((actionId) => {
                        const action = actions.find((a) => a._id === actionId);
                        return action ? action.name : actionId;
                      })
                      .join(', ') || '-',
                  key: 'actions',
                },
              ]}
              onClick={() => handleClick(trigger._id || '')}
              onDelete={() => setDeleteModalOpen(trigger._id || '')}
            />
          ))
        ) : triggers.length > 0 ? (
          <TooltipMessage message={`no triggers with '${searchValue}' filter`} />
        ) : (
          <TooltipMessage message="no triggers" />
        )}
      </div>

      <div className="md:hidden grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        {filteredTriggers && filteredTriggers.length > 0 ? (
          filteredTriggers.map((trigger: ITrigger) => (
            <CardBlock
              key={trigger._id || ''}
              id={trigger._id || ''}
              handleClick={() => {
                if (trigger._id) {
                  handleClick(trigger._id);
                }
              }}
              handleDelete={() => {
                setDeleteModalOpen(trigger._id || '');
              }}
            >
              <H2>{truncateText(trigger.name, 15)}</H2>
            </CardBlock>
          ))
        ) : triggers.length > 0 ? (
          <TooltipMessage message={`no triggers with '${searchValue}' filter`} />
        ) : (
          <TooltipMessage message="no triggers" />
        )}
      </div>
    </div>
  );
};
