import { Navigate, Route, Routes } from 'react-router-dom';
import { Prompts as PromptPage } from './components/Prompts';
import { PromptPanel } from './components/PromptPanel';

export const Prompts = () => {
  return (
    <Routes>
      <Route path="main" element={<PromptPage />} />
      <Route path="main/new" element={<PromptPanel type="create" />} />
      <Route path="main/:id" element={<PromptPanel type="update" />} />

      <Route path="/*" element={<Navigate to="main" replace />} />
    </Routes>
  );
};
