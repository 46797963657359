import React, { useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { MessageType } from 'app/types/messages';

// type MessageType = 'info' | 'warning' | 'error';

interface ErrorMessageProps {
    message: string;
    type?: MessageType;
    closable?: boolean;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message, type = 'error', closable = false }) => {
    const [isVisible, setIsVisible] = useState(true);

    const handleClose = () => {
        setIsVisible(false);
    };

    if (!isVisible) return null;

    const getColorClasses = (type: MessageType) => {
        switch (type) {
            case 'info':
                return 'bg-white text-black border border-lightGray/40';
            case 'warning':
                return 'bg-[#FFE382] text-black';
            case 'error':
                return 'bg-[#FF7E7E] text-white';
            default:
                return '';
        }
    };

    return (
        <div className={`flex justify-between items-center p-3 pl-[18px] rounded-md w-full text-base ${getColorClasses(type)}`}>
            <span>{message}</span>
            {closable && <IoMdClose className="cursor-pointer ml-4" onClick={handleClose} />}
        </div>
    );
};

export default ErrorMessage;
