export async function fetchData<T>(
  url: string,
  method: string,
  token?: string,
  /*eslint-disable*/
  body: any = null,
  /*eslint-enable*/
): Promise<T> {
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
  };

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const options: RequestInit = {
    method: method,
    headers,
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  const response = await fetch(url, options);

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Unexpected error occurred');
  }

  const textResponse = await response.text();

  if (!textResponse.trim()) {
    return {} as T;
  }

  try {
    const data: T = JSON.parse(textResponse);
    return data;
  } catch (jsonError) {
    throw new Error('Invalid JSON response');
  }
}
