import { fetchData } from 'app/utils/fetch/request';
import { ComponentRow } from 'shared/ComponentRow';
import { ColumnHeaders } from 'shared/ColumnHeaders';
import { CardBlock } from 'features/CardBlock';
import Cookies from 'js-cookie';
import { useContext, useMemo, useState } from 'react';
import { Modal } from 'shared/Modal';
import { H2 } from 'shared/H2';
import { IAction, IActionForm } from 'app/types/actions';
import { getActionById, getAllActions } from '../api/actions';
import { ActionPanel } from './ActionPanel';
import { initialActionForm } from '../data/actions';
import { ErrorMessage } from 'features/ErrorMessage';
import { Message } from 'app/types/messages';
import { TooltipMessage } from 'shared/TooltipMessage';
import { SearchContext } from 'app/context/SearchContext';
import { truncateText } from 'app/utils/truncateText/truncateText';

interface IActionsProps {
  actions: IAction[];
  setActions: React.Dispatch<React.SetStateAction<IAction[]>>;
  modalActive: 'create' | 'update' | false;
  setModalActive: React.Dispatch<
    React.SetStateAction<'create' | 'update' | false>
  >;
  setStatus: (value: React.SetStateAction<Message | undefined>) => void;
  error: string;
}

export const Actions: React.FC<IActionsProps> = ({
  actions,
  setActions,
  modalActive,
  setModalActive,
  setStatus,
  error,
}) => {
  const [deleteActionId, setDeleteActionId] = useState<string>('');
  const [updateActionId, setUpdateActionId] = useState<string>('');
  const [actionForm, setActionForm] = useState<IActionForm>(initialActionForm);
  const { searchValue } = useContext(SearchContext);

  const [username] = useState<string>(String(Cookies.get('username')));

  const deleteHandler = async () => {
    const token = Cookies.get('accessToken');
    try {
      await fetchData(
        `${import.meta.env.VITE_APP_USER_API}/actions/${deleteActionId}`,
        'DELETE',
        token,
      );
      const actions = await getAllActions();
      setActions(actions);
      setModalActive(false);
      setDeleteActionId('');
      setStatus({ text: 'Successfully deleted', type: 'success' });
    } catch (error) {
      setStatus({ text: 'Error when deleting', type: 'error' });
    }
  };

  const handleClick = async (selectedId: string) => {
    try {
      const action = await getActionById(selectedId);
      if (action) {
        setUpdateActionId(selectedId);
        setModalActive('update');
        setActionForm({
          name: action.name,
          type: action.type,
          email: action.toEmail?.at(0) || '',
          spreadsheetId: action.spreadsheetId || '',
          sheetName: action.sheetName || '',
          calendarId: action.calendarId || '',
          prompt: action.prompt || '',
          response: action.response || '',
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filteredActions = useMemo(() => {
    return actions
      ? actions.filter((note) =>
          note.name.toLowerCase().includes(searchValue.toLowerCase()),
        )
      : [];
  }, [searchValue, actions]);

  const headers = [
    'Action Name',
    'Description',
    'Type',
    'Created At',
    'Username',
    '',
  ];

  return (
    <div>
      <ActionPanel
        selectedId={updateActionId}
        modalActive={modalActive}
        setActions={setActions}
        setModalActive={setModalActive}
        actionForm={actionForm}
        setActionForm={setActionForm}
        setStatus={setStatus}
      />
      <Modal
        title="Delete"
        isOpen={!!deleteActionId}
        submitHandler={deleteHandler}
        onClose={() => {
          setDeleteActionId('');
        }}
        isDanger
      >
        <div>Are you sure?</div>
      </Modal>

      {error && (
        <div className="pb-2">
          <ErrorMessage message={error} />
        </div>
      )}

      <div className="hidden md:block">
        {actions &&
          filteredActions &&
          actions.length > 0 &&
          filteredActions.length > 0 && (
            <ColumnHeaders columns={headers.length} headers={headers} />
          )}
        {filteredActions && filteredActions.length > 0 ? (
          filteredActions.map((action: IAction) => (
            <ComponentRow
              columns={headers.length}
              key={action._id}
              id={action._id || ''}
              title={action.name}
              username={username}
              description="no description"
              creationDate={
                action.createdAt
                  ? new Date(action.createdAt).toISOString().split('T')[0]
                  : '-'
              }
              additionalFields={[{ value: action.type, key: 'type' }]}
              onClick={() => handleClick(action._id || '')}
              onDelete={() => setDeleteActionId(action._id || '')}
            />
          ))
        ) : actions.length > 0 ? (
          <TooltipMessage message={`no actions with '${searchValue}' filter`} />
        ) : (
          <TooltipMessage message="no actions" />
        )}
      </div>

      <div className="md:hidden grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        {filteredActions && filteredActions.length > 0 ? (
          filteredActions.map((action: IAction) => (
            <CardBlock
              key={action._id || ''}
              id={action._id || ''}
              handleClick={() => {
                if (action._id) {
                  handleClick(action._id);
                }
              }}
              handleDelete={() => {
                setDeleteActionId(action._id || '');
              }}
            >
              <H2>{truncateText(action.name, 15)}</H2>
            </CardBlock>
          ))
        ) : actions.length > 0 ? (
          <TooltipMessage message={`no actions with '${searchValue}' filter`} />
        ) : (
          <TooltipMessage message="no actions" />
        )}
      </div>
    </div>
  );
};
