import React from 'react';
import { SubmitLoader } from './components/SubmitLoader';

interface Props {
  // label?: string;
  label: React.ReactNode;
  onClick?: () => void;
  isActive?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  extra?: string;
}

const SubmitButton: React.FC<Props> = ({
  label,
  onClick,
  isLoading = false,
  isActive = true,
  extra,
}) => {
  return (
    <button
      type="submit"
      onClick={onClick}
      disabled={!isActive}
      className={`flex justify-center items-center p-1 max-h-9 w-full h-full rounded-xl font-medium transition duration-200 ${isActive ? 'text-white bg-normalBlue hover:bg-darkBlue active:bg-[#233657]' : 'bg-lightBlue text-white cursor-not-allowed opacity-70'} ${extra}`}
    >
      {isLoading ? <SubmitLoader /> : label}
    </button>
  );
};

export default SubmitButton;
