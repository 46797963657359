import React, { useEffect } from 'react';
import { HiX } from 'react-icons/hi';
import Links from './components/Links';
import { Text } from 'shared/Text';
import LogoLight from './components/LogoLight.svg';
import LogoDark from './components/LogoDark.svg';

import Cookies from 'js-cookie';
import routes from '../routes';

const Sidebar = (props: {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLSpanElement>;
}) => {
  const { open, onClose } = props;

  const handleLinkClick = () => {
    if (window.innerWidth < 1200) {
      onClose({} as React.MouseEvent<HTMLSpanElement>);
    }
  };

  useEffect(() => {
    setTimeout(async () => {
      const token = Cookies.get('accessToken');
      const profileId = Cookies.get('profileId');

      try {
        const response = await fetch(
          `${import.meta.env.VITE_APP_USER_API}/profiles/${profileId}/credentials`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (!response.ok) {
          throw new Error('Failed to get agents');
        }

        const data = await response.json();
        console.log(data);
      } catch (err) {
        console.error(err);
      }
    });
  }, [open]);

  return (
    <>
      <div
        className={`duration-500 xl:hidden absolute w-full h-full inset-0 hover:cursor-pointer transition-opacity ${
          open ? 'bg-black z-50 opacity-20' : 'opacity-0 pointer-events-none'
        }`}
        onClick={onClose}
      ></div>

      <div
        className={`sm:none duration-500 fixed z-50 xl:z-0 ${
          open ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <div className="relative w-full h-full">
          <div
            className={`w-[280px] h-screen md:w-[400px] lg:w-56 sm:none duration-500 linear flex flex-col bg-[#F4F6F8] shadow-2xl shadow-white/5
            transform transition-all dark:!bg-navy-800 dark:text-white`}
          >
            <Text
              onClick={onClose}
              extra="absolute right-4 top-4 block cursor-pointer xl:hidden"
            >
              <HiX />
            </Text>

            <div className="flex justify-center items-center pt-14 pb-11 pr-4 pl-3 border-b border-b-lightGray">
              <img
                src={LogoLight}
                alt="ShmoneLoops"
                className="w-[90%] xl-max:w-[85%] dark:hidden"
              />
              <img
                src={LogoDark}
                alt="ShmoneLoops"
                className="w-[90%] xl-max:w-[85%] hidden dark:block"
              />
            </div>
            <ul className="mb-auto pt-5 pl-5 pr-3 overflow-y-auto scrollbar-thin sm-max:pt-1">
              <Links routes={routes} onLinkClick={handleLinkClick} />
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
