import React, { useState } from 'react';

interface DropdownListProps {
    children: React.ReactNode;
}

export const DropdownList: React.FC<DropdownListProps> = ({ children }) => {
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [openIndexes, setOpenIndexes] = useState<number[]>([]);

    const handleToggle = (index: number, isOpen: boolean) => {
        setOpenIndexes((prev) => {
            if (isOpen) {
                return [...prev, index];
            } else {
                return prev.filter((i) => i !== index);
            }
        });
    };

    return (
        <div className="w-full max-w-md mx-auto">
            {React.Children.map(children, (child, index) => {
                const isOpen = openIndexes.includes(index);
                return (
                    <div key={index} className="relative group">
                        {React.cloneElement(child as React.ReactElement<any>, {
                            onHover: (isHovered: boolean) => { setHoveredIndex(isHovered ? index : null); },
                            onToggle: (isOpen: boolean) => { handleToggle(index, isOpen); },
                        })}
                        {index < React.Children.count(children) - 1 && (
                            <hr
                                className={`border-t border-lightGray border-opacity-70 transition-opacity duration-300
                            ${hoveredIndex === index && !isOpen ? 'border-opacity-0' : ''}
                            ${hoveredIndex === index + 1 && !openIndexes.includes(index + 1) ? 'border-opacity-0' : ''}`}
                            />
                        )}
                    </div>
                );
            })}
        </div>
    );
};