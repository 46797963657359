import { ITrigger, ITriggerForm } from 'app/types';
import { fetchData } from 'app/utils/fetch/request';
import Cookies from 'js-cookie';
import { useState, useEffect, useRef } from 'react';
import { Input } from 'shared/Input';
import { Modal } from 'shared/Modal';
import { Select } from 'shared/Select';
import { getAllTriggers } from '../api/triggers';
import { initialTriggerForm } from '../data/triggers';
import { IAction } from 'app/types/actions';
import { MultiValue, SingleValue } from 'react-select';
import { Text } from 'shared/Text';
import { Message } from 'app/types/messages';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const TriggerTypes = [
  { label: 'Time', value: 'time' },
  { label: 'Condition', value: 'prompt' },
  { label: 'Collect', value: 'collect' },
];

interface ITriggerPanelProps {
  actions: IAction[];
  selectedId: string;
  setTriggers: React.Dispatch<React.SetStateAction<ITrigger[]>>;
  modalActive: 'create' | 'update' | false;
  setModalActive: React.Dispatch<
    React.SetStateAction<'create' | 'update' | false>
  >;
  triggerForm: ITriggerForm;
  setTriggerForm: React.Dispatch<React.SetStateAction<ITriggerForm>>;
  setStatus: (value: React.SetStateAction<Message | undefined>) => void;
}

export const TriggerPanel: React.FC<ITriggerPanelProps> = ({
  actions,
  selectedId,
  setTriggers,
  modalActive,
  setModalActive,
  triggerForm,
  setTriggerForm,
  setStatus,
}) => {
  const [error, setError] = useState<string>('');

  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const datePickerRef = useRef<HTMLDivElement | null>(null);

  const handleTriggerChange = (field: string, value: string) => {
    setTriggerForm({ ...triggerForm, [field]: value });
  };

  useEffect(() => {
    setTriggerForm((prevForm) => ({
      ...prevForm,
      specificField: '',
    }));
    setShowDatePicker(false);
    setSelectedDate(null);
  }, [triggerForm.type]);

  useEffect(() => {
    if (triggerForm.type === 'time' && triggerForm.specificField) {
      const date = new Date(parseInt(triggerForm.specificField, 10));
      setSelectedDate(date);
    }
  }, [triggerForm]);


  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const handleDatePickerClose = () => {
    if (selectedDate) {
      const timestamp = Math.floor(selectedDate.getTime()).toString();
      handleTriggerChange('specificField', timestamp);
    }
    setShowDatePicker(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target as Node)) {
        setShowDatePicker(false);
      }
    };

    if (showDatePicker) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDatePicker]);

  type OnChangeCallback<T> = (option: SingleValue<T> | MultiValue<T>) => void;

  const handleChange: OnChangeCallback<{
    label: string;
    value: string;
  }> = (option) => {
    if (Array.isArray(option)) {
      setTriggerForm({
        ...triggerForm,
        actions: option ? option.map((el) => el.value) : triggerForm.actions,
      });
    }
  };

  const handleTriggerSubmit = async () => {
    try {
      const token = Cookies.get('accessToken');
      const profileId = Cookies.get('profileId');

      const triggerBody: ITrigger = {
        name: triggerForm.name,
        type: triggerForm.type,
        condition: triggerForm.specificField,
        actions: triggerForm.actions,
      };

      if (modalActive === 'create') {
        await fetchData<void>(
          `${import.meta.env.VITE_APP_USER_API}/triggers/${profileId}`,
          'POST',
          token,
          triggerBody,
        );
        setStatus({
          text: 'Trigger was successfully created',
          type: 'success',
        });
        setError('');
      } else {
        await fetchData<void>(
          `${import.meta.env.VITE_APP_USER_API}/triggers/${selectedId}`,
          'PUT',
          token,
          triggerBody,
        );
        setStatus({
          text: 'Trigger was successfully updated',
          type: 'success',
        });
        setError('');
      }

      const triggers = await getAllTriggers();
      triggers && setTriggers(triggers);
      setTriggerForm(initialTriggerForm);
      setModalActive(false);
    } catch (error) {
      if (error instanceof Error) {
        setError(error?.message);
      } else {
        setError('Internal error');
        console.error(error);
      }
    }
  };

  return (
    <Modal
      title={modalActive === 'create' ? 'Create trigger' : 'Update trigger'}
      isOpen={!!modalActive}
      onClose={() => {
        setModalActive(false);
        setTriggerForm(initialTriggerForm);
        setError('');
      }}
      submitHandler={handleTriggerSubmit}
    >
      <div className="flex flex-col gap-2">
        {error && <Text extra="text-red-500">{error}</Text>}
        <Input
          label="Name"
          placeholder="Name"
          name="name"
          value={triggerForm.name}
          onChange={(e) => handleTriggerChange('name', e.target.value)}
        />
        <Select
          name="type"
          label="Type"
          options={TriggerTypes}
          value={TriggerTypes.find(
            (option) => option.value === triggerForm.type,
          )}
          onChange={(option) => {
            if (option && !Array.isArray(option)) {
              const singleOption = option as { value: string };
              handleTriggerChange('type', singleOption.value);
            }
          }}
          extra="dark:bg-normalBlue"
        />
        {triggerForm.type && (
          <>
            <div onClick={() => setShowDatePicker((prev) => !prev)}>
              <Input
                label={
                  triggerForm.type.charAt(0).toUpperCase() +
                  triggerForm.type.slice(1)
                }
                name={triggerForm.type}
                // value={triggerForm.specificField}
                value={triggerForm.type === 'time' && selectedDate ? selectedDate.getTime().toString() : triggerForm.specificField}
                onChange={(e) => handleTriggerChange('specificField', e.target.value)}
              />
            </div>
            {showDatePicker && triggerForm.type === 'time' && (
              <div ref={datePickerRef} className="absolute z-50 left-[50%] translate-x-[-50%] w-max bg-white p-2 border border-lightGray rounded shadow-lg">
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={1}
                  dateFormat="yyyy-MM-dd HH:mm"
                  inline
                />
                <div className="flex justify-end mt-2">
                  <button className="px-3 py-1 rounded-xl border border-lightGray text-xs font-semibold text-darkBlue bg-white hover:bg-lightGray/20" onClick={handleDatePickerClose}>
                    OK
                  </button>
                </div>
              </div>
            )}
          </>
        )}
        <Select
          options={
            actions
              ? actions
                .filter((action) => action._id !== undefined)
                .map((action) => ({
                  label: action.name,
                  value: action._id!,
                }))
              : []
          }
          name="action"
          label="Actions"
          isMulti={true}
          value={
            actions
              ? actions
                .filter((action) => action._id !== undefined)
                .filter((action) =>
                  triggerForm.actions.includes(action._id || ''),
                )
                .map((action) => ({
                  label: action.name,
                  value: action._id!,
                }))
              : []
          }
          onChange={handleChange}
          extra="dark:bg-normalBlue"
        />
      </div>
    </Modal>
  );
};
