import { H2 } from 'shared/H2';

interface IProps {
  label: string;
  children: React.ReactNode;
}

export const LabledBlock: React.FC<IProps> = ({ label, children }) => {
  return (
    <div className="w-full h-full p-2 overflow-y-auto">
      <div className="w-full h-full text-sm border rounded-lg border-lightGray flex flex-col bg-white dark:bg-darkBlue dark:border-lightBlue/50">
        <div className="w-full p-2 border-b-[1px] border-lightGray dark:border-lightBlue/50">
          <H2>{label}</H2>
        </div>
        <div className="p-2 h-full overflow-y-auto">{children}</div>
      </div>
    </div>
  );
};
