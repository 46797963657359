import { SwitchLayout } from 'shared/SwitchLayout';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';
import Cookies from 'js-cookie';
import { fetchData } from 'app/utils/fetch/request';
import { IPrompt } from 'app/types/prompt';
import { Modal } from 'shared/Modal';
import { ComponentRow } from 'shared/ComponentRow';
import { ColumnHeaders } from 'shared/ColumnHeaders';
import { CardBlock } from 'features/CardBlock';
import { H2 } from 'shared/H2';
import { truncateText } from 'app/utils/truncateText/truncateText';
import { TimeoutMessage } from 'widgets/TimeoutMessage';
import { Message } from 'app/types/messages';
import { ErrorMessage } from 'features/ErrorMessage';
import { TooltipMessage } from 'shared/TooltipMessage';
import { SearchContext } from 'app/context/SearchContext';
import { ContentWrapper } from 'widgets/ContentWrapper';

export const Prompts = () => {
  const navigate = useNavigate();
  const [prompts, setPrompts] = useState<IPrompt[]>();
  const [templates, setTemplates] = useState<IPrompt[]>();
  const [deleteModalOpen, setDeleteModalOpen] = useState<string>('');
  const [deleteType, setDeleteType] = useState<'prompt' | 'template'>('prompt');
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState<Message>();
  const [error, setError] = useState<{
    text: string;
    type: 'prompts' | 'template' | 'models';
  }>();
  const [templateModalId, setTemplateModalId] = useState<string>('');
  const [username] = useState<string>(String(Cookies.get('username')));
  const [activeTab, setActiveTab] = useState<'prompts' | 'templates'>(
    'prompts',
  );

  const { searchValue } = useContext(SearchContext);

  const getAllPrompts = async () => {
    const token = Cookies.get('accessToken');
    const profileId = Cookies.get('profileId');

    try {
      setLoading(true);
      const prompts = await fetchData<IPrompt[]>(
        `${import.meta.env.VITE_APP_USER_API}/profiles/${profileId}/prompts`,
        'GET',
        token,
      );

      setLoading(false);
      setPrompts(prompts);
    } catch (error) {
      setLoading(false);
      setError({ text: 'Error on getting prompts', type: 'prompts' });
      console.error('Error on getting prompts:', error);
    }
  };

  const getAllTemplates = async () => {
    const token = Cookies.get('accessToken');

    try {
      setLoading(true);
      const templates = await fetchData<IPrompt[]>(
        `${import.meta.env.VITE_APP_USER_API}/prompts/template`,
        'GET',
        token,
      );

      setLoading(false);
      setTemplates(templates);
    } catch (error) {
      setLoading(false);
      setError({ text: 'Error on getting templates', type: 'template' });
      console.error('Error on getting templates:', error);
    }
  };

  useEffect(() => {
    getAllPrompts();
    getAllTemplates();
  }, []);

  const deleteHandler = async () => {
    const token = Cookies.get('accessToken');
    try {
      if (deleteType === 'prompt') {
        await fetchData(
          `${import.meta.env.VITE_APP_USER_API}/prompts/${deleteModalOpen}`,
          'DELETE',
          token,
        );
        await getAllPrompts();
      } else if (deleteType === 'template') {
        await fetchData(
          `${import.meta.env.VITE_APP_USER_API}/prompts/template/${deleteModalOpen}`,
          'DELETE',
          token,
        );
        await getAllTemplates();
      }
      setDeleteModalOpen('');
      setStatusMessage({ text: 'Successfully deleted', type: 'success' });
    } catch (error) {
      setStatusMessage({ text: 'Error when deleting', type: 'error' });
      console.error('Error on deleting:', error);
    }
  };

  const filteredPrompts = useMemo(() => {
    return prompts
      ? prompts.filter((prompt) =>
          prompt.name.toLowerCase().includes(searchValue.toLowerCase()),
        )
      : [];
  }, [searchValue, prompts]);

  const filteredTemplates = useMemo(() => {
    return templates
      ? templates.filter((template) =>
          template.name.toLowerCase().includes(searchValue.toLowerCase()),
        )
      : [];
  }, [searchValue, templates]);

  const formatDate = (dateString: string) => {
    return new Date(dateString).toISOString().split('T')[0];
  };

  const promptHeaders = [
    'Prompt Name',
    'Description',
    'Created At',
    'Username',
    '',
  ];
  const templateHeaders = [
    'Template Name',
    'Description',
    'Created At',
    'Username',
    '',
  ];

  return (
    <>
      {statusMessage && <TimeoutMessage messages={statusMessage} />}
      <ContentWrapper
        title="Prompts"
        description="Manage your agent prompts"
        sumbitLabel="New"
        sumbitHandler={() => {
          navigate('/admin/prompts/main/new');
        }}
        showSubmitButton={activeTab === 'prompts'}
      >
        <SwitchLayout
          loading={loading}
          sides={[
            {
              title: 'Prompts',
              component: (
                <div>
                  <Modal
                    title="Delete"
                    isOpen={!!deleteModalOpen}
                    submitHandler={deleteHandler}
                    onClose={() => {
                      setDeleteModalOpen('');
                    }}
                    isDanger
                  >
                    <div>Are you sure?</div>
                  </Modal>

                  {error?.type === 'prompts' && error.text && (
                    <div className="pb-2">
                      <ErrorMessage message={error.text} />
                    </div>
                  )}

                  <div className="hidden md:block">
                    {prompts &&
                      filteredPrompts &&
                      prompts.length > 0 &&
                      filteredPrompts.length > 0 && (
                        <ColumnHeaders
                          columns={promptHeaders.length}
                          headers={promptHeaders}
                        />
                      )}
                    {filteredPrompts && filteredPrompts.length > 0 ? (
                      filteredPrompts.map((prompt: IPrompt) => (
                        <ComponentRow
                          columns={promptHeaders.length}
                          key={prompt._id}
                          id={prompt._id || ''}
                          title={prompt.name}
                          username={username}
                          description="no description"
                          creationDate={formatDate(String(prompt.createdAt))}
                          onClick={() => {
                            navigate(`/admin/prompts/main/${prompt._id}`);
                          }}
                          onDelete={() => {
                            setDeleteType('prompt');
                            setDeleteModalOpen(prompt._id || '');
                          }}
                        />
                      ))
                    ) : prompts && prompts?.length > 0 ? (
                      <TooltipMessage message={`no prompts with '${searchValue}' filter`} />
                    ) : (
                      <TooltipMessage message="no prompts" />
                    )}
                  </div>

                  <div className="md:hidden grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                    {filteredPrompts && filteredPrompts.length > 0 ? (
                      filteredPrompts.map(
                        (prompt: IPrompt) =>
                          prompt._id && (
                            <CardBlock
                              id={prompt._id}
                              handleClick={() => {
                                navigate(`/admin/prompts/main/${prompt._id}`);
                              }}
                              handleDelete={() => {
                                setDeleteType('prompt');
                                setDeleteModalOpen(prompt._id || '');
                              }}
                            >
                              <H2>{truncateText(prompt.name, 15)}</H2>
                            </CardBlock>
                          ),
                      )
                    ) : prompts && prompts?.length > 0 ? (
                      <TooltipMessage message={`no prompts with '${searchValue}' filter`} />
                    ) : (
                      <TooltipMessage message="no prompts" />
                    )}
                  </div>
                </div>
              ),
            },
            {
              title: 'Templates',
              component: (
                <div>
                  <Modal
                    title="Template"
                    isOpen={!!templateModalId}
                    submitHandler={() => {
                      setTemplateModalId('');
                    }}
                    onClose={() => {
                      setTemplateModalId('');
                    }}
                  >
                    <div>
                      {
                        templates?.find(
                          (template) => template._id === templateModalId,
                        )?.content
                      }
                    </div>
                  </Modal>

                  <Modal
                    title="Delete"
                    isOpen={!!deleteModalOpen && deleteType === 'template'}
                    submitHandler={deleteHandler}
                    onClose={() => {
                      setDeleteModalOpen('');
                    }}
                    isDanger
                  >
                    <div>Are you sure?</div>
                  </Modal>

                  {error?.type === 'template' && error.text && (
                    <div className="pb-2">
                      <ErrorMessage message={error.text} />
                    </div>
                  )}

                  <div className="hidden md:block">
                    {templates &&
                      filteredTemplates &&
                      templates.length > 0 &&
                      filteredTemplates.length > 0 && (
                        <ColumnHeaders
                          columns={templateHeaders.length}
                          headers={templateHeaders}
                        />
                      )}
                    {filteredTemplates && filteredTemplates.length > 0 ? (
                      filteredTemplates.map((template: IPrompt) => (
                        <ComponentRow
                          columns={templateHeaders.length}
                          key={template._id}
                          id={template._id || ''}
                          title={template.name}
                          username="ShmoneLoops"
                          description="no description"
                          creationDate={formatDate(String(template.createdAt))}
                          onClick={() => {
                            setTemplateModalId(template._id || '');
                          }}
                        />
                      ))
                    ) : templates && templates?.length > 0 ? (
                      <TooltipMessage message={`no templates with '${searchValue}' filter`} />
                    ) : (
                      <TooltipMessage message="no templates" />
                    )}
                  </div>

                  <div className="md:hidden grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                    {filteredTemplates && filteredTemplates.length > 0 ? (
                      filteredTemplates.map(
                        (template: IPrompt) =>
                          template._id && (
                            <CardBlock
                              id={template._id}
                              handleClick={() => {
                                setTemplateModalId(template._id || '');
                              }}
                            >
                              <H2>{truncateText(template.name, 15)}</H2>
                            </CardBlock>
                          ),
                      )
                    ) : templates && templates?.length > 0 ? (
                      <TooltipMessage message={`no templates with '${searchValue}' filter`} />
                    ) : (
                      <TooltipMessage message="no templates" />
                    )}
                  </div>
                </div>
              ),
            },
          ]}
          setActiveTitle={(title) =>
            setActiveTab(title === 'Prompts' ? 'prompts' : 'templates')
          }
        />
      </ContentWrapper>
    </>
  );
};
