import React from 'react';

interface BlockProps {
  children: React.ReactNode;
  onClick?: () => void;
}

const Block: React.FC<BlockProps> = ({ children, onClick = () => {} }) => {
  return (
    <div
      className="h-full shadow border rounded-lg p-4 flex justify-center content-center cursor-pointer transition duration-200 bg-white hover:bg-lightGray/20 dark:bg-darkBlue dark:hover:bg-normalGray dark:hover:bg-opacity-20 dark:border-darkBlue"
      onClick={onClick}
    >
      <div>{children}</div>
    </div>
  );
};

export default Block;
