import Loader from 'entities/Loader/Loader';
import React, { SetStateAction, useState } from 'react';

interface ISide {
  title: string;
  component: React.ReactNode;
}

interface LayoutProps {
  sides: ISide[];
  setActiveTitle?: React.Dispatch<SetStateAction<string>>;
  loading?: boolean;
}

export const SwitchLayout: React.FC<LayoutProps> = ({
  sides,
  setActiveTitle,
  loading = false,
}) => {
  const [selectedColumn, setSelectedColumn] = useState<number>(0);

  return (
    <div className="flex h-full flex-col">
      <div className="flex w-full justify-start pb-2">
        <div className="flex overflow-hidden">
          {sides.map((side, index) => {
            if (index === selectedColumn && setActiveTitle) {
              setActiveTitle(side.title);
            }
            return (
              <button
                key={index}
                className={`flex-1 px-3 py-[2.5px] font-bold relative after:absolute after:bottom-0 after:left-0 after:right-0 after:h-[2px] after:bg-transparent ${
                  selectedColumn === index
                  ? 'text-darkBlue after:bg-darkBlue dark:text-white dark:after:bg-white'
                    : 'text-lightBlue font-medium dark:text-lightGray'
                }`}
                onClick={() => setSelectedColumn(index)}
              >
                {side.title}
              </button>
            );
          })}
        </div>
      </div>
      {sides.map((side, index) => (
        <div
          key={index}
          className={`h-full flex-1 ${
            selectedColumn === index ? 'block' : 'hidden'
          }`}
        >
          {loading ? <Loader /> : side.component}
        </div>
      ))}
    </div>
  );
};
