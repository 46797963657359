import React, { useState, useEffect } from 'react';
import { checkUsername, checkPassword, checkEmail, checkLogin } from './utils';

function Input(props: {
  id?: string;
  label?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  placeholder?: string;
  type?: string;
  isSuccess?: boolean;
  shouldValidate?: boolean;
  disabled?: boolean;
  extra?: string;
  setSubmitActive?: (active: boolean) => void;
  password?: string;
  autoComplete?: string;
}) {
  const [validation, setValidation] = useState<{
    status: 'success' | 'warning' | 'error' | 'none';
    message: string;
  }>({ status: 'none', message: '' });

  const updateStatus = (validationResult: {
    status: 'success' | 'warning' | 'error' | 'none';
    message: string;
  }) => {
    setValidation(validationResult);
    if (props.setSubmitActive) {
      props.setSubmitActive(validationResult.status !== 'error');
    }
  };

  useEffect(() => {
    if (props.shouldValidate) {
      if (props.type === 'password' && props.id === 'password' && props.value) {
        updateStatus(checkPassword(props.value));
      } else if (
        props.type === 'password' &&
        props.id === 'confirm-password' &&
        props.value &&
        props.password
      ) {
        if (props.value !== props.password) {
          updateStatus({ status: 'error', message: 'Passwords do not match' });
        } else {
          updateStatus(checkPassword(props.value));
        }
      } else if (props.type === 'email' && props.value) {
        updateStatus(checkEmail(props.value));
      } else if (
        props.type === 'text' &&
        props.id === 'username' &&
        props.value
      ) {
        updateStatus(checkUsername(props.value));
      } else if (props.id === 'login' && props.value) {
        updateStatus(checkLogin(props.value));
      } else {
        updateStatus({ status: 'none', message: '' });
      }
    }
  }, [
    props.value,
    props.type,
    props.shouldValidate,
    props.id,
    props.setSubmitActive,
    props.password,
  ]);

  const colorMap = {
    none: 'border-lightGray dark:text-white',
    success: 'border-lightGray dark:!border-lightGray/70 dark:text-white',
    warning:
      'border-yellow-500 text-yellow-500 placeholder:text-yellow-500 dark:!border-yellow-400 dark:!text-yellow-400 dark:placeholder:!text-yellow-400',
    error:
      'border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400',
  };

  return (
    <div key={props.id} className="w-full">
      {props.label && (
        <label
          htmlFor={props.id}
          className="text-sm text-lightBlue dark:text-white font-medium"
        >
          {props.label}
        </label>
      )}

      <input
        id={props.id}
        disabled={props.disabled}
        type={props.type || 'text'}
        autoComplete={props.autoComplete}
        name={props.name}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        className={`mt-0 flex w-full items-center justify-center rounded-xl border border-lightBlue/50 text-darkBlue placeholder:text-lightBlue bg-white/0 px-3 py-2 text-sm outline-none focus:border-lightBlue focus:bg-lightBlue/5 dark:border-lightBlue/70 ${
          props.disabled === true
            ? '!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]'
            : colorMap[validation.status]
        }
        ${props.extra || ''}`}
      />
      {validation.status !== 'success' && validation.message && (
        <div className="text-sm text-red-500 dark:text-red-400">
          {validation.message}
        </div>
      )}
    </div>
  );
}

export default Input;
