import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { ESize, H2 } from 'shared/H2';
import { Card } from 'shared/Card';
import { Input } from 'shared/Input';

const ChangePassword: React.FC<any> = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const changePassword = async (
    oldPassword: string,
    newPassword: string,
  ): Promise<void> => {
    try {
      const token = Cookies.get('accessToken');
      const response = await fetch(
        import.meta.env.VITE_APP_USER_API + '/auth/change-password',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            oldPassword,
            newPassword,
          }),
        },
      );

      if (!response.ok) {
        throw new Error('Failed to change password');
      }
    } catch (error) {
      throw new Error('Failed to change password');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    if (newPassword !== confirmPassword) {
      setError('New passwords do not match');
      return;
    }
    try {
      await changePassword(currentPassword, newPassword);
      setSuccess('Password changed successfully!');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (err) {
      setError('Failed to change password');
    }
  };

  return (
    <Card>
      <div className="p-6">
        <H2 size={ESize.MEDIUM} extra="mb-4">
          Change Password
        </H2>
        {error && <div className="mb-4 text-red-500">{error}</div>}
        {success && <div className="mb-4 text-green-500">{success}</div>}
        <form onSubmit={handleSubmit} className="flex flex-col gap-5">
          <Input
            label="Current Password"
            id="currentPassword"
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            shouldValidate={true}
          />
          <Input
            label="New Password"
            id="newPassword"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            shouldValidate={true}
          />
          <Input
            label="Confirm New Password"
            id="confirmPassword"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            shouldValidate={true}
          />
          <div className="flex justify-end">
            <button
              type="submit"
              className={`flex justify-center items-center max-h-12 py-2 px-3 rounded-xl font-medium transition duration-200 text-white bg-normalBlue hover:bg-darkBlue active:bg-[#233657] dark:hover:!bg-black/30`}
            >
              Change Password
            </button>
          </div>
        </form>
      </div>
    </Card>
  );
};

export default ChangePassword;
