// import { FaCopy } from "react-icons/fa";
import { H1, ESizeHeader } from "shared/H1";
import { H2, ESize } from "shared/H2";
import { CodeBlock } from "shared/CodeBlock";

// const copyToClipboard = (text: string) => {
//   navigator.clipboard.writeText(text);
// };

const Documentation = () => {
  return (
    <div className="max-w-full flex flex-col justify-between gap-6 p-8 md-max:p-5">
      <H1 size={ESizeHeader.HUGE}>
        Documentation for Open API to Get Response from LLM Agent
      </H1>

      <section className="">
        <H2 size={ESize.HUGE} extra="mb-2">Description</H2>
        <p className="mb-2">
          This API provides the ability to send a request to an LLM agent and
          receive a response. The request is made to
        </p>
        <code className="break-words rounded p-1">
          https://api-users.dashboard.shmoneloops.com/api/agents/:agentId/public-llm
        </code>

      </section>

      <section className="">
        <H2 size={ESize.HUGE} extra="mb-2">URL</H2>
        <CodeBlock text="https://api-users.dashboard.shmoneloops.com/api/agents/:agentId/public-llm" />
        <p className="mt-2">
          <strong>:agentId</strong> — the identifier of the agent to which the
          request is directed.
        </p>
      </section>

      <section className="">
        <H2 size={ESize.HUGE} extra="mb-2">Method</H2>
        <CodeBlock text="POST" />
      </section>

      <section className="">
        <H2 size={ESize.HUGE} extra="mb-2">Headers</H2>
        <CodeBlock text="Content-Type: application/json" />
      </section>

      <section className="">
        <H2 size={ESize.HUGE} extra="mb-2">Request Body</H2>
        <p>
          The request body should contain the chat ID and an array of messages.
          Example request body:
        </p>
        <CodeBlock text={`{
  "chatId": "testId",
  "messages": [
    { "content": "ok", "role": "user" },
    { "content": "ok", "role": "assistant" },
    { "content": "ok", "role": "user" }
  ]
}`} />
        <h3 className="mt-4 text-xl font-semibold">Request Body Parameters</h3>
        <ul className="list-inside list-disc">
          <li>
            <strong>chatId</strong> (string): The identifier of the chat.
          </li>
          <li>
            <strong>messages</strong> (array): An array of messages, each of
            which is an object with two fields:
            <ul className="ml-4 list-inside list-disc">
              <li>
                <strong>content</strong> (string): The content of the message.
              </li>
              <li>
                <strong>role</strong> (string): The role of the message sender.
                Possible values: <code className="rounded  p-1">user</code> and{" "}
                <code className="rounded p-1">assistant</code> .
              </li>
            </ul>
          </li>
        </ul>
      </section>

      <section className="">
        <H2 size={ESize.HUGE} extra="mb-2">Example Request</H2>
        <CodeBlock text={`curl -X POST https://api-users.dashboard.shmoneloops.com/api/agents/12345/public-llm
-H "Content-Type: application/json"
-d '{
  "chatId": "testId",
  "messages": [
    {
      "content": "ok",
      "role": "user"
    },
    {
      "content": "ok",
      "role": "assistant"
    },
    {
      "content": "ok",
      "role": "user"
    }
  ]
}'`} />
      </section>

      <section className="">
        <H2 size={ESize.HUGE} extra="mb-2">Example Response</H2>
        <p className="mb-2">
          A successful response will contain the response from the LLM agent.
          Example successful response:
        </p>
        <CodeBlock text={`{
  "response": {
    "content": "Response from the agent",
    "role": "assistant"
  }
}`} />
        <h3 className="mt-4 text-xl font-semibold">Response Parameters</h3>
        <ul className="list-inside list-disc">
          <li>
            <strong>response</strong> (object): An object containing the
            response from the agent.
          </li>
          <li>
            <strong>content</strong> (string): The content of the response.
          </li>
          <li>
            <strong>role</strong> (string): The role of the response sender,
            always <code className="rounded p-1">assistant</code>.
          </li>
        </ul>
      </section>

      <section className="">
        <H2 size={ESize.HUGE} extra="mb-2">Errors</H2>
        <ul className="list-inside list-disc">
          <li>
            <strong>400 Bad Request</strong>: Returned if the request body is
            incorrect or required fields are missing.
          </li>
          <li>
            <strong>404 Not Found</strong>: Returned if the agent with the
            specified <code className="rounded p-1">agentId</code> is not found.
          </li>
          <li>
            <strong>500 Internal Server Error</strong>: Returned in case of an
            internal server error.
          </li>
        </ul>
      </section>

      <section className="">
        <H2 size={ESize.HUGE} extra="mb-2">Example Error</H2>
        <CodeBlock text={`{
  "error": "Invalid request body"
}`} />
      </section>

      <section>
        <H2 size={ESize.HUGE} extra="mb-2">Conclusion</H2>
        <p>
          This API allows you to send requests to an LLM agent and receive
          responses based on the provided messages. Make sure all required
          fields are included in the request body and that requests are sent
          with the correct <code className="rounded p-1">agentId</code>.
        </p>
      </section>

    </div>
  );
};

export default Documentation;
