import { fetchData } from 'app/utils/fetch/request';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { Input } from 'shared/Input';
import { Modal } from 'shared/Modal';
import { Select } from 'shared/Select';
import { Text } from 'shared/Text';
import { getAllActions } from '../api/actions';
import { IAction, IActionForm } from 'app/types/actions';
import { ActionTypes, initialActionForm } from '../data/actions';
import { Message } from 'app/types/messages';

interface IActionPanelProps {
  selectedId: string;
  setActions: React.Dispatch<React.SetStateAction<IAction[]>>;
  modalActive: 'create' | 'update' | false;
  setModalActive: React.Dispatch<
    React.SetStateAction<'create' | 'update' | false>
  >;
  actionForm: IActionForm;
  setActionForm: React.Dispatch<React.SetStateAction<IActionForm>>;
  setStatus: (value: React.SetStateAction<Message | undefined>) => void;
}

export const ActionPanel: React.FC<IActionPanelProps> = ({
  selectedId,
  setActions,
  modalActive,
  setModalActive,
  actionForm,
  setActionForm,
  setStatus,
}) => {
  const [error, setError] = useState<string>('');

  const handleActionChange = (field: string, value: string) => {
    console.log(field, value);
    setActionForm({ ...actionForm, [field]: value });
  };

  const handleActionSubmit = async () => {
    try {
      const token = Cookies.get('accessToken');
      const profileId = Cookies.get('profileId');

      if (modalActive === 'create') {
        await fetchData<void>(
          `${import.meta.env.VITE_APP_USER_API}/actions/${profileId}`,
          'POST',
          token,
          {
            ...actionForm,
            toEmail: [actionForm.email],
          },
        );
        setStatus({
          text: 'Action was successfully created',
          type: 'success',
        });
        setError('');
      } else {
        await fetchData<void>(
          `${import.meta.env.VITE_APP_USER_API}/actions/${selectedId}`,
          'PUT',
          token,
          {
            ...actionForm,
            toEmail: [actionForm.email],
          },
        );
        setStatus({
          text: 'Action was successfully updated',
          type: 'success',
        });
        setError('');
      }

      const actions = await getAllActions();
      setActions(actions);
      setActionForm(initialActionForm);
      setModalActive(false);
    } catch (error) {
      if (error instanceof Error) {
        setError(error?.message);
      } else {
        setError('Internal error');
        console.error(error);
      }
    }
  };

  console.log('actionForm', actionForm);

  return (
    <Modal
      title={modalActive === 'create' ? 'Create action' : 'Update action'}
      isOpen={!!modalActive}
      onClose={() => {
        setActionForm(initialActionForm);
        setError('');
        setModalActive(false);
      }}
      submitHandler={handleActionSubmit}
    >
      {error && <Text extra="text-red-500">{error}</Text>}
      <div className="flex flex-col gap-2">
        <Input
          label="Name"
          placeholder="Name"
          name="name"
          value={actionForm.name}
          onChange={(e) => handleActionChange('name', e.target.value)}
        />
        <Select
          label="Type"
          name="type"
          options={ActionTypes}
          value={ActionTypes.find((option) => option.value === actionForm.type)}
          onChange={(option) => {
            if (option && !Array.isArray(option)) {
              const singleOption = option as { value: string };
              handleActionChange('type', singleOption.value);
            }
          }}
          extra="dark:bg-normalBlue"
        />
        {
          {
            notification: (
              <Input
                label="Email"
                name="email"
                value={actionForm.email}
                onChange={(e) => handleActionChange('email', e.target.value)}
              />
            ),
            table: (
              <>
                <Input
                  label="Spreadsheet id"
                  name="spreadsheetId"
                  value={actionForm.spreadsheetId}
                  onChange={(e) =>
                    handleActionChange('spreadsheetId', e.target.value)
                  }
                />{' '}
                <Input
                  label="Sheet name"
                  name="sheetName"
                  value={actionForm.sheetName}
                  onChange={(e) =>
                    handleActionChange('sheetName', e.target.value)
                  }
                />
              </>
            ),
            calendar: (
              <Input
                label="Calendar Id"
                name="calendarId"
                value={actionForm.calendarId}
                onChange={(e) =>
                  handleActionChange('calendarId', e.target.value)
                }
              />
            ),
            llm_response: (
              <Input
                label="Prompt"
                name="prompt"
                value={actionForm.prompt}
                onChange={(e) => handleActionChange('prompt', e.target.value)}
              />
            ),
            custom_response: (
              <Input
                label="Response"
                name="customResponse"
                value={actionForm.response}
                onChange={(e) => handleActionChange('response', e.target.value)}
              />
            ),
          }[actionForm.type]
        }
      </div>
    </Modal>
  );
};
