import React, { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { Message, MessageType } from 'app/types/messages';

interface TimeoutMessageProps {
  messages: Message[] | Message;
}

const TimeoutMessage: React.FC<TimeoutMessageProps> = ({ messages }) => {
  const [visibleMessages, setVisibleMessages] = useState<Message[]>([]);
  const [fadeOut, setFadeOut] = useState<boolean>(false);

  useEffect(() => {
    const msgs = Array.isArray(messages) ? messages : [messages];
    setVisibleMessages(msgs);

    const timer = setTimeout(() => {
      setFadeOut(true);

      setTimeout(() => {
        setVisibleMessages([]);
        setFadeOut(false);
      }, 500);
    }, 2000);

    return () => clearTimeout(timer);
  }, [messages]);

  const getColorClasses = (type: MessageType) => {
    switch (type) {
      case 'info':
        return 'bg-white text-black border border-lightGray/40';
      case 'warning':
        return 'bg-[#FFE382] text-black';
      case 'error':
        return 'bg-[#FF7E7E] text-white';
      case 'success':
        return 'bg-green-500 text-white';
      default:
        return '';
    }
  };

  const handleRemoveMessage = (indexToRemove: number) => {
    setVisibleMessages((prevMessages) =>
      prevMessages.filter((_, index) => index !== indexToRemove),
    );
  };

  return (
    <div className="fixed bottom-4 right-4 space-y-2 z-10">
      {visibleMessages.map((msg, index) => (
        <div
          key={index}
          className={`flex justify-between items-center p-3 pl-[18px] rounded-md w-64 text-base transition-opacity duration-500 ${
            fadeOut ? 'opacity-0' : 'opacity-100'
          } ${getColorClasses(msg.type)}`}
        >
          <span>{msg.text}</span>
          <AiOutlineClose
            className="cursor-pointer ml-4"
            onClick={() => handleRemoveMessage(index)}
          />
        </div>
      ))}
    </div>
  );
};

export default TimeoutMessage;
