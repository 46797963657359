import { IKnowledge } from 'app/types/knowledge';
import { Message } from 'app/types/messages';
import { fetchData } from 'app/utils/fetch/request';
import { useState, useEffect } from 'react';
import { SubmitButton } from 'shared/SubmitButton';
import { Input } from 'shared/Input';
import { Select, ISelectOption } from 'shared/Select';
import { SingleValue, MultiValue } from 'react-select';

interface IParsingProps {
  setKnowledge: (value: React.SetStateAction<IKnowledge>) => void;
  setStatus: (value: React.SetStateAction<Message | undefined>) => void;
}

export const Parsing: React.FC<IParsingProps> = ({
  setKnowledge,
  setStatus,
}) => {
  const [url, setUrl] = useState<string>('');
  const [selector, setSelector] = useState<string>('');
  // const [depth, setDepth] = useState<number>(1);
  const [depth, setDepth] = useState<ISelectOption>({ value: '1', label: '1' });
  const [loading, setLoading] = useState(false);

  const [formFilled, setFormFilled] = useState(false);

  const depthOptions = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
  ];

  const handleDepthChange = (selectedOption: SingleValue<ISelectOption> | MultiValue<ISelectOption> | null) => {
    if (selectedOption && !Array.isArray(selectedOption)) {
      setDepth(selectedOption as ISelectOption);
    }
  };
  

  useEffect(() => {
    setFormFilled(!!url && !!selector && !!depth);
  }, [url, selector, depth]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { htmlCode } = await fetchData<{ htmlCode: string[] }>(
        import.meta.env.VITE_APP_USER_API + '/microservices/parsing',
        'POST',
        undefined,
        {
          url,
          selector: selector || 'p',
          depth,
          min_words: 2,
        },
      );

      setKnowledge((prev: IKnowledge) => ({
        name: prev.name,
        data: htmlCode?.join('/n'),
      }));
      setStatus({ text: 'Successful URL parsing', type: 'success' });
      setLoading(false);
    } catch (error) {
      setStatus({ text: 'URL parsing error', type: 'error' });
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-full">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col h-full justify-between space-y-4"
      >
        <div className="h-full flex flex-col gap-2">
          <div>
            <Input
              label="URL"
              type="text"
              id="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>
          <div>
            <Input
              label="Selector"
              id="selector"
              value={selector}
              onChange={(e) => setSelector(e.target.value)}
            />
          </div>
          <div>
            {/* <label
              htmlFor="depth"
              className="block text-sm font-medium text-lightBlue"
            >
              Depth
            </label>
            <select
              id="depth"
              value={depth}
              onChange={(e) => setDepth(Number(e.target.value))}
              className="mt-1 block w-full px-3 py-2 border border-lightGray rounded-md shadow-sm focus:outline-none focus:border-lightBlue focus:bg-lightBlue/5 sm:text-sm dark:bg-darkBlue dark:border-lightGray/50 dark:focus:border-lightGray/90"
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
            </select> */}

            <Select
              label="Depth"
              id="depth"
              value={depth}
              onChange={handleDepthChange}
              options={depthOptions}
              extra=""
            />
          </div>
        </div>
        <SubmitButton
          label="Submit"
          isLoading={loading}
          isActive={!loading && formFilled}
          extra="flex items-center justify-center"
        />
      </form>
    </div>
  );
};
