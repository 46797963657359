import React from 'react';

import { RiMoonFill, RiSunFill } from 'react-icons/ri';

export default function FixedButton(props: { [s: string]: any }) {
  const { ...rest } = props;
  const [darkmode, setDarkmode] = React.useState(
    document.body.classList.contains('dark'),
  );

  return (
    <button
      className="border-px fixed top-[20px] right-[20px] h-[30px] w-[30px] sm:h-[60px] sm:w-[60px] sm:top-auto sm:bottom-[30px] md:right-[35px] !z-[99] flex lg:h-[30px] lg:w-[30px] lg:bottom-[38px] lg:right-[10px] xl:h-[60px] xl:w-[60px] xl:bottom-[30px] xl:right-[25px] 3xl:right-[35px] items-center justify-center rounded-full border-lightBlue bg-gradient-to-br from-lightBlue to-darkBlue p-0"
      onClick={() => {
        if (darkmode) {
          document.body.classList.remove('dark');
          setDarkmode(false);
        } else {
          document.body.classList.add('dark');
          setDarkmode(true);
        }
      }}
      {...rest}
    >
      <div className="cursor-pointer text-gray-600">
        {darkmode ? (
          <RiSunFill className="h-4 w-4 text-white" />
        ) : (
          <RiMoonFill className="h-4 w-4 text-white" />
        )}
      </div>
    </button>
  );
}
