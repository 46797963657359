import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import './App.css';
import { Login } from 'pages/Login';
import { AuthLayout } from 'layouts/AuthLayout';
import { Register } from 'pages/Register';
import AuthHandler from 'app/utils/auth';
import { AdminLayout } from 'layouts/AdminLayout';
import { RtlLayout } from 'layouts/RtlLayout';
import { SearchProvider } from 'app/context/SearchProvider';
import { AuthApiSuccess } from 'pages/AuthApiSuccess';

function App() {
  return (
    <SearchProvider>
      <Router>
        <Routes>
          <Route
            path="auth/sign-in"
            element={
              <AuthLayout>
                <Login />
              </AuthLayout>
            }
          />
          <Route
            path="auth/sign-up"
            element={
              <AuthLayout>
                <Register />
              </AuthLayout>
            }
          />
          <Route
            path="auth/success"
            element={
              <AuthLayout>
                <AuthApiSuccess />
              </AuthLayout>
            }
          />
          <Route
            path="admin/*"
            element={
              <AuthHandler>
                <AdminLayout />
              </AuthHandler>
            }
          />
          <Route
            path="rtl/*"
            element={
              <AuthHandler>
                <RtlLayout />
              </AuthHandler>
            }
          />

          <Route path="/" element={<Navigate to="/admin" replace />} />
        </Routes>
      </Router>
    </SearchProvider>
  );
}

export default App;
