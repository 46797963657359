import React, { useState } from 'react';

interface SliderProps {
  min: number;
  max: number;
  step: number;
  name?: string;
  value?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Slider: React.FC<SliderProps> = ({
  min,
  max,
  step,
  name,
  value = 0,
  onChange,
}) => {
  const [sliderValue, setSliderValue] = useState(value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    setSliderValue(value);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div className="w-full">
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        name={name}
        value={sliderValue}
        onChange={handleChange}
        className="w-full h-[6px] appearance-none cursor-pointer"
        style={{
          background: `linear-gradient(to right, #3A4B69 ${((sliderValue - min) / (max - min)) * 100}%, #CED0DE ${((sliderValue - min) / (max - min)) * 100}%)`,
          borderRadius: '0.25rem',
        }}
      />
      <div className="text-center mt-2">{sliderValue}</div>

      <style>{`
        input[type='range']::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 1rem;
          height: 1rem;
          background: #ffffff;
          border-radius: 50%;
          cursor: pointer;
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        }

        input[type='range']::-moz-range-thumb {
          width: 1rem;
          height: 1rem;
          background: #ffffff;
          border-radius: 50%;
          cursor: pointer;
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        }
      `}</style>
    </div>
  );
};

export default Slider;
