import { Chats } from 'pages/Chats';
import { Dashboard } from 'pages/Dashboard';
import { Documentation } from 'pages/Documentation';
import { Assistant } from 'pages/Assistant';
import { Overview } from 'pages/Overview';
import { Profile } from 'pages/Profile';
import { Prompts } from 'pages/Prompts';

import { GrHomeOption } from "react-icons/gr";
import { RiAccountBoxLine } from "react-icons/ri";
import { RxDashboard } from "react-icons/rx";
import { TbPrompt } from "react-icons/tb";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import { GoHubot } from "react-icons/go";
import { AiOutlineApi } from "react-icons/ai";

// import { AiFillOpenAI } from 'react-icons/ai';
// import { BsChatSquareTextFill } from 'react-icons/bs';
// import { MdHome, MdPerson, MdDashboard } from 'react-icons/md';
// import { PiPlugsConnectedFill } from 'react-icons/pi';

const routes: RoutesType[] = [
  {
    name: 'Overview',
    layout: '/admin',
    path: 'default',
    icon: <GrHomeOption className="h-6 w-6" />,
    component: <Overview />,
    // group: 'Main',
  },
  {
    name: 'Profile',
    layout: '/admin',
    path: 'profile',
    icon: <RiAccountBoxLine className="h-6 w-6" />,
    component: <Profile />,
    // group: 'Main',
  },
  {
    name: 'Dashboard',
    layout: '/admin',
    path: 'dashboard/*',
    icon: <RxDashboard className="h-6 w-6" />,
    component: <Dashboard />,
    group: 'Control Panel',
  },
  {
    name: 'Prompts',
    layout: '/admin',
    path: 'prompts/*',
    icon: <TbPrompt className="h-6 w-6" />,
    component: <Prompts />,
    group: 'Control Panel',
  },
  {
    name: 'Chats',
    layout: '/admin',
    path: 'chats',
    icon: <HiOutlineChatAlt2 className="h-6 w-6" />,
    component: <Chats />,
    group: 'Conversations',
  },
  {
    name: 'Assistant',
    layout: '/admin',
    path: 'assistant',
    icon: <GoHubot className="h-6 w-6" />,
    component: <Assistant />,
    group: 'Conversations',
  },
  {
    name: 'API Access',
    layout: '/admin',
    path: 'api',
    icon: <AiOutlineApi className="h-6 w-6" />,
    component: <Documentation />,
    group: 'Developer Tools',
  },
];
export default routes;
