import React, { useState } from 'react';

interface ISide {
  title: string;
  component: React.ReactNode;
}

interface LayoutProps {
  leftSide: ISide;
  rightSide: ISide;
}

export const AdaptiveDoubleSwitch: React.FC<LayoutProps> = ({
  leftSide,
  rightSide,
}) => {
  const [selectedColumn, setSelectedColumn] = useState<'left' | 'right'>(
    'left',
  );

  return (
    <div className="h-full flex flex-col md:flex-row">
      <div className="mb-4 flex w-full justify-center md:hidden">
        <div className="flex w-full overflow-hidden rounded-full text-md bg-lightGray/50 dark:bg-lightGray/90">
          <button
            className={`flex-1 px-4 py-1 sm:py-2 ${
              selectedColumn === 'left'
              ? 'bg-normalBlue text-white dark:bg-darkBlue/85'
                : 'text-black'
            }`}
            onClick={() => setSelectedColumn('left')}
          >
            {leftSide.title}
          </button>
          <button
            className={`flex-1 px-4 py-1 sm:py-2 ${
              selectedColumn === 'right'
              ? 'bg-normalBlue text-white dark:bg-darkBlue/85'
                : 'text-black'
            }`}
            onClick={() => setSelectedColumn('right')}
          >
            {rightSide.title}
          </button>
        </div>
      </div>
      <div
        className={`flex-1 ${
          selectedColumn === 'left' ? 'block' : 'hidden'
        } md:block`}
      >
        {leftSide.component}
      </div>
      <div
        className={`flex-1 ${
          selectedColumn === 'right' ? 'block' : 'hidden'
        } md:block`}
      >
        {rightSide.component}
      </div>
    </div>
  );
};
