import { fetchData } from 'app/utils/fetch/request';
import Cookies from 'js-cookie';
import { useEffect, useState, useMemo, useContext } from 'react';
import { Modal } from 'shared/Modal';
import { INote } from 'app/types/note';
import { ComponentRow } from 'shared/ComponentRow';
import { ColumnHeaders } from 'shared/ColumnHeaders';
import { CardBlock } from 'features/CardBlock';
import { Input } from 'shared/Input';
import { Textarea } from 'shared/Textarea';
import { H2 } from 'shared/H2';
import { truncateText } from 'app/utils/truncateText/truncateText';
import Loader from 'entities/Loader/Loader';
import { Message } from 'app/types/messages';
import { ErrorMessage } from 'features/ErrorMessage';
import { TooltipMessage } from 'shared/TooltipMessage';
import { SearchContext } from 'app/context/SearchContext';

interface INotesProps {
  notes: INote[];
  setNotes: React.Dispatch<React.SetStateAction<INote[]>>;
  isAddModalOpen: boolean;
  setIsAddModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setStatus: (value: React.SetStateAction<Message | undefined>) => void;
  error: string;
}

export const Notes: React.FC<INotesProps> = ({
  notes,
  setNotes,
  isAddModalOpen,
  setIsAddModalOpen,
  setStatus,
  error,
}) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<INote>({ name: '', data: '' });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editForm, setEditForm] = useState<INote>({ name: '', data: '' });
  const [deleteModalOpen, setDeleteModalOpen] = useState<string>('');
  const { searchValue } = useContext(SearchContext);

  const [username] = useState<string>(String(Cookies.get('username')));

  const fetchNotes = async () => {
    const token = Cookies.get('accessToken');
    const profileId = Cookies.get('profileId');

    try {
      const notes: INote[] = await fetchData(
        `${import.meta.env.VITE_APP_USER_API}/profiles/${profileId}/knowledge-base`,
        'GET',
        token,
      );
      setNotes(notes);
      console.log('NOTES:' + JSON.stringify(notes));
    } catch (error) {
      console.error('Error on getting notes:', error);
    }
  };

  useEffect(() => {
    setTimeout(async () => {
      setLoading(true);
      fetchNotes();
      setLoading(false);
    });
  }, []);

  const handleDelete = async () => {
    const token = Cookies.get('accessToken');

    try {
      await fetchData(
        `${import.meta.env.VITE_APP_USER_API}/knowledge-base/${deleteModalOpen}`,
        'DELETE',
        token,
      );
      setNotes(notes);
      setStatus({ text: 'Successfully deleted', type: 'success' });
    } catch (error) {
      setStatus({ text: 'Error when deleting', type: 'error' });
      console.error('Error on deleting note:', error);
    }
    fetchNotes();
    setDeleteModalOpen('');
  };

  const handleBlockClick = (note: INote) => {
    setEditForm(note);
    setIsEditModalOpen(true);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleEditChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setEditForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const isFormValid = () => {
    return form.name.trim() !== '' && form.data.trim() !== '';
  };

  const isEditFormValid = () => {
    return editForm.name.trim() !== '' && editForm.data.trim() !== '';
  };

  const handleSubmit = async () => {
    const token = Cookies.get('accessToken');
    const profileId = Cookies.get('profileId');

    try {
      await fetchData(
        `${import.meta.env.VITE_APP_USER_API}/knowledge-base/${profileId}`,
        'POST',
        token,
        { name: form.name, data: form.data, type: 'text' },
      );
      setForm({ name: '', data: '' });
      setNotes(notes);
      setStatus({
        text: 'Agent was successfully created',
        type: 'success',
      });
    } catch (error) {
      setStatus({
        text: 'Error on creating note',
        type: 'error',
      });
    }

    setIsAddModalOpen(false);
    fetchNotes();
  };

  const handleEditSubmit = async () => {
    const token = Cookies.get('accessToken');

    try {
      await fetchData(
        `${import.meta.env.VITE_APP_USER_API}/knowledge-base/${editForm._id}`,
        'PUT',
        token,
        { name: editForm.name, data: editForm.data, type: 'text' },
      );
      setNotes(notes);
      setStatus({ text: 'Successfully updated', type: 'success' });
    } catch (error) {
      setStatus({ text: 'Error when updating', type: 'error' });
      console.error('Error on editing note:', error);
    }

    setIsEditModalOpen(false);
    fetchNotes();
  };

  const onClose = () => {
    setIsAddModalOpen(false);
    setIsEditModalOpen(false);
  };

  const headers = ['Note Name', 'Description', 'Created At', 'Username', ''];

  const filteredNotes = useMemo(() => {
    return notes
      ? notes.filter((note) =>
          note.name.toLowerCase().includes(searchValue.toLowerCase()),
        )
      : [];
  }, [searchValue, notes]);

  return loading ? (
    <Loader />
  ) : (
    <div>
      <Modal
        title="Delete"
        isOpen={!!deleteModalOpen}
        submitHandler={handleDelete}
        onClose={() => {
          setDeleteModalOpen('');
        }}
        isDanger
      >
        <div>Are you sure?</div>
      </Modal>

      {error && (
        <div className="pb-2">
          <ErrorMessage message={error} />
        </div>
      )}

      <div className="hidden md:block">
        {notes &&
          filteredNotes &&
          notes.length > 0 &&
          filteredNotes.length > 0 && (
            <ColumnHeaders columns={headers.length} headers={headers} />
          )}
        {filteredNotes && filteredNotes.length > 0 ? (
          filteredNotes.map((note: INote) => (
            <ComponentRow
              columns={headers.length}
              key={note._id}
              id={note._id || ''}
              title={note.name}
              username={username}
              description="no description"
              creationDate={
                note.createdAt
                  ? new Date(note.createdAt).toISOString().split('T')[0]
                  : '-'
              }
              onClick={() => handleBlockClick(note)}
              onDelete={() => setDeleteModalOpen(note._id || '')}
            />
          ))
        ) : notes.length > 0 ? (
          <TooltipMessage message={`no notes with '${searchValue}' filter`} />
        ) : (
          <TooltipMessage message="no notes" />
        )}
      </div>

      <div className="md:hidden grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        {filteredNotes && filteredNotes.length > 0 ? (
          filteredNotes.map((note: INote) => (
            <CardBlock
              key={note._id || ''}
              id={note._id || ''}
              handleClick={() => handleBlockClick(note)}
              handleDelete={() => setDeleteModalOpen(note._id || '')}
            >
              <H2>{truncateText(note.name, 15)}</H2>
            </CardBlock>
          ))
        ) : notes.length > 0 ? (
          <TooltipMessage message={`no notes with '${searchValue}' filter`} />
        ) : (
          <TooltipMessage message="no notes" />
        )}
      </div>

      <Modal
        title="Add Note"
        submitHandler={handleSubmit}
        isOpen={isAddModalOpen}
        onClose={onClose}
        isSubmitDisabled={!isFormValid()}
      >
        <div className="bg-white text-black dark:bg-normalBlue">
          <div className="mb-4">
            <Input
              id="name"
              name="name"
              type="text"
              label="Name"
              value={form.name}
              onChange={handleChange}
            />
          </div>
          <div>
            <Textarea
              id="data"
              name="data"
              label={`${form.data.length}/250`}
              value={form.data}
              onChange={handleChange}
              extra="dark:bg-normalBlue"
            />
          </div>
        </div>
      </Modal>

      <Modal
        title="Edit Note"
        submitHandler={handleEditSubmit}
        isOpen={isEditModalOpen}
        onClose={onClose}
        isSubmitDisabled={!isEditFormValid()}
      >
        <div className="bg-white text-black dark:bg-normalBlue">
          <div className="mb-4">
            <Input
              id="edit-name"
              name="name"
              type="text"
              label="Name"
              value={editForm.name}
              onChange={handleEditChange}
            />
          </div>
          <div>
            <Textarea
              id="edit-data"
              name="data"
              label={`${editForm.data.length}/250`}
              value={editForm.data}
              onChange={handleEditChange}
              extra="dark:bg-normalBlue"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
