import React from 'react';

interface TooltipMessageProps {
    message: string;
}

const TooltipMessage: React.FC<TooltipMessageProps> = ({ message }) => {
    return (
        <div className="pl-2 text-sm text-lightBlue dark:text-lightGray">{message}</div>
    );
};

export default TooltipMessage;