import { Dashboard } from 'pages/Dashboard';
import { Documentation } from 'pages/Documentation';
import { Assistant } from 'pages/Assistant';
import { Profile } from 'pages/Profile';
import { MdHome, MdPerson, MdDashboard } from 'react-icons/md';
import { PiPlugsConnectedFill } from 'react-icons/pi';

const routes: RoutesType[] = [
  {
    name: 'Home',
    layout: '/admin',
    path: 'default',
    icon: <MdHome className="h-6 w-6" />,
    component: <Assistant />,
    group: '',
  },
  {
    name: 'Profile',
    layout: '/admin',
    path: 'profile',
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
    group: '',
  },
  {
    name: 'Dashboard',
    layout: '/admin',
    path: 'dashboard/*',
    icon: <MdDashboard className="h-6 w-6" />,
    component: <Dashboard />,
    group: '',
  },
  {
    name: 'API',
    layout: '/admin',
    path: 'api',
    icon: <PiPlugsConnectedFill className="h-6 w-6" />,
    component: <Documentation />,
    group: '',
  },
];
export default routes;
