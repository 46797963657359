import FullScreenLoader from 'entities/FullScreenLoader/FullScreenLoader';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthApiSuccess = () => {
  const [error, setError] = useState<string>();
  const navigate = useNavigate();

  const handleSignIn = async () => {
    try {
      console.log(`${import.meta.env.VITE_APP_USER_API}`)
      const response = await fetch(
        `${import.meta.env.VITE_APP_USER_API}/auth/google/success`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      if (!response.ok) {
        throw new Error('Failed to sign in');
      }
      const login = await response.json();

      const payloadResponse = await fetch(
        `${import.meta.env.VITE_APP_USER_API}/auth/payload`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${login.accessToken}`,
          },
        },
      );
      if (!payloadResponse.ok) {
        console.log(payloadResponse);
        setError('Forbidden');
        throw new Error('Failed to fetch user data');
      }
      const payload = await payloadResponse.json();

      Cookies.set('accessToken', login.accessToken, {
        expires: 1,
      });
      Cookies.set('profileId', payload.mongoUserId, {
        expires: 1,
      });
      Cookies.set('username', payload.username, {
        expires: 1,
      });
      Cookies.set('email', payload.email, {
        expires: 1,
      });
      navigate('/');
    } catch (error: unknown) {
      setError('unknown');
      console.error('Error signing in:', error);
    }
  };

  useEffect(() => {
    handleSignIn();
  }, []);

  return !error ? <FullScreenLoader /> : <div>Access is denied</div>;
};
