import { IPrompt } from 'app/types/prompt';
import { fetchData } from 'app/utils/fetch/request';
import Cookies from 'js-cookie';

export const getAllPrompts = async () => {
  const token = Cookies.get('accessToken');
  const profileId = Cookies.get('profileId');

  return await fetchData<IPrompt[]>(
    `${import.meta.env.VITE_APP_USER_API}/profiles/${profileId}/prompts`,
    'GET',
    token,
  );
};

export const getPromptById = async (id: string): Promise<IPrompt> => {
  const token = Cookies.get('accessToken');
  const url = `${import.meta.env.VITE_APP_USER_API}/prompts/${id}`;
  return await fetchData<IPrompt>(url, 'GET', token);
};
