import Cookies from 'js-cookie';
import React, { useState, useEffect, useRef } from 'react';
import { Text } from 'shared/Text';
import { useChatAPI } from './utils';
import Message from './components/Message';
import { Modal } from 'shared/Modal';
import { LuDelete } from 'react-icons/lu';
import { GiPlayButton } from 'react-icons/gi';

interface ChatProps {
  inputPlaceholder?: string;
  buttonText?: string;
  agentId?: string;
  chatId?: string;
  extra?: string;
  showTooltip?: boolean;
  model?: string;
}

const Chat: React.FC<ChatProps> = ({
  inputPlaceholder = 'Type your message...',
  chatId,
  agentId,
  extra,
  showTooltip = true,
  model,
}) => {
  const [newMessage, setNewMessage] = useState('');
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const lastMessageRef = useRef<HTMLDivElement>(null);
  const typingRef = useRef<HTMLDivElement>(null);

  const { messages, typing, fetchChatHistory, sendMessage, clearMessages } =
    useChatAPI(chatId, agentId);

  const onSubmit = (event: any) => {
    event.preventDefault();
    sendMessage(newMessage, setNewMessage);
  };

  useEffect(() => {
    if (!chatId) return;
    fetchChatHistory();
  }, [chatId]);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    Cookies.set('assistantChat', JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    if (typing && typingRef.current) {
      typingRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [typing]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSubmit(e);
    }
  };

  const handleDeleteClick = () => {
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    clearMessages();
    setDeleteModalOpen(false);
  };

  return (
    <div className={`relative h-full flex flex-col m-auto ${extra}`}>
      <div className="scrollbar-none flex-1 overflow-auto p-4 pb-[75px] relative">
        <div className="flex flex-col gap-3">
          {messages.map((message, index) => (
            <Message
              key={index}
              message={message}
              isLastMessage={index === messages.length - 1}
            />
          ))}
          {typing && (
            <div
              ref={typingRef}
              className="mt-2 flex h-max items-center justify-center space-x-1 self-start py-2"
            >
              <Text extra="sr-only">Loading...</Text>
              <div className="h-3 w-3 animate-bounce rounded-full bg-darkBlue/85 [animation-delay:-0.3s] dark:bg-lightGray/80"></div>
              <div className="h-3 w-3 animate-bounce rounded-full bg-darkBlue/85 [animation-delay:-0.15s] dark:bg-lightGray/80"></div>
              <div className="h-3 w-3 animate-bounce rounded-full bg-darkBlue/85 dark:bg-lightGray/80"></div>
            </div>
          )}
          <div ref={lastMessageRef} />
        </div>
      </div>

      <div className="absolute bottom-0 left-0 right-0 bg-opacity-0 p-4 pb-0">
        <form
          onSubmit={onSubmit}
          className="relative flex flex-col items-end"
        >
          <div className="absolute top-3 right-1 flex space-x-2">
            <button
              type="button"
              onClick={handleDeleteClick}
              className="p-1 pr-1.5 text-lightBlue transition duration-200 rounded-lg hover:bg-lightBlue/20"
            >
              <LuDelete className="h-5 w-5" />
            </button>
            <button
              type="button"
              onClick={onSubmit}
              className={`p-1 transition duration-200 rounded-lg hover:bg-lightBlue/20 ${newMessage.length > 0
                ? 'text-lightBlue hover:bg-lightBlue/20'
                : 'text-lightBlue hover:!bg-lightBlue/0 cursor-not-allowed'
                }`}
            >
              <GiPlayButton className="h-5 w-5" />
            </button>

          </div>
          {showTooltip && isTooltipVisible && newMessage.length === 0 && (
            <div className="absolute top-10 left-4 text-[10px] text-normalGray">
              {model}
            </div>
          )}
          <textarea
            className="w-full p-4 pb-2 pr-20 rounded-t-lg rounded-b-none resize-none border-t border-x border-normalGray/50 text-sm bg-[#F9F9F9] placeholder-normalGray outline-none focus:outline-none transition-all duration-500 md-max:pb-1 dark:border-normalGray/20 dark:bg-[#525f8a]"
            placeholder={inputPlaceholder}
            value={newMessage}
            onFocus={() => setIsTooltipVisible(true)}
            onBlur={() => setIsTooltipVisible(false)}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </form>
      </div>
      
      <Modal
        title="Delete"
        isOpen={deleteModalOpen}
        submitHandler={handleDeleteConfirm}
        onClose={() => setDeleteModalOpen(false)}
        isDanger
      >
        <div>Are you sure you want to delete all messages?</div>
      </Modal>
    </div>
  );
};

export default Chat;