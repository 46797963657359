import { IAction } from 'app/types/actions';
import { fetchData } from 'app/utils/fetch/request';
import Cookies from 'js-cookie';

export const getAllActions = async (): Promise<IAction[]> => {
  const token = Cookies.get('accessToken');
  const profileId = Cookies.get('profileId');

  return await fetchData<IAction[]>(
    `${import.meta.env.VITE_APP_USER_API}/profiles/${profileId}/actions`,
    'GET',
    token,
  );
};

export const getActionById = async (id: string): Promise<IAction> => {
  const token = Cookies.get('accessToken');
  const url = `${import.meta.env.VITE_APP_USER_API}/actions/${id}`;
  return await fetchData<IAction>(url, 'GET', token);
};
