import { truncateText } from 'app/utils/truncateText/truncateText';
import { Text } from 'shared/Text';

export const VariableWrapper = (props: {
  varName: string;
  children: React.ReactNode;
}) => {
  const { varName, children } = props;
  return (
    <div className="flex flex-row items-center gap-2 mb-2">
      <div className="w-1/4 flex items-center justify-center">
        <Text>{truncateText(varName, 12)}</Text>
      </div>
      <div className="w-3/4">{children}</div>
    </div>
  );
};
