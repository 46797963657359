import React, { useState } from 'react';
import { FaEllipsisV } from 'react-icons/fa';

interface ICardBlockProps {
  id: string;
  handleClick: () => void;
  handleDelete?: () => void;
  children: React.ReactNode;
  menuChildren?: React.ReactNode;
}

export const CardBlock: React.FC<ICardBlockProps> = ({
  id,
  handleClick,
  handleDelete,
  children,
  menuChildren,
}) => {
  const [showMenu, setShowMenu] = useState<string>('');

  return (
    <div
      key={id}
      onClick={handleClick}
      className="relative cursor-pointer rounded bg-white p-4 shadow hover:bg-gray-50 dark:bg-navy-700 hover:dark:bg-navy-800"
    >
      <div className="flex items-center justify-between">
        {children}
        {handleDelete && (
          <div className="relative">
            <button
              className="text-gray-500 hover:text-gray-700"
              onClick={(e) => {
                e.stopPropagation();
                setShowMenu(showMenu === id ? '' : id);
              }}
            >
              <FaEllipsisV />
            </button>
            {showMenu === id && (
              <div
                className="absolute right-0 z-10 mt-2 w-48 rounded-md bg-white shadow-lg"
                onMouseLeave={() => setShowMenu('')}
              >
                {menuChildren}
                {handleDelete && (
                  <button
                    className="block w-full px-4 py-2 text-left text-red-500 hover:bg-red-100 hover:rounded-md"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete();
                    }}
                  >
                    Delete
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
