import React, { useState } from 'react';

interface ISide {
  title: string;
  component: React.ReactNode;
}

interface LayoutProps {
  leftSide: ISide;
  upperRight: ISide;
  lowerRight: ISide;
}

export const AdaptiveTripleSwitch: React.FC<LayoutProps> = ({
  leftSide,
  upperRight,
  lowerRight,
}) => {
  const [selectedColumn, setSelectedColumn] = useState<
    'left' | 'upperRight' | 'lowerRight'
  >('left');

  return (
    <div className="h-full flex flex-col md:flex-row">
      <div className="mb-4 flex w-full justify-center md:hidden">
        <div className="flex w-full overflow-hidden rounded-full text-md bg-lightGray/50 dark:bg-lightGray/90">
          <button
            className={`flex-1 px-4 py-2 ${
              selectedColumn === 'left'
                ? 'bg-normalBlue text-white dark:bg-darkBlue/85'
                : 'text-black'
            }`}
            onClick={() => setSelectedColumn('left')}
          >
            {leftSide.title}
          </button>
          <button
            className={`flex-1 px-4 py-2 ${
              selectedColumn === 'upperRight'
                ? 'bg-normalBlue text-white dark:bg-darkBlue/85'
                : 'text-black'
            }`}
            onClick={() => setSelectedColumn('upperRight')}
          >
            {upperRight.title}
          </button>
          <button
            className={`flex-1 px-4 py-2 ${
              selectedColumn === 'lowerRight'
                ? 'bg-normalBlue text-white dark:bg-darkBlue/85'
                : 'text-black'
            }`}
            onClick={() => setSelectedColumn('lowerRight')}
          >
            {lowerRight.title}
          </button>
        </div>
      </div>
      <div
        className={`flex-1 ${
          selectedColumn === 'left' ? 'block' : 'hidden'
        } md:block`}
      >
        {leftSide.component}
      </div>
      <div
        className={`${selectedColumn === 'left' ? 'hidden md:flex' : 'flex'} flex-1 h-full  flex-col`}
      >
        <div
          className={`h-full overflow-y-auto ${
            selectedColumn === 'upperRight' ? 'block' : 'hidden'
          } md:block`}
        >
          {upperRight.component}
        </div>
        <div
          className={`h-full overflow-y-auto ${
            selectedColumn === 'lowerRight' ? 'block' : 'hidden'
          } md:block`}
        >
          {lowerRight.component}
        </div>
      </div>
    </div>
  );
};
