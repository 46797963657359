import React from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ESizeText, EWeightText, Text } from 'shared/Text';

interface LogoutButtonProps {
  size?: ESizeText;
  weight?: EWeightText;
  color?: string;
}

const LogoutButton: React.FC<LogoutButtonProps> = ({
  size = ESizeText.SMALL,
  weight = EWeightText.MEDIUM,
  color = 'text-red-500 hover:text-red-400',
}) => {
  const navigate = useNavigate();

  const handleLogout = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    Cookies.remove('accessToken');
    navigate('auth/log-in');
  };

  return (
    <a href=" " onClick={handleLogout}>
      <Text size={size} weight={weight} color={color}>
        Log Out
      </Text>
    </a>
  );
};

export default LogoutButton;
