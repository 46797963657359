import { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Input } from 'shared/Input';
import { Checkbox } from 'shared/Checkbox';
import { SubmitButton } from 'shared/SubmitButton';
import { Text, ESizeText, EWeightText } from 'shared/Text';
import { GoogleButton } from 'shared/GoogleButton';

interface SignUpProps {
  setIsRegistered?: (value: boolean) => void;
}

export default function SignUp({ setIsRegistered }: SignUpProps) {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [error, setError] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [isUsernameValid, setIsUsernameValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);

  // const navigate = useNavigate();

  useEffect(() => {
    if (
      username &&
      email &&
      password &&
      confirmPassword &&
      acceptTerms &&
      isUsernameValid &&
      isEmailValid &&
      isPasswordValid &&
      isConfirmPasswordValid
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [
    username,
    email,
    password,
    confirmPassword,
    acceptTerms,
    isUsernameValid,
    isEmailValid,
    isPasswordValid,
    isConfirmPasswordValid,
  ]);

  const handleSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(''); // Clear any previous errors

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    if (!acceptTerms) {
      setError('You must accept the Terms and Conditions');
      return;
    }
    try {
      const registerResponse = await fetch(
        `${import.meta.env.VITE_APP_USER_API}/auth/register`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username,
            email,
            password,
            isVerified: false,
          }),
        },
      );
      if (!registerResponse.ok) {
        throw new Error('Failed to sign up');
      }

      /*
       * WARNING
       *
       * This part of the code was commented out because it
        was necessary to separate the registration part from
        the authorization part.
       *
       */

      // const register = await registerResponse.json();

      // const payloadResponse = await fetch(
      //   `${import.meta.env.VITE_APP_USER_API}/auth/payload`,
      //   {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //       Authorization: `Bearer ${register.accessToken}`,
      //     },
      //   },
      // );
      // if (!payloadResponse.ok) {
      //   throw new Error('Failed to fetch user data');
      // }
      // const payload = await payloadResponse.json();

      // Cookies.set('accessToken', register.accessToken, {
      //   expires: 1,
      // });
      // Cookies.set('profileId', payload.mongoUserId, {
      //   expires: 1,
      // });
      // Cookies.set('username', payload.username, {
      //   expires: 1,
      // });
      // Cookies.set('email', payload.email, {
      //   expires: 1,
      // });

      // navigate('/');
      if (setIsRegistered) {
        setIsRegistered(true);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unknown error occurred');
      }
      console.error('Error signing up:', error);
    }
  };

  return (
    <div className="flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign up section */}
      <div className="w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px] sm-max:mt-0">
        <h4 className="mb-2.5 text-4xl font-bold text-normalBlue dark:text-white">
          Sign Up
        </h4>
        <p className="mb-6 ml-1 text-base text-lightBlue">
          Enter your email and password to sign up!
        </p>
        {error && (
          <p className="mb-4 text-sm font-medium text-red-500">{error}</p>
        )}
        <form onSubmit={handleSignUp} className="flex flex-col gap-2">
          {/* Username */}
          <Input
            extra=""
            label="Username*"
            placeholder="Username"
            id="username"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            shouldValidate={true}
            setSubmitActive={setIsUsernameValid}
          />
          {/* Email */}
          <Input
            extra=""
            label="Email*"
            placeholder="mail@simmmple.com"
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            shouldValidate={true}
            setSubmitActive={setIsEmailValid}
          />

          {/* Password */}
          <Input
            extra=""
            label="Password*"
            placeholder="Min. 8 characters"
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            shouldValidate={true}
            setSubmitActive={setIsPasswordValid}
          />

          {/* Confirm Password */}
          <Input
            extra=""
            label="Confirm Password*"
            placeholder="Re-enter your password"
            id="confirm-password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            shouldValidate={true}
            setSubmitActive={() => {
              if (password && password == confirmPassword) {
                setIsConfirmPasswordValid(true);
              } else {
                setIsConfirmPasswordValid(false);
              }
            }}
            password={password}
          />

          {/* Checkbox */}
          <div className="mb-2 flex items-center justify-between px-2">
            <div className="flex items-center">
              <Checkbox
                checked={acceptTerms}
                onChange={() => setAcceptTerms(!acceptTerms)}
              />
              <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                I accept the Terms and Conditions
              </p>
            </div>
          </div>
          <SubmitButton
            label="Sign Up"
            isActive={!isButtonDisabled}
            extra="py-3"
          />
        </form>
        <div className="my-4">
          <Text
            size={ESizeText.SMALL}
            weight={EWeightText.MEDIUM}
            extra="text-darkBlue"
          >
            Already have an account?
          </Text>
          <a
            href="/auth/sign-in"
            className="ml-1 text-sm font-medium transition duration-200 text-lightBlue hover:text-darkBlue dark:text-white dark:hover:text-lightBlue"
          >
            Sign In
          </a>
        </div>
        <GoogleButton />
      </div>
    </div>
  );
}
