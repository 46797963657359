import LogoutButton from 'entities/LogoutButton/LogoutButton';
import Banner from './components/Banner';
import ChangePassword from './components/ChangePassword';
import { ESizeText } from 'shared/Text';

const ProfileOverview = () => {
  return (
    <div className="flex w-full flex-col gap-5">
      <div className="w-full flex h-fit flex-col gap-5 lg:grid">
        <div className="col-span-4 lg:!mb-0">
          <Banner />
        </div>
        <div className="col-span-4 lg:!mb-0">
          <ChangePassword />
        </div>
        <div className="col-span-4 lg:!mb-0 justify-self-end p-3">
          <LogoutButton size={ESizeText.MEDIUM} />
        </div>
      </div>
    </div>
  );
};

export default ProfileOverview;
