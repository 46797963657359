import { LabledBlock } from 'widgets/LabledBlock/LabledBlock';
import { FileUpload } from './FileUpload';
import { Parsing } from './Parsing';
import { useEffect, useState } from 'react';
import { IKnowledge } from 'app/types/knowledge';
import { generateUniqueText } from 'app/utils/random/agent';
import { Textarea } from 'shared/Textarea';
import { Input } from 'shared/Input';
import { useNavigate, useParams } from 'react-router-dom';
import { AdaptiveTripleSwitch } from 'shared/TriangularLayout';
import Loader from 'entities/Loader/Loader';
import { getKnowldgeById } from '../api/knowledge';
import { FiEdit } from 'react-icons/fi';
import Cookies from 'js-cookie';
import { fetchData } from 'app/utils/fetch/request';
import { Message } from 'app/types/messages';
import { TimeoutMessage } from 'widgets/TimeoutMessage';
import { ContentWrapper } from 'widgets/ContentWrapper';

export const KnowledgePanel = (props: { type: 'create' | 'update' }) => {
  const { type } = props;
  const navigate = useNavigate();
  const { id } = useParams();

  const [temporaryName, setTemporaryName] = useState(generateUniqueText());
  const [statusMessage, setStatusMessage] = useState<Message>();
  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sumbitLoading, setSubmitLoading] = useState(false);

  const initialKnowledgeData = {
    name: temporaryName,
    data: '',
  };

  const [knowledge, setKnowledge] = useState<IKnowledge>(initialKnowledgeData);
  const [initialKnowledge, setInitialKnowledge] = useState<IKnowledge | null>(
    initialKnowledgeData,
  );

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setKnowledge((prev) => ({ name: prev.name, data: e.target.value }));
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(async () => {
      if (type === 'update') {
        try {
          if (id) {
            const knowledge = await getKnowldgeById(id || '');
            setKnowledge(knowledge);
            setInitialKnowledge(knowledge);
            setTemporaryName(knowledge.name);
          }
        } catch (error) {
          console.error(error);
        }
      }
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (initialKnowledge && knowledge) {
      setIsUpdated(
        JSON.stringify(knowledge) !== JSON.stringify(initialKnowledge),
      );
    }
  }, [knowledge, initialKnowledge]);

  const createSubmitHandler = async () => {
    try {
      setSubmitLoading(true);

      const token = Cookies.get('accessToken');
      const profileId = Cookies.get('profileId');
      const url =
        import.meta.env.VITE_APP_USER_API + `/knowledge-vector/${profileId}`;
      const createdKnowledge = await fetchData<IKnowledge>(
        url,
        'POST',
        token,
        knowledge,
      );
      setSubmitLoading(false);
      setStatusMessage({
        text: 'Knowledge was successfully created',
        type: 'success',
      });
      if (createdKnowledge?._id) {
        setIsUpdated(false);
        navigate('/admin/dashboard/knowledge/' + createdKnowledge?._id);
      }
    } catch (error: any) {
      setSubmitLoading(false);
      setStatusMessage({
        text: 'Creating knowledge error',
        type: 'error',
      });
      console.error('Error on creating knowledge:', error);
    }
  };

  const updateSubmitHandler = async () => {
    try {
      setSubmitLoading(true);
      const token = Cookies.get('accessToken');
      const url = import.meta.env.VITE_APP_USER_API + `/knowledge-vector/${id}`;
      const updatedKnowldge = await fetchData<IKnowledge>(
        url,
        'PUT',
        token,
        knowledge,
      );
      setKnowledge(updatedKnowldge);
      setInitialKnowledge(updatedKnowldge);
      setSubmitLoading(false);
      setStatusMessage({
        text: 'Knowledge was successfully updated',
        type: 'success',
      });
      setTemporaryName(updatedKnowldge.name);
    } catch (error: any) {
      setSubmitLoading(false);
      setStatusMessage({
        text: 'Updating knowledge error',
        type: 'error',
      });
      console.error('Error on fetching knowledge:', error.message);
    }
  };

  return loading || !knowledge ? (
    <Loader />
  ) : (
    <>
      {statusMessage && <TimeoutMessage messages={statusMessage} />}
      <ContentWrapper
        title={knowledge.name}
        description="Knowledge"
        isSubmitActive={isUpdated && !!knowledge.name && !!knowledge.data}
        submitLoading={sumbitLoading}
        onBackClick={() => {
          navigate('/admin/dashboard/knowledge');
        }}
        sumbitLabel={type === 'create' ? 'Create' : 'Update'}
        sumbitHandler={
          type === 'update' ? updateSubmitHandler : createSubmitHandler
        }
        modalTitle="Edit name"
        modalButton={
          <div className="flex justify-center items-center w-8 h-8 transition duration-200 rounded-lg hover:bg-lightBlue/20">
            <FiEdit className="w-5 h-5" />
          </div>
        }
        modalContent={
          <div className="flex flex-col">
            <Input
              label="Name"
              type="text"
              onChange={(e) => {
                setTemporaryName(e.target.value);
              }}
              value={temporaryName}
            />
          </div>
        }
        modalSubmitHandler={() => {
          setKnowledge({
            ...knowledge,
            name: temporaryName,
          });
        }}
        modalCloseHandler={() => {
          setTemporaryName(knowledge.name);
        }}
      >
        <AdaptiveTripleSwitch
          leftSide={{
            title: 'Parsing',
            component: (
              <LabledBlock label="Parsing">
                <Parsing
                  setKnowledge={setKnowledge}
                  setStatus={setStatusMessage}
                />
              </LabledBlock>
            ),
          }}
          upperRight={{
            title: 'File Upload',
            component: (
              <LabledBlock label="File upload">
                <FileUpload
                  setKnowledge={setKnowledge}
                  setStatus={setStatusMessage}
                />
              </LabledBlock>
            ),
          }}
          lowerRight={{
            title: 'Content',
            component: (
              <LabledBlock label="Content">
                <Textarea
                  onChange={handleChange}
                  value={knowledge?.data || ''}
                  placeholder="Type something..."
                  minHeight="200px"
                  extra="dark:bg-lightGray/10 dark:border-normalBlue dark:focus:border-lightGray/60"
                />
              </LabledBlock>
            ),
          }}
        />
      </ContentWrapper>
    </>
  );
};
