import { SearchContext } from 'app/context/SearchContext';
import { useContext } from 'react';
import { FiSearch } from 'react-icons/fi';

export const Search = () => {
  const { searchValue, setSearchValue } = useContext(SearchContext);

  return (
    <div className="flex h-full w-full items-center rounded-full bg-lightGray/20 text-navy-700 dark:bg-darkBlue dark:text-white">
      <p className="pl-3 pr-2 text-xl">
        <FiSearch className="h-4 w-4 text-normalGray dark:text-white" />
      </p>
      <input
        type="text"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder="Search..."
        className="block h-full w-full rounded-full bg-lightGray/5 text-sm font-medium text-darkBlue outline-none placeholder:!text-normalGray/80 dark:bg-darkBlue dark:text-white dark:placeholder:!text-white"
      />
    </div>
  );
};
