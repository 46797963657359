import React, { useRef, CSSProperties } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { ClipboardButton } from 'shared/ClipboardButton';
import { duotoneSea } from 'react-syntax-highlighter/dist/esm/styles/prism';

interface MessageProps {
  message: {
    content: string;
    role: string;
  };
  isLastMessage: boolean;
  userStyle?: CSSProperties;
  botStyle?: CSSProperties;
  codeStyle?: CSSProperties;
  inlineCodeStyle?: CSSProperties;
  syntaxHighlightStyle?: any;
}

interface CodeProps {
  node: any;
  inline: boolean;
  className: string;
  children: React.ReactNode;
  [key: string]: any;
}

const Message: React.FC<MessageProps> = ({
  message,
  isLastMessage,
  inlineCodeStyle = {},
  syntaxHighlightStyle = duotoneSea,
}) => {
  const messageRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={isLastMessage ? messageRef : null}
      className={`max-w-[80%] rounded-xl p-3 font-geist md-max:max-w-[50ch] 2xl:max-w-[70%] ` +
        (message.role === 'user'
        ? 'mb-2 self-end text-sm text-white bg-darkBlue/85 dark:bg-lightBlue/60'
        : 'mb-2 self-start text-sm text-black bg-lightGray/30 border border-lightGray/30 dark:bg-lightGray/70'
        )
      }
    >
      <ReactMarkdown
        children={message.content}
        remarkPlugins={[remarkGfm]}
        components={{
          code({ inline, className, children, ...props }: Partial<CodeProps>) {
            const match = /language-(\w+)/.exec(className || '');
            return !inline && match ? (
              <div style={{ position: 'relative' }}>
                <ClipboardButton
                textToCopy={String(children).replace(/\n$/, '')}
                  extra="absolute right-0 text-white/80 lg:hover:text-white lg:hover:bg-opacity-0 dark:lg:hover:bg-opacity-0 dark:text-lightGray/80"
                />
                <SyntaxHighlighter
                  style={syntaxHighlightStyle}
                  language={match[1]}
                  PreTag="div"
                  codeTagProps={{
                    className:
                      'scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-transparent !whitespace-pre-wrap break-words break-all',
                  }}
                  {...props}
                >
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              </div>
            ) : (
              <code
                className={className}
                style={{
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',
                  wordBreak: 'break-all',
                  overflowWrap: 'break-word',
                  fontFamily: '"Ubuntu Mono", monospace',
                  ...inlineCodeStyle,
                }}
                {...props}
              >
                {children}
              </code>
            );
          },
        }}
      />
    </div>
  );
};

export default Message;
