import { IKnowledge } from 'app/types/knowledge';
import { fetchData } from 'app/utils/fetch/request';
import Cookies from 'js-cookie';

export const getAllKnowledge = async () => {
  const token = Cookies.get('accessToken');
  const profileId = Cookies.get('profileId');

  return await fetchData<IKnowledge[]>(
    `${import.meta.env.VITE_APP_USER_API}/profiles/${profileId}/knowledge-vector`,
    'GET',
    token,
  );
};

export const getKnowldgeById = async (id: string): Promise<IKnowledge> => {
  const token = Cookies.get('accessToken');
  const url = `${import.meta.env.VITE_APP_USER_API}/knowledge-vector/${id}`;
  return await fetchData<IKnowledge>(url, 'GET', token);
};
