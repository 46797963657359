import React, { useEffect, useState } from 'react';
import { FiAlignJustify } from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';
import { RiMoonFill, RiSunFill } from 'react-icons/ri';
import {} from 'react-icons/io';
import Cookies from 'js-cookie';
import HorizontalLink from 'shared/HorizontalLink/HorizontalLink';
import { Popover } from 'shared/Popover';
import { Text, ESizeText } from 'shared/Text';
import { Search } from 'features/Search/Search';
import LogoutButton from 'entities/LogoutButton/LogoutButton';


const subroutes = [
  { path: 'dashboard/agents', name: 'Agents' },
  { path: 'dashboard/knowledge', name: 'Knowledge' },
  { path: 'dashboard/triggers', name: 'Triggers' },
];

const Navbar = (props: {
  onOpenSidenav: () => void;
  brandText: string;
  secondary?: boolean | string;
}) => {
  const [picture, setPicture] = useState(
    'https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg',
  );
  const [username] = useState<string>(String(Cookies.get('username')));
  const [subscriptionTitle] = useState<string>(String(Cookies.get('subscriptionTitle')));

  const location = useLocation();

  const { onOpenSidenav, brandText } = props;
  const [darkmode, setDarkmode] = React.useState(false);

  useEffect(() => {
    setTimeout(async () => {
      const token = Cookies.get('accessToken');
      const profileId = Cookies.get('profileId');

      try {
        const response = await fetch(
          `${import.meta.env.VITE_APP_USER_API}/profiles/${profileId}/credentials`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (!response.ok) {
          throw new Error('Failed to get agents');
        }

        const data = await response.json();
        console.log(data);
        data.picture && setPicture(data.picture);
      } catch (err) {
        console.error(err);
      }
    });
  });

  return (
    <nav className="sticky top-4 z-40 flex gap-2 flex-col lg:flex-row lg:items-center justify-between rounded-xl bg-[#F4F6F8] p-2 backdrop-blur-xl dark:bg-darkBlue">
    {/* <nav className="fixed w-full top-4 z-40 flex gap-2 flex-col lg:flex-row lg:items-center justify-between rounded-xl bg-[#F4F6F8] p-2 backdrop-blur-xl dark:bg-darkBlue"> */}
      <div className="ml-[6px]">
        <div className="h-6 pt-1">
          <a
            className="text-sm font-normal text-darkBlue hover:underline dark:text-white dark:hover:text-white"
            href=" "
          >
            Pages
            <Text size={ESizeText.SMALL} extra="mx-1">
              {' '}
              /{' '}
            </Text>
          </a>
          <Link
            className="text-sm font-normal capitalize text-darkBlue hover:underline dark:text-white dark:hover:text-white"
            to="#"
          >
            {brandText}
          </Link>
        </div>
        <p className="shrink text-[25px] capitalize text-darkBlue dark:text-white hidden lg:block">
          <Link
            to="#"
            className="font-bold capitalize hover:text-darkBlue dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p>
      </div>

      {location.pathname.includes('dashboard') && (
        <div className="flex space-x-4 self-center order-last lg:order-none max-w-full lg:overflow-y-visible">
          {subroutes.map((el) => (
            <div key={el.path}>
              <HorizontalLink
                path={el.path}
                isActive={location.pathname.includes(el.path)}
              >
                {el.name}
              </HorizontalLink>
            </div>
          ))}
        </div>
      )}
      <div className="flex items-center lg-max:items-start">
        {subscriptionTitle === 'Premium' && (
          <Text size={ESizeText.SMALL}
            extra={`absolute lg-max:top-3 lg-max:right-2 mt-[3px] w-max px-3 py-1 rounded-full bg-lightBlue/40 !font-semibold 3xl-max:text-xs` +
              (location.pathname.includes('dashboard') ||
                location.pathname.includes('chats') ||
                location.pathname.includes('prompts')
              ? ' top-[50] right-96 lg:right-64 2xl:right-80 3xl:right-96'
              : ' top-[50] right-[140px] xl:right-[120px]')
            }>
            Club Member
          </Text>
        )}
        <div
          className={
            'relative mt-2 lg:mt-[3px] flex h-[61px] flex-grow items-center justify-between gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-normalBlue dark:shadow-none md:flex-grow-0 md:gap-1 xl:gap-2' +
            (location.pathname.includes('dashboard') ||
              location.pathname.includes('chats') ||
              location.pathname.includes('prompts')
              ? ' w-full lg:w-[235px] 2xl:w-[300px] 3xl:w-[355px]'
              : ' w-full max-w-fit')
          }
        >
          <Text
            size={ESizeText.LARGE}
            onClick={onOpenSidenav}
            extra="flex cursor-pointer xl:hidden"
          >
            <FiAlignJustify className="h-5 w-5 text-lightBlue dark:text-white" />
          </Text>
          {(location.pathname.includes('dashboard') ||
            location.pathname.includes('chats') ||
            location.pathname.includes('prompts')) && <Search />}
          <div
            className="cursor-pointer text-gray-600"
            onClick={() => {
              if (darkmode) {
                document.body.classList.remove('dark');
                setDarkmode(false);
              } else {
                document.body.classList.add('dark');
                setDarkmode(true);
              }
            }}
          >
            {darkmode ? (
              <RiSunFill className="h-4 w-4 text-lightBlue dark:text-white mx-2" />
            ) : (
              <RiMoonFill className="h-4 w-4 text-lightBlue dark:text-white mx-2" />
            )}
          </div>
          {/* Profile & Dropdown */}
          <a className="lg:hidden" href="/admin/profile">
            <img
              className="h-10 w-10 rounded-full hover:cursor-pointer min-w-10"
              src={picture}
              alt=""
            />
          </a>
          <Popover
            trigger={
              <img
                className="h-10 w-10 hidden lg:block rounded-full hover:cursor-pointer min-w-10"
                src={picture}
                alt=""
              />
            }
            extra={'hidden lg:block py-2 top-8 -left-[200px] w-max'}
          >
            <div className="flex w-56 flex-col justify-start shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="ml-4 mt-3">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-darkBlue dark:text-white">
                    👋 Hey, {username}
                    {subscriptionTitle === 'Premium' && (
                      <Text size={ESizeText.SMALL} extra="ml-2 text-normalGray">
                        premium
                      </Text>
                    )}
                  </p>{' '}
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />
              <div className="mt-3 ml-4 flex flex-col">
                <a href="/admin/profile" className="text-sm font-medium">
                  Edit Profile{' '}
                </a>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />
              <div className="my-3 ml-4 flex flex-col">
                <LogoutButton />
              </div>
            </div>
          </Popover>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
