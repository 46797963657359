import React from 'react';

export enum ESizeHeader {
    SMALL = "small",
    MEDIUM = "medium",
    LARGE = "large",
    HUGE = "huge"
}

interface H1Props {
    children: React.ReactNode;
    size?: ESizeHeader;
    extra?: string;
}

export const H1: React.FC<H1Props> = ({ children, size = ESizeHeader.MEDIUM, extra }) => {
    // const fontSize = size === ESizeHeader.MEDIUM ? 'text-xl' : 'text-3xl';
    let fontSize;
    switch (size) {
        case ESizeHeader.SMALL:
            fontSize = 'text-lg';
            break;
        case ESizeHeader.LARGE:
            fontSize = 'text-2xl';
            break;
        case ESizeHeader.HUGE:
            fontSize = 'text-3xl';
            break;
        default:
            fontSize = 'text-xl';
    }
    return <h1 className={`${fontSize} font-semibold ${extra}`}> {children}</h1>;
};