import { ITrigger } from 'app/types';
import { fetchData } from 'app/utils/fetch/request';
import Cookies from 'js-cookie';

export const getAllTriggers = async () => {
  const token = Cookies.get('accessToken');
  const profileId = Cookies.get('profileId');

  return await fetchData<ITrigger[]>(
    `${import.meta.env.VITE_APP_USER_API}/profiles/${profileId}/triggers`,
    'GET',
    token,
  );
};

export const getTriggerById = async (id: string): Promise<ITrigger> => {
  const token = Cookies.get('accessToken');
  const url = `${import.meta.env.VITE_APP_USER_API}/triggers/${id}`;
  return await fetchData<ITrigger>(url, 'GET', token);
};
