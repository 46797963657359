import { Navigate, Route, Routes } from 'react-router-dom';
import { KnowledgePage } from './pages/Knowledge';
import { Agents } from './pages/Agents';
import { TriggerPage } from './pages/TriggerPage';
import { AgentControl } from './components/AgentControl';
import { KnowledgePanel } from './components/KnowledgePanel';

const Dashboard = () => {
  return (
    <Routes>
      <Route path="agents" element={<Agents />} />
      <Route path="agents/new" element={<AgentControl type="create" />} />
      <Route path="agents/:id" element={<AgentControl type="update" />} />

      <Route path="knowledge" element={<KnowledgePage />} />
      <Route path="knowledge/new" element={<KnowledgePanel type="create" />} />
      <Route path="knowledge/:id" element={<KnowledgePanel type="update" />} />

      <Route path="triggers" element={<TriggerPage />} />

      <Route path="/*" element={<Navigate to="agents" replace />} />
    </Routes>
  );
};

export default Dashboard;
