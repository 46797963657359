import React from 'react';

interface AdvantageCardProps {
    title: string;
    description: string[];
    content: React.ReactNode;
    color: string;
}

const AdvantageCard: React.FC<AdvantageCardProps> = ({ title, description, content, color }) => {
    return (
        <div
            className={`pl-6 pt-4 flex flex-col justify-between items-end gap-6 rounded-lg shadow-md lg-max:w-full lg-max:h-[300px] lg:h-[300px] overflow-hidden cursor-pointer transform transition-transform duration-300 ease-in-out lg:hover:scale-105 lg:hover:shadow-lg`}
            style={{ background: color }}
        >
            <div className="w-full flex flex-col gap-3">
                <h3 className="text-base font-[500] text-white lg:text-lg">{title}</h3>
                <div className="text-[#F5F5F5] text-[13px] !font-[100] lg:text-sm">
                    {description.map((line, index) => (
                        <span key={index} className="block">{line}</span>
                    ))}
                </div>
            </div>
            <div className="w-[65%] max-h-[45%] px-2 pb-5 bg-white rounded-tl-lg flex-1 flex items-end md:w-[75%] lg:max-h-[55%] 4xl:w-[55%]">{content}</div>
        </div>
    );
};

export default AdvantageCard;