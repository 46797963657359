import { AdaptiveDoubleSwitch } from 'shared/AdaptiveDoubleSwitch';
import { LabledBlock } from 'widgets/LabledBlock/LabledBlock';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { generateUniqueText } from 'app/utils/random/agent';
import { IPrompt } from 'app/types/prompt';
import { fetchData } from 'app/utils/fetch/request';
import Loader from 'entities/Loader/Loader';
import { FiEdit } from 'react-icons/fi';
import { TimeoutMessage } from 'widgets/TimeoutMessage';
import { Message } from 'app/types/messages';
import { AdvancedTextarea } from './components/AdvancedTextarea';
import { getPromptById } from 'pages/Dashboard/api/prompt';
import { PromptItemWrapper } from './components/PromptItemWrapper';
import { Textarea } from 'shared/Textarea';
import { SubmitButton } from 'shared/SubmitButton';
import { VariableWrapper } from './components/VariableWrapper';
import { Input } from 'shared/Input';
import { ContentWrapper } from 'widgets/ContentWrapper';
import { Select } from 'shared/Select';
import { IModelVersion } from 'app/types/models';

function replaceTemplateString(
  template: string,
  variables: { [key: string]: string },
): string {
  return template.replace(/{{(.*?)}}/g, (_, key) => {
    return key.trim() in variables ? variables[key.trim()] : `{{${key}}}`;
  });
}

export const PromptPanel = (props: { type: 'create' | 'update' }) => {
  const { type } = props;
  const navigate = useNavigate();
  const { id } = useParams();

  const [statusMessage, setStatusMessage] = useState<Message>();
  const [temporaryName, setTemporaryName] = useState(generateUniqueText());
  const initialPromptData: IPrompt = {
    name: temporaryName,
    content: '',
  };

  const [prompt, setPrompt] = useState<IPrompt>(initialPromptData);
  const [initialPrompt, setInitialPrompt] = useState<IPrompt | null>(
    initialPromptData,
  );

  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [messages, setMessages] = useState<
    { type: 'Human' | 'AI'; content: string }[]
  >([]);
  const [models, setModels] = useState<IModelVersion[]>();
  const [selectedModelId, setSelectedModelId] = useState<string>('');
  const [response, setResponse] = useState<string>('');
  const [vars, setVars] = useState<string[]>([]);
  const [values, setValues] = useState<{ [key: string]: string }>({});
  const [requestLoading, setRequestLoading] = useState<boolean>(false);

  const requestHandler = async () => {
    const token = Cookies.get('accessToken');

    try {
      setRequestLoading(true);
      const responseFromLLM: { message: { content: string } } = await fetchData(
        `${import.meta.env.VITE_APP_USER_API}/prompts/llm`,
        'POST',
        token,
        {
          versionId: selectedModelId,
          messages: [
            {
              role: 'system',
              content: replaceTemplateString(prompt.content, values),
            },
            ...messages.map((message) => ({
              content: message.content,
              role: message.type === 'Human' ? 'user' : 'assistant',
            })),
          ],
        },
      );
      setRequestLoading(false);
      setResponse(responseFromLLM?.message?.content);
      setStatusMessage({ text: 'Success!', type: 'success' });
    } catch (error) {
      setRequestLoading(false);
      setStatusMessage({ text: 'Error on fetching llm', type: 'error' });
    }
  };

  const getAllModels = async () => {
    const token = Cookies.get('accessToken');
    try {
      const url = `${import.meta.env.VITE_APP_USER_API}/models/versions`;
      const models = await fetchData<any[]>(url, 'GET', token);
      setModels(models);
      setSelectedModelId(models.at(0)?._id || '');
    } catch (error) {
      setStatusMessage({
        text: 'Getting models error',
        type: 'error',
      });
      console.error('Error on fetching props:', error);
    }
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(async () => {
      await getAllModels();
      if (type === 'update') {
        try {
          if (id) {
            const prompt = await getPromptById(id || '');
            setPrompt(prompt);
            setTemporaryName(prompt.name);
            setInitialPrompt(prompt);
          }
        } catch (error) {
          setStatusMessage({
            text: 'Getting prompt error',
            type: 'error',
          });
          console.error(error);
        }
      }
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (initialPrompt && prompt) {
      setIsUpdated(JSON.stringify(prompt) !== JSON.stringify(initialPrompt));
    }
  }, [prompt, initialPrompt]);

  const createSubmitHandler = async () => {
    try {
      setSubmitLoading(true);
      const token = Cookies.get('accessToken');
      const profileId = Cookies.get('profileId');
      const url = import.meta.env.VITE_APP_USER_API + `/prompts/${profileId}`;
      const createdPrompt = await fetchData<IPrompt>(
        url,
        'POST',
        token,
        prompt,
      );
      setSubmitLoading(false);
      setStatusMessage({
        text: 'Prompt was successfully created',
        type: 'success',
      });
      if (createdPrompt?._id) {
        setIsUpdated(false);
        navigate('/admin/prompts/main/' + createdPrompt?._id);
      }
    } catch (error: any) {
      setSubmitLoading(false);
      setStatusMessage({
        text: 'Creating prompt error',
        type: 'error',
      });
      console.error('Error on creating prompt:', error);
    }
  };

  const updateSubmitHandler = async () => {
    try {
      setSubmitLoading(true);
      const token = Cookies.get('accessToken');
      const url = import.meta.env.VITE_APP_USER_API + `/prompts/${id}`;
      const updatedPrompt = await fetchData<IPrompt>(url, 'PUT', token, {
        name: prompt.name,
        content: prompt.content,
      });
      setInitialPrompt(updatedPrompt);
      setPrompt(updatedPrompt);
      setSubmitLoading(false);
      setStatusMessage({
        text: 'Prompt was successfully updated',
        type: 'success',
      });
      setTemporaryName(updatedPrompt.name);
    } catch (error: any) {
      setSubmitLoading(false);
      setStatusMessage({
        text: 'Updating prompt error',
        type: 'error',
      });
      console.error('Error on fetching prompt:', error.message);
    }
  };

  const handleValuesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  useEffect(() => {
    const regex = /\{\{(.*?)\}\}/g;
    const results: Set<string> = new Set();
    let matches: RegExpExecArray | null;

    while ((matches = regex.exec(prompt.content)) !== null) {
      results.add(matches[1]);
    }

    setVars([...results]);
  }, [prompt]);

  const transformText = (text: string): string => {
    const regex = /(\{\{[^}]*\}\})/g;
    return text.replace(regex, (match) => {
      return match
        .replace(/[^a-zA-Z_{}]/g, '')
        .replace(/_+/g, '_')
        .toLowerCase();
    });
  };

  const handleTextareaChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    setPrompt((prev) => ({
      ...prev,
      content: transformText(e.target.value),
    }));
  };

  return loading || !prompt || !models ? (
    <Loader />
  ) : (
    <>
      {statusMessage && <TimeoutMessage messages={statusMessage} />}
      <ContentWrapper
        title={prompt.name}
        description="Prompt"
        isSubmitActive={isUpdated && !!prompt.name && !!prompt.content}
        submitLoading={submitLoading}
        onBackClick={() => {
          navigate('/admin/prompts/main');
        }}
        sumbitLabel={type === 'create' ? 'Create' : 'Update'}
        sumbitHandler={
          type === 'update' ? updateSubmitHandler : createSubmitHandler
        }
        modalTitle="Edit name"
        modalButton={
          <div className="flex justify-center items-center w-8 h-8 transition duration-200 rounded-lg hover:bg-lightBlue/20">
            <FiEdit className="w-5 h-5" />
          </div>
        }
        modalContent={
          <div className="flex flex-col">
            <Input
              label="Name"
              type="text"
              onChange={(e) => {
                setTemporaryName(e.target.value);
              }}
              value={temporaryName}
            />
          </div>
        }
        modalSubmitHandler={() => {
          setPrompt({
            ...prompt,
            name: temporaryName,
          });
        }}
        modalCloseHandler={() => {
          setTemporaryName(prompt?.name);
        }}
      >
        <AdaptiveDoubleSwitch
          leftSide={{
            title: 'Prompt',
            component: (
              <LabledBlock label="Prompt">
                <div className="flex gap-2 w-full">
                  <div className="w-1/2">
                    <Select
                      value={{
                        label:
                          models.find((model) => model._id === selectedModelId)
                            ?.versionId || '',
                        value:
                          models.find((model) => model._id === selectedModelId)
                            ?._id || '',
                      }}
                      options={models.map((model) => ({
                        label: model.versionId,
                        value: model._id,
                      }))}
                      onChange={(option) => {
                        const singleOption = option as { value: string };
                        setSelectedModelId(singleOption.value);
                      }}
                      extra="mb-2"
                    />
                  </div>
                  <div className="w-1/2">
                    <SubmitButton
                      label="Make request"
                      isLoading={requestLoading}
                      onClick={requestHandler}
                    />
                  </div>
                </div>
                <PromptItemWrapper type="System" textToCopy={prompt.content}>
                  <AdvancedTextarea
                    prompt={prompt}
                    values={values}
                    handleTextareaChange={handleTextareaChange}
                  />
                </PromptItemWrapper>
                {messages.length > 0 &&
                  messages.map((message, index) => (
                    <PromptItemWrapper
                      type={message.type}
                      deleteHandler={() => {
                        const newArray = messages.slice();
                        newArray.splice(index, 2);
                        setMessages(newArray);
                      }}
                    >
                      <Textarea
                        value={message.content}
                        onChange={(
                          e: React.ChangeEvent<HTMLTextAreaElement>,
                        ) => {
                          messages[index].content = e.target.value;
                          setMessages([...messages]);
                        }}
                      />
                    </PromptItemWrapper>
                  ))}
                <div className="w-36">
                  <SubmitButton
                    label="Add"
                    onClick={() => {
                      setMessages((prev) => [
                        ...prev,
                        {
                          type: prev.length % 2 === 0 ? 'Human' : 'AI',
                          content: '',
                        },
                      ]);
                    }}
                  />
                </div>
              </LabledBlock>
            ),
          }}
          rightSide={{
            title: 'Settings',
            component: (
              <div className="h-full flex flex-col">
                <LabledBlock label="Variables">
                  {vars.length > 0 ? (
                    vars.map((item, index) => (
                      <div key={index}>
                        <VariableWrapper varName={item}>
                          <Input
                            name={item}
                            value={values[item] || ''}
                            onChange={handleValuesChange}
                          />
                        </VariableWrapper>
                      </div>
                    ))
                  ) : (
                    <div className="flex w-full h-full justify-center items-center">
                      {'No vars. Added {{ var_name }} to prompt'}
                    </div>
                  )}
                </LabledBlock>
                <LabledBlock label="Response">{response}</LabledBlock>
              </div>
            ),
          }}
        />
      </ContentWrapper>
    </>
  );
};
