// Dropdown.tsx
import React, { useState } from 'react';
import { IoChevronDown } from "react-icons/io5";
import { IoChevronUp } from "react-icons/io5";
import { AiOutlinePlus } from "react-icons/ai";
import { Text, ESizeText, EWeightText } from 'shared/Text';

interface DropdownProps {
  text: string;
  children: React.ReactNode;
  onButtonClick?: () => void;

  onHover?: (isHovered: boolean) => void;
  onToggle?: (isOpen: boolean) => void;
}

const Dropdown: React.FC<DropdownProps> = ({
  text,
  children,
  onButtonClick,

  onHover,
  onToggle,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  // const toggleDropdown = () => {
  //   setIsOpen(!isOpen);
  // };

  const toggleDropdown = () => {
    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);
    if (onToggle) {
      onToggle(newIsOpen);
    }
  };

  return (
    <div
      className="relative w-full max-w-md mx-auto group overflow-hidden"
    >
      <div
        onClick={toggleDropdown}
        onMouseEnter={() => onHover && onHover(true)}
        onMouseLeave={() => onHover && onHover(false)}
        className="flex items-center justify-between p-2 cursor-pointer hover:bg-lightGray hover:bg-opacity-50 hover:rounded transition-all duration-300"
      >
        <div className="flex items-center">
          {isOpen ? <IoChevronUp className="text-normalGray" /> : <IoChevronDown className="text-normalGray" />}
          <Text size={ESizeText.MEDIUM} weight={EWeightText.BOLD} extra="ml-2">{text}</Text>
        </div>
        {/* {buttonIcon && onButtonClick && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              onButtonClick();
            }}
            className="ml-2"
          >
            {buttonIcon}
          </button>
        )} */}
        {onButtonClick && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              onButtonClick();
            }}
            className="ml-2"
          >
            <AiOutlinePlus className="text-normalGray hover:bg-lightGray hover:rounded transition-all duration-500" />
          </button>
        )}
      </div>
      
      {isOpen && (
        <div className="pl-2 py-3">
          <Text size={ESizeText.SMALL} extra="text-normalBlue">{children}</Text>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
