import { IAgentResponse } from 'app/types';
import { fetchData } from 'app/utils/fetch/request';
import Cookies from 'js-cookie';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'shared/Modal';
import { ComponentRow } from 'shared/ComponentRow';
import { AgentCard } from 'shared/AgentCard';
import { ColumnHeaders } from 'shared/ColumnHeaders';
import { IModelVersion } from 'app/types/models';
import { formatNumber } from 'app/utils/formatNumber/formatNumber'; 
import Loader from 'entities/Loader/Loader';
import { TimeoutMessage } from 'widgets/TimeoutMessage';
import { Message } from 'app/types/messages';
import { ErrorMessage } from 'features/ErrorMessage';
import { TooltipMessage } from 'shared/TooltipMessage';
import { SearchContext } from 'app/context/SearchContext';
import { ContentWrapper } from 'widgets/ContentWrapper';
import { CodeBlock } from 'shared/CodeBlock';

export const Agents = () => {
  const { searchValue } = useContext(SearchContext);

  const [agents, setAgents] = useState<IAgentResponse[]>();
  const [deleteModalOpen, setDeleteModalOpen] = useState<string>();
  const [embedModalOpen, setEmbedModalOpen] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState<Message>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const navigate = useNavigate();

  const [username] = useState<string>(String(Cookies.get('username')));
  const [models, setModels] = useState<IModelVersion[]>();

  const filteredAgents = useMemo(() => {
    return agents
      ? agents.filter((agent) =>
          agent.name.toLowerCase().includes(searchValue.toLowerCase()),
        )
      : [];
  }, [searchValue, agents]);

  const getAllAgents = async () => {
    const token = Cookies.get('accessToken');
    const profileId = Cookies.get('profileId');

    try {
      setLoading(true);
      const agents = await fetchData<IAgentResponse[]>(
        `${import.meta.env.VITE_APP_USER_API}/profiles/${profileId}/agents`,
        'GET',
        token,
      );
      setLoading(false);
      setAgents(agents);
    } catch (error) {
      setLoading(false);
      setErrorMessage('Error on getting agents');
      console.error('Error on getting agents:', error);
    }
  };

  const getAllModels = async () => {
    const token = Cookies.get('accessToken');
    try {
      const url = `${import.meta.env.VITE_APP_USER_API}/models/versions`;
      const models = await fetchData<any[]>(url, 'GET', token);
      setModels(models);
    } catch (error) {
      setErrorMessage('Error on getting models');
      console.error('Error on fetching props:', error);
    }
  };

  const getModelName = (modelId: string) => {
    if (!models) return '';
    const model = models.find((model) => model._id === modelId);
    return model
      ? `${model.versionId} (${formatNumber(model.contextWindowLimit)})`
      : '';
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toISOString().split('T')[0];
  };

  useEffect(() => {
    getAllAgents();
    getAllModels();
  }, []);

  const deleteHandler = () => {
    const token = Cookies.get('accessToken');
    setTimeout(async () => {
      try {
        await fetchData(
          `${import.meta.env.VITE_APP_USER_API}/agents/${deleteModalOpen}`,
          'DELETE',
          token,
        );
        setDeleteModalOpen('');
        setStatusMessage({
          text: 'Successfully deleted',
          type: 'success',
        });
      } catch (error) {
        setDeleteModalOpen('');
        setStatusMessage({ text: 'Delete error', type: 'error' });
        console.error('Error on getting agents:', error);
      }
      await getAllAgents();
    });
  };

  const headers = ['Agent Name', 'Description', 'Model', 'Created At', 'User Name', ''];

  return loading ? (
    <Loader />
  ) : (
    <>
      {statusMessage && <TimeoutMessage messages={statusMessage} />}
      <ContentWrapper
        title="Agents"
        description="Your AI-agents"
        sumbitLabel="New"
        sumbitHandler={() => {
          navigate('/admin/dashboard/agents/new');
        }}
      >
        <Modal
          title="Delete"
          isOpen={!!deleteModalOpen}
          submitHandler={deleteHandler}
          onClose={() => {
            setDeleteModalOpen('');
          }}
          isDanger
        >
          <div>Are you sure?</div>
        </Modal>

          {/* {embedModalOpen && (
            <Modal
              title="Embed"
              isOpen={!!embedModalOpen}
              onClose={() => {
                setEmbedModalOpen(null);
              }}
            >
              <div>
                <pre className="whitespace-pre-wrap break-all bg-black text-white text-xs p-4 rounded">
                  {`<iframe
    id="iframe"
    src="https://chat.fewclicks.ru/?id=${embedModalOpen}"
    width="100%"
    height="600px"
></iframe>`}
                </pre>
              </div>
            </Modal>
          )} */}

          {embedModalOpen && (
            <Modal
              title="Embed"
              isOpen={!!embedModalOpen}
              onClose={() => {
                setEmbedModalOpen(null);
              }}
            >
              <CodeBlock
                text={`<iframe
  id="iframe"
  src="https://chat.dashboard.shmoneloops.com/?id=${embedModalOpen}"
  width="100%"
  height="600px"
></iframe>`}
                language="html"
              />
            </Modal>
          )}

        {errorMessage && (
          <div className="pb-2">
            <ErrorMessage message={errorMessage} />
          </div>
        )}

        <div className="hidden md:block">
          {agents &&
            filteredAgents &&
            agents?.length > 0 &&
            filteredAgents.length > 0 && (
              <ColumnHeaders columns={headers.length} headers={headers} />
            )}
          {agents && filteredAgents && filteredAgents.length > 0 ? (
            filteredAgents
              .filter((agent) => agent._id)
              .map((agent: IAgentResponse, index: number) => (
                <ComponentRow
                  columns={headers.length}
                  key={agent._id || String(index)}
                  id={agent._id || String(index)}
                  title={agent.name}
                  username={username}
                  description={agent.description || 'no description'}
                  creationDate={formatDate(String(agent.createdAt))}
                  onClick={() => {
                    navigate(`/admin/dashboard/agents/${agent._id}`);
                  }}
                  onDelete={() => {
                    setDeleteModalOpen(agent._id);
                  }}
                  onEmbed={() => {
                    setEmbedModalOpen(agent._id || '');
                  }}
                  additionalFields={[
                    {
                      value: getModelName(
                        agent.languageModelVersion?._id || '',
                      ),
                      key: 'model',
                    },
                  ]}
                />
              ))
          ) : agents && agents?.length > 0 ? (
              <TooltipMessage message={`no agents with '${searchValue}' filter`} />
          ) : (
            <TooltipMessage message="no agents" />
          )}
        </div>

          <div className="md:hidden grid grid-cols-1 gap-4">
            {filteredAgents && filteredAgents.length > 0 ? (
              filteredAgents
                .filter((agent) => agent._id)
                .map((agent: IAgentResponse, index: number) => (
                  <AgentCard
                    key={agent._id || String(index)}
                    id={agent._id || String(index)}
                    title={agent.name}
                    username={username}
                    model={getModelName(agent.languageModelVersion?._id || '')}
                    description={agent.description || 'no description'}
                    creationDate={formatDate(String(agent.createdAt))}
                    onClick={() => {
                      navigate(`/admin/dashboard/agents/${agent._id}`);
                    }}
                    onDelete={() => {
                      setDeleteModalOpen(agent._id);
                    }}
                    onEmbed={() => {
                      setEmbedModalOpen(agent._id || '');
                    }}
                  />
                ))
            ) : agents && agents?.length > 0 ? (
              <TooltipMessage message={`no agents with '${searchValue}' filter`} />
            ) : (
              <TooltipMessage message="no agents" />
            )}
          </div>
      </ContentWrapper>
    </>
  );
};
