import BlockLink from 'features/LinkBlock/LinkBlock';
import { H2, ESize } from 'shared/H2';
import { AdvantagesList } from 'widgets/AdvantagesList';
import { CodeCard } from 'shared/CodeCard';

const sampleCode = `
import requests
import json
url = "https://api-users.shmoneloops.com/api/agents/669f737b15dcf98169e89b97/public-llm"
headers = {"Content-Type": "application/json"}
payload = {
  "chatId": "testId",
  "messages": [
...
  `;

export default function Overview() {
  return (
    <>
      <div className="flex flex-col gap-10 p-4 2xl:max-w-[95%] 3xl:max-w-[80%] m-auto">
        <AdvantagesList />
        <CodeCard
          title='Developer quickstart'
          description='Set up your agentic workflow and launch your first product in minutes.'
          code={sampleCode}
        />
    </div>
    
      <div className="h-full 2xl:max-w-[95%] 3xl:max-w-[80%] m-auto grid grid-cols-1 md:grid-cols-2 gap-4 p-4">
        <div className="flex flex-col space-y-4">
          <div className="h-full">
            <BlockLink href="/admin/chats/">
              <div className="flex flex-col justify-center gap-2 py-20 md:py-0 h-full">
                <H2 size={ESize.MEDIUM}>Chats</H2>
                <p className="text-sm">
                  Go to your <b>Chats {'->'}</b>
                </p>
              </div>
            </BlockLink>
          </div>
          <div className="h-full">
            <BlockLink href="/admin/dashboard/agents/">
              <div className="flex flex-col justify-center gap-2 py-20 md:py-0 h-full">
                <H2 size={ESize.MEDIUM}>Agents</H2>
                <p className="whitespace-nowrap text-sm">
                  Create new <b>Agent {'->'}</b>
                </p>
              </div>
            </BlockLink>
          </div>
          <div className="h-full">
            <BlockLink href="/admin/dashboard/knowledge/">
              <div className="flex flex-col justify-center gap-2 py-20 md:py-0 h-full">
                <H2 size={ESize.MEDIUM}>Knowledge</H2>
                <p className="whitespace-nowrap text-sm">
                  Create new <b>Knowledge {'->'}</b>
                </p>
              </div>
            </BlockLink>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <div className="h-full">
            <BlockLink href="/admin/dashboard/triggers/">
              <div className="flex flex-col justify-center gap-2 py-20 md:py-0 h-full">
                <H2 size={ESize.MEDIUM}>Triggers</H2>
                <p className="text-sm">
                  Create new <b>Trigger {'->'}</b>
                </p>
              </div>
            </BlockLink>
          </div>
          <div className="h-full md-max:!mb-4">
            <BlockLink href="/admin/assistant/">
              <div className="flex flex-col justify-center gap-2 py-20 md:py-0 h-full">
                <H2 size={ESize.MEDIUM}>Assistants</H2>
                <p className="text-sm">
                  Chat with <b>Assistant {'->'}</b>
                </p>
              </div>
            </BlockLink>
          </div>
        </div>
      </div>
    </>
  );
}
