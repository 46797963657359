import { IAgentRequest, ITrigger } from 'app/types';
import { IKnowledge } from 'app/types/knowledge';
import { INote } from 'app/types/note';
import { useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
// import { FaPlusCircle } from 'react-icons/fa';
import { Dropdown } from 'shared/Dropdown';
import { Modal } from 'shared/Modal';
import { Text, EWeightText, ESizeText } from 'shared/Text';
import { DropdownList } from 'widgets/DropdownList';

const ConnectElement = (props: {
  title: string;
  isAdded: boolean;
  handleButtonClick: () => void;
}) => {
  const { title, isAdded, handleButtonClick } = props;

  return (
    <div className="flex items-center justify-between bg-white shadow-md p-4 rounded dark:bg-normalBlue">
      <Text weight={EWeightText.BOLD}>{title}</Text>
      <button
        onClick={handleButtonClick}
        className={`ml-4 px-4 py-2 rounded focus:outline-none ${
          isAdded
            ? 'bg-red-500 hover:bg-red-600'
          : 'bg-normalBlue hover:bg-darkBlue dark:bg-darkBlue dark:hover:bg-black'
        } text-white`}
      >
        {isAdded ? 'Remove' : 'Add'}
      </button>
    </div>
  );
};

const ConnectBlock = (props: {
  entityType: 'trigger' | 'note' | 'knowledge';
  entities: ITrigger[] | INote[];
  agent: IAgentRequest;
  setAgent: React.Dispatch<React.SetStateAction<IAgentRequest>>;
}) => {
  const { agent, setAgent, entities, entityType } = props;

  return (
    entities &&
    entities.map((entity) => {
      let isAdded = false;
      if (entityType === 'trigger') {
        isAdded = agent?.triggers?.includes(entity._id || '') || false;
      } else if (entityType === 'note') {
        isAdded = agent?.knowledgeBase?.includes(entity._id || '') || false;
      } else if (entityType === 'knowledge') {
        isAdded = agent?.knowledgeVector?.includes(entity._id || '') || false;
      }

      return (
        <ConnectElement
          title={entity.name}
          isAdded={isAdded}
          handleButtonClick={() => {
            const updatedAgent = {
              ...agent,
            };

            if (isAdded) {
              if (entityType === 'trigger') {
                updatedAgent.triggers = updatedAgent?.triggers?.filter(
                  (id: string) => id !== entity._id,
                );
              } else if (entityType === 'note') {
                updatedAgent.knowledgeBase =
                  updatedAgent?.knowledgeBase?.filter(
                    (id: string) => id !== entity._id,
                  );
              } else if (entityType === 'knowledge') {
                updatedAgent.knowledgeVector =
                  updatedAgent?.knowledgeVector?.filter(
                    (id: string) => id !== entity._id,
                  );
              }
            } else {
              if (entityType === 'trigger') {
                entity._id && updatedAgent?.triggers?.push(entity._id);
              } else if (entityType === 'note') {
                entity._id && updatedAgent?.knowledgeBase?.push(entity._id);
              } else if (entityType === 'knowledge') {
                entity._id && updatedAgent?.knowledgeVector?.push(entity._id);
              }
            }
            setAgent(updatedAgent);
          }}
        />
      );
    })
  );
};

const ConnectionPreview = (props: {
  id: string;
  name: string;
  entityType: 'trigger' | 'note' | 'knowledge';
  agent: IAgentRequest;
  setAgent: React.Dispatch<React.SetStateAction<IAgentRequest>>;
}) => {
  const { id, name, entityType, agent, setAgent } = props;

  const handleClick = () => {
    const updatedAgent = {
      ...agent,
    };
    if (entityType === 'trigger') {
      updatedAgent.triggers = updatedAgent?.triggers?.filter(
        (triggerId: string) => triggerId !== id,
      );
    } else if (entityType === 'note') {
      updatedAgent.knowledgeBase = updatedAgent?.knowledgeBase?.filter(
        (noteId: string) => noteId !== id,
      );
    } else if (entityType === 'knowledge') {
      updatedAgent.knowledgeVector = updatedAgent?.knowledgeVector?.filter(
        (knowledgeId: string) => knowledgeId !== id,
      );
    }
    setAgent(updatedAgent);
  };
  return (
    <div key={id} className="w-full h-10 flex justify-between pr-4">
      <Text size={ESizeText.MEDIUM} extra="flex justify-center items-center">
        {name}
      </Text>
      <button onClick={handleClick}>
        <FaTrashAlt className="w-4 h-4 text-red-500" />
      </button>
    </div>
  );
};

export const Connections = (props: {
  agent: IAgentRequest;
  setAgent: React.Dispatch<React.SetStateAction<IAgentRequest>>;
  triggers: ITrigger[];
  notes: INote[];
  knowledge: IKnowledge[];
}) => {
  const { agent, setAgent, triggers, notes, knowledge } = props;

  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [isKnowledgeOpen, setIsKnowledgeOpen] = useState(false);
  const [isTriggerOpen, setIsTriggerOpen] = useState(false);

  return (
    <div>
      <DropdownList>
        <Dropdown
          text="Notes"
          onButtonClick={() => {
            setIsNotesOpen(true);
          }}
        >
          {notes.filter((note) => agent.knowledgeBase?.includes(note._id || ''))
            .length > 0 ? (
            notes
              .filter((note) => agent.knowledgeBase?.includes(note._id || ''))
              .map((note) => (
                <ConnectionPreview
                  id={note._id || ''}
                  name={note.name}
                  entityType="note"
                  agent={agent}
                  setAgent={setAgent}
                />
              ))
          ) : (
            <div>Press "+" to add notes</div>
          )}
        </Dropdown>

        <Dropdown
          text="Knowledge"
          onButtonClick={() => {
            setIsKnowledgeOpen(true);
          }}
        >
          {knowledge.filter((knowledge) =>
            agent.knowledgeVector?.includes(knowledge._id || ''),
          ).length > 0 ? (
            knowledge
              .filter((knowledge) =>
                agent.knowledgeVector?.includes(knowledge._id || ''),
              )
              .map((item) => (
                <ConnectionPreview
                  id={item._id || ''}
                  name={item.name}
                  entityType="knowledge"
                  agent={agent}
                  setAgent={setAgent}
                />
              ))
          ) : (
            <div>Press "+" to add knowledge</div>
          )}
        </Dropdown>

        <Dropdown
          text="Trigger"
          onButtonClick={() => {
            setIsTriggerOpen(true);
          }}
        >
          {triggers.filter((trigger) =>
            agent.triggers?.includes(trigger._id || ''),
          ).length > 0 ? (
            triggers
              .filter((trigger) => agent.triggers?.includes(trigger._id || ''))
              .map((trigger) => (
                <ConnectionPreview
                  id={trigger._id || ''}
                  name={trigger.name}
                  entityType="trigger"
                  agent={agent}
                  setAgent={setAgent}
                />
              ))
          ) : (
            <div>Press "+" to add triggers</div>
          )}
        </Dropdown>
      </DropdownList>

      <Modal
        title="Notes"
        isOpen={isNotesOpen}
        onClose={() => {
          setIsNotesOpen(false);
        }}
      >
        <ConnectBlock
          entityType="note"
          entities={notes}
          agent={agent}
          setAgent={setAgent}
        />
      </Modal>

      <Modal
        title="Knowledge"
        isOpen={isKnowledgeOpen}
        onClose={() => {
          setIsKnowledgeOpen(false);
        }}
      >
        <ConnectBlock
          entityType="knowledge"
          entities={knowledge}
          agent={agent}
          setAgent={setAgent}
        />
      </Modal>

      <Modal
        title="Trigger"
        isOpen={isTriggerOpen}
        onClose={() => {
          setIsTriggerOpen(false);
        }}
      >
        <ConnectBlock
          entityType="trigger"
          entities={triggers}
          agent={agent}
          setAgent={setAgent}
        />
      </Modal>
    </div>
  );
};
