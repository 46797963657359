export function checkUsername(username: string): { status: "success" | "error", message: string } {
    const usernameRegex = /^[a-zA-Z][a-zA-Z0-9]*$/;
    return {
        status: usernameRegex.test(username) ? "success" : "error",
        message: usernameRegex.test(username) ? "" : "Incorrect username"
    };
}

export function checkPassword(password: string): { status: "success" | "warning" | "error", message: string } {
    if (password.length < 5) return { status: "error", message: "too short password" };
    if (password.length < 8 || !/\d/.test(password) || !/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        return { status: "warning", message: "Too simple password, add symbols and numbers" };
    }
    return { status: "success", message: "" };
}

export function checkEmail(email: string): { status: "success" | "error", message: string } {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return {
        status: emailRegex.test(email) ? "success" : "error",
        message: emailRegex.test(email) ? "" : "Incorrect email"
    };
}

export function checkLogin(login: string): { status: "success" | "error", message: string } {
    const usernameRegex = /^[a-zA-Z0-9]*$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(login)) {
        return { status: "success", message: "" };
    } else if (usernameRegex.test(login)) {
        return { status: "success", message: "" };
    } else {
        return { status: "error", message: "Incorrect username or email" };
    }
}
