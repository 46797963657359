import { IKnowledge } from 'app/types/knowledge';
import { fetchData } from 'app/utils/fetch/request';
import { ComponentRow } from 'shared/ComponentRow';
import { ColumnHeaders } from 'shared/ColumnHeaders';
import { CardBlock } from 'features/CardBlock';
import Cookies from 'js-cookie';
import { useState, useMemo, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'shared/Modal';
import { H2 } from 'shared/H2';
import { truncateText } from 'app/utils/truncateText/truncateText';
import { getAllKnowledge } from '../api/knowledge';
import { Message } from 'app/types/messages';
import { ErrorMessage } from 'features/ErrorMessage';
import { TooltipMessage } from 'shared/TooltipMessage';
import { SearchContext } from 'app/context/SearchContext'; // Импорт контекста поиска

interface IKnowledgeProps {
  knowledge: IKnowledge[];
  setKnowledge: React.Dispatch<React.SetStateAction<IKnowledge[]>>;
  setStatus: (value: React.SetStateAction<Message | undefined>) => void;
  error: string;
}

export const Knowledge: React.FC<IKnowledgeProps> = ({
  knowledge,
  setKnowledge,
  setStatus,
  error,
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState<string>('');
  const { searchValue } = useContext(SearchContext);

  const navigate = useNavigate();
  const [username] = useState<string>(String(Cookies.get('username')));

  const fetchKnowledge = async () => {
    const token = Cookies.get('accessToken');
    const profileId = Cookies.get('profileId');

    try {
      const knowledge: IKnowledge[] = await fetchData(
        `${import.meta.env.VITE_APP_USER_API}/profiles/${profileId}/knowledge-vector`,
        'GET',
        token,
      );
      setKnowledge(knowledge);
      console.log('KNOW:' + JSON.stringify(knowledge));
    } catch (error) {
      console.error('Error on getting knowledge:', error);
    }
  };

  useEffect(() => {
    setTimeout(async () => {
      await fetchKnowledge();
    });
  }, []);

  const deleteHandler = async () => {
    const token = Cookies.get('accessToken');
    try {
      await fetchData(
        `${import.meta.env.VITE_APP_USER_API}/knowledge-vector/${deleteModalOpen}`,
        'DELETE',
        token,
      );
      setDeleteModalOpen('');
      setStatus({ text: 'Successfully deleted', type: 'success' });
    } catch (error) {
      setStatus({ text: 'Error when deleting', type: 'error' });
    }
    const knowledge = await getAllKnowledge();
    setKnowledge(knowledge);
  };

  const filteredKnowledge = useMemo(() => {
    return knowledge
      ? knowledge.filter((knowledgeItem) =>
          knowledgeItem.name.toLowerCase().includes(searchValue.toLowerCase()),
        )
      : [];
  }, [searchValue, knowledge]);

  const headers = ['Knowledge Name', 'Description', 'Created At', 'Username', ''];

  return (
    <>
      <Modal
        title="Delete"
        isOpen={!!deleteModalOpen}
        submitHandler={deleteHandler}
        onClose={() => {
          setDeleteModalOpen('');
        }}
        isDanger
      >
        <div>Are you sure?</div>
      </Modal>

      {error && (
        <div className="pb-2">
          <ErrorMessage message={error} />
        </div>
      )}

      <div className="hidden md:block">
        {knowledge &&
          filteredKnowledge &&
          knowledge.length > 0 &&
          filteredKnowledge.length > 0 && (
          <ColumnHeaders columns={headers.length} headers={headers} />
          )}
        {filteredKnowledge && filteredKnowledge.length > 0 ? (
          filteredKnowledge.map((knowledgeItem: IKnowledge) => (
            <ComponentRow
              key={knowledgeItem._id as string}
              id={knowledgeItem._id as string}
              title={knowledgeItem.name}
              username={username}
              creationDate={
                knowledgeItem.createdAt
                  ? new Date(knowledgeItem.createdAt)
                      .toISOString()
                      .split('T')[0]
                  : '-'
              }
              description="no description"
              columns={headers.length}
              onClick={() => {
                navigate(`/admin/dashboard/knowledge/${knowledgeItem._id}`);
              }}
              onDelete={() => {
                setDeleteModalOpen(knowledgeItem._id as string);
              }}
            />
          ))
        ) : knowledge.length > 0 ? (
            <TooltipMessage message={`no knowledge with '${searchValue}' filter`} />
        ) : (
          <TooltipMessage message="no knowledge" />
        )}
      </div>

      <div className="md:hidden grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        {filteredKnowledge && filteredKnowledge.length > 0 ? (
          filteredKnowledge.map((knowledgeItem: IKnowledge) => (
            <CardBlock
              key={knowledgeItem._id as string}
              id={knowledgeItem._id as string}
              handleClick={() => {
                navigate(`/admin/dashboard/knowledge/${knowledgeItem._id}`);
              }}
              handleDelete={() => {
                setDeleteModalOpen(knowledgeItem._id as string);
              }}
            >
              <H2>{truncateText(knowledgeItem.name, 15)}</H2>
            </CardBlock>
          ))
        ) : knowledge.length > 0 ? (
            <TooltipMessage message={`no knowledge with '${searchValue}' filter`} />
        ) : (
          <TooltipMessage message="no knowledge" />
        )}
      </div>
    </>
  );
};
