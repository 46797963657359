import { IAgentResponse, IChat, IChatResponse } from 'app/types';
import { fetchData } from 'app/utils/fetch/request';
import Cookies from 'js-cookie';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Modal } from 'shared/Modal';
import { H2 } from 'shared/H2';
import { ChatCard } from './components/ChatCard';
import { ChatWindow } from './components/ChatWindow';
import { EWeightText, Text } from 'shared/Text';
import Loader from 'entities/Loader/Loader';
import { TimeoutMessage } from 'widgets/TimeoutMessage';
import { Message } from 'app/types/messages';
import { ErrorMessage } from 'features/ErrorMessage';
import { SearchContext } from 'app/context/SearchContext';
import { ContentWrapper } from 'widgets/ContentWrapper';
import { Select } from 'shared/Select';
import { Input } from 'shared/Input';

export const Chats = () => {
  const [chatLoading, setChatLoading] = useState(false);
  const [chats, setChats] = useState<IChatResponse[]>();
  const [agents, setAgents] = useState<IAgentResponse[]>();
  const [deleteModalOpen, setDeleteModalOpen] = useState<string>();
  const [form, setForm] = useState<IChat>({ agent: '', name: '' });
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [chatId, setChatId] = useState<string>('');
  const [isChatMobileView, setChatMobileView] = useState<boolean>(false);
  const [statusMessage, setStatusMessage] = useState<Message>();
  const [error, setError] = useState<{
    text: string;
    type: 'chats' | 'messages';
  }>();
  const { searchValue } = useContext(SearchContext);

  const getAllChats = async () => {
    const token = Cookies.get('accessToken');
    const profileId = Cookies.get('profileId');

    try {
      const agents = await fetchData<IAgentResponse[]>(
        `${import.meta.env.VITE_APP_USER_API}/profiles/${profileId}/agents`,
        'GET',
        token,
      );
      setAgents(agents);
      setChatLoading(true);
      const chats: IChatResponse[] = await fetchData<IChatResponse[]>(
        `${import.meta.env.VITE_APP_USER_API}/profiles/${profileId}/chats`,
        'GET',
        token,
      );
      setChatLoading(false);
      setChats(chats);
    } catch (error) {
      setChatLoading(false);
      setError({ text: 'Error on getting chats', type: 'chats' });
      console.error('Error on getting chats:', error);
    }
  };

  useEffect(() => {
    getAllChats();
  }, []);

  const deleteHandler = () => {
    const token = Cookies.get('accessToken');
    setTimeout(async () => {
      try {
        await fetchData(
          `${import.meta.env.VITE_APP_USER_API}/chats/${deleteModalOpen}`,
          'DELETE',
          token,
        );
        setDeleteModalOpen('');
        setChatId('');
        setForm({ name: '', agent: '' });
        setStatusMessage({ text: 'Successfully deleted', type: 'success' });
      } catch (error) {
        setStatusMessage({ text: 'Error when deleting', type: 'error' });
      }
      await getAllChats();
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleSubmit = async () => {
    const token = Cookies.get('accessToken');

    try {
      await fetchData(
        `${import.meta.env.VITE_APP_USER_API}/chats/${form.agent}`,
        'POST',
        token,
        { name: form.name },
      );
      setIsAddModalOpen(false);
      getAllChats();
      setForm({ name: '', agent: '' });
      setStatusMessage({ text: 'Chat was created', type: 'success' });
    } catch (error) {
      setStatusMessage({ text: 'Error on creating chat', type: 'error' });
    }
  };

  const filteredChats = useMemo(() => {
    return chats
      ? chats.filter((note) =>
          note.name.toLowerCase().includes(searchValue.toLowerCase()),
        )
      : [];
  }, [searchValue, chats]);

  return (
    <>
      {statusMessage && <TimeoutMessage messages={statusMessage} />}
      <Modal
        title="Add Chat"
        submitHandler={handleSubmit}
        isOpen={isAddModalOpen}
        onClose={() => {
          setIsAddModalOpen(false);
          setForm({ name: '', agent: '' });
        }}
        isSubmitDisabled={form.name.trim() === '' || form.agent.trim() === ''}
      >
        <div className="bg-white p-4 text-black">
          <div className="mb-4">
            {/* <label htmlFor="name" className="mb-2 block">
              Name
            </label> */}
            <Input
              label="Name"
              type="text"
              id="name"
              name="name"
              value={form.name}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            {/* <label htmlFor="agent" className="mb-2 block">
              Agent
            </label> */}
            <Select
              label="Agent"
              value={{
                label:
                  agents?.find(({ _id }) => _id === form.agent)?.name || '',
                value: agents?.find(({ _id }) => _id === form.agent)?._id || '',
              }}
              options={
                agents
                  ? agents.map((agent) => ({
                      label: agent.name || '',
                      value: agent._id || '',
                    }))
                  : []
              }
              onChange={(option) => {
                const selectedOption = option as {
                  label: string;
                  value: string;
                };
                setForm((prevForm) => ({
                  ...prevForm,
                  agent: selectedOption.value,
                }));
              }}
            />
          </div>
        </div>
      </Modal>
      <Modal
        title="Delete"
        isOpen={!!deleteModalOpen}
        submitHandler={deleteHandler}
        onClose={() => {
          setDeleteModalOpen('');
        }}
        isDanger
      >
        <div>Are you sure?</div>
      </Modal>
      <ContentWrapper
        title={isChatMobileView && chatId ? '' : 'Chats'}
        description={
          isChatMobileView && chatId ? '' : 'Communicate with your agents'
        }
        sumbitLabel={isChatMobileView && chatId ? '' : 'New'}
        sumbitHandler={() => {
          setIsAddModalOpen(true);
        }}
      >
        <div className="w-full h-full px-2 pb-1 mt-[-7px]">
          <div className="overflow-hidden flex w-full h-full rounded-lg border border-lightGray bg-white dark:bg-darkBlue dark:border-lightBlue/60">
            <div
              className={`${isChatMobileView && chatId ? 'hidden' : 'block'} w-full h-full md:block md:w-1/2 overflow-y-auto`}
            >
              {chatLoading ? (
                <Loader />
              ) : error?.type === 'chats' && error.text ? (
                <div className="pb-2">
                  <ErrorMessage message={error.text} />
                </div>
              ) : filteredChats && filteredChats.length > 0 ? (
                filteredChats.map((chat: IChatResponse, index: number) => (
                  <ChatCard
                    id={chat._id || String(index)}
                    handleClick={() => {
                      setChatMobileView(true);
                      setChatId(chat._id);
                    }}
                    handleDelete={() => {
                      setDeleteModalOpen(chat._id);
                    }}
                    key={index}
                    isSelected={chat._id === chatId}
                  >
                    <H2>{chat?.name}</H2>
                  </ChatCard>
                ))
              ) : (
                <div className="w-full h-full flex justify-center items-center">
                  <Text weight={EWeightText.BOLD}>No chats</Text>
                </div>
              )}
            </div>
            <div className="md:block hidden h-full w-[1px] bg-lightGray dark:bg-lightBlue/60"></div>
            <div
              className={`${isChatMobileView && chatId ? 'block' : 'hidden'} md:block flex flex-col h-full md:w-1/2 w-full`}
            >
              {chatId ? (
                <ChatWindow
                  id={chatId}
                  setChatId={setChatId}
                  setChatMobileView={setChatMobileView}
                />
              ) : (
                <div className="w-full h-full flex justify-center items-center">
                  <Text weight={EWeightText.BOLD}>Select chat</Text>
                </div>
              )}
            </div>
          </div>
        </div>
      </ContentWrapper>
    </>
  );
};
