import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Select, { MultiValue, SingleValue } from 'react-select';

export interface ISelectOption {
  value: string;
  label: string;
}

interface ISelectProps {
  id?: string;
  label?: string;
  value?: SingleValue<ISelectOption> | MultiValue<ISelectOption>;
  onChange?: (
    option: SingleValue<ISelectOption> | MultiValue<ISelectOption> | null,
  ) => void;
  name?: string;
  disabled?: boolean;
  options: ISelectOption[];
  isMulti?: boolean;
  extra?: string;
}

export default function ListSelect(props: ISelectProps) {
  const selectRef = useRef<any>(null);

  useEffect(() => {
    const node = ReactDOM.findDOMNode(selectRef.current);
    if (node instanceof HTMLElement) {
      const input = node.querySelector('input');
      if (input) {
        input.setAttribute('readonly', 'readonly');
        input.classList.add('no-keyboard');
      }
    }
  }, []);
  return (
    <div key={props.id}>
      {props.label && (
        <label
          htmlFor={props.id}
          className="text-sm text-lightBlue dark:text-white font-medium"
        >
          {props.label}
        </label>
      )}

      <Select
        ref={selectRef}
        id={props.id}
        isDisabled={props.disabled}
        name={props.name}
        value={props.value}
        onChange={props.onChange as any}
        options={props.options}
        classNames={{
          control: (state) =>
            `z-30 !rounded-xl !outline-none !ring-0 !text-sm !border-lightBlue/50 hover:!border-lightBlue/100 dark:!border-lightBlue dark:text-white dark:bg-darkBlue ${props.extra ? props.extra : ''} ` +
            (state.isFocused || state.menuIsOpen
              ? '!border-lightBlue/100 !bg-lightBlue/5'
              : ''),
          menu: () => 'z-50 !overflow-hidden dark:!bg-darkBlue',
          option: (state) =>
            '!text-sm !cursor-pointer ' +
            (state.isSelected
              ? '!bg-lightBlue !text-white dark:!bg-lightBlue/50 dark:!text-white'
              : state.isFocused
                ? '!bg-lightBlue/20 !text-darkBlue dark:!text-white'
                : '!bg-white !text-darkBlue dark:!bg-darkBlue dark:!text-white'),
          singleValue: () => '!text-black dark:!text-white',
          dropdownIndicator: (state) =>
            'dark:!text-lightGray hover:dark:!text-white cursor-pointer ' +
            (state.isFocused ? 'dark:!text-white' : ''),
          multiValue: () => '!bg-[#6B80AB] !rounded-md flex gap-1',
          multiValueLabel: () => '!text-white',
          clearIndicator: () =>
            'dark:!text-lightGray hover:dark:!text-white cursor-pointer ',
        }}
        isMulti={props.isMulti}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          placeholder: (base) => ({
            ...base,
            color: 'white',
          }),
          multiValueRemove: (base) => ({
            ...base,
            color: 'white',
            ':hover': {
              color: 'white',
            },
          }),
        }}
      />
    </div>
  );
}
