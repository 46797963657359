import { FaTrashAlt } from 'react-icons/fa';
import { ClipboardButton } from 'shared/ClipboardButton';
import { Text } from 'shared/Text';

export const PromptItemWrapper = (props: {
  type: 'System' | 'Human' | 'AI';
  children: React.ReactNode;
  deleteHandler?: () => void;
  textToCopy?: string;
}) => {
  const { type, children, deleteHandler, textToCopy } = props;
  return (
    <div className="bg-white rounded-md border-[1px] border-lightGray p-2 mb-2 dark:bg-darkBlue dark:border-lightBlue/50">
      <div className="flex justify-between pb-2">
        <Text>{type}</Text>
        {type !== 'System' ? (
          <div
            className="w-6 h-6 flex cursor-pointer justify-center items-center rounded-full hover:bg-lightGray"
            onClick={deleteHandler}
          >
            <FaTrashAlt className="w-3 h-3" />
          </div>
        ) : (
          <ClipboardButton textToCopy={textToCopy || ''} />
        )}
      </div>
      {children}
    </div>
  );
};
