import { fetchData } from 'app/utils/fetch/request';
import banner from '../assets/banner.png';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { Card } from 'shared/Card';
import { Text } from 'shared/Text';
import { ErrorMessage } from 'features/ErrorMessage';
import { SubmitButton } from 'shared/SubmitButton';
import { MdVerified } from "react-icons/md";

const Banner = () => {
  const [username] = useState<string>(String(Cookies.get('username')));
  const [email] = useState<string>(String(Cookies.get('email')));
  const [isVerified] = useState<string>(String(Cookies.get('isVerified')));

  const [picture, setPicture] = useState<string>(
    'https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg',
  );

  const [agentCount, setAgentCount] = useState<string | number>('--');
  const [chatCount, setChatCount] = useState<string | number>('--');
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    setTimeout(async () => {
      const token = Cookies.get('accessToken');
      const profileId = Cookies.get('profileId');

      try {
        const data = await fetchData<{ name: string; picture: string }>(
          `${import.meta.env.VITE_APP_USER_API}/profiles/${profileId}/credentials`,
          'GET',
          token,
        );
        data?.picture && setPicture(data.picture);
      } catch (err) {
        setErrorMessage('Error on getting profile data');
        console.error(err);
      }
      try {
        const data = await fetchData<{ agents: number; chats: number }>(
          `${import.meta.env.VITE_APP_USER_API}/profiles/${profileId}/analytics`,
          'GET',
          token,
        );
        setAgentCount(data.agents);
        setChatCount(data.chats !== undefined ? data.chats : '-');
      } catch (err) {
        console.error(err);
        setErrorMessage('Error on getting profile data');
        setAgentCount('-');
        setChatCount('-');
      }
    });
  }, []);

  return (
    <>
      {errorMessage && (
        <div className="pb-2">
          <ErrorMessage message={errorMessage} />
        </div>
      )}
      <Card>
        {/* Background and profile */}
        <div
          className="relative flex h-32 w-full justify-center rounded-xl bg-cover"
          style={{ backgroundImage: `url(${banner})` }}
        >
          <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-darkBlue">
            <img className="h-full w-full rounded-full" src={picture} alt="" />
          </div>
        </div>

        {/* Name and email */}
        <div className="mt-16 flex flex-col items-center">
          <h4 className="relative text-xl font-bold text-darkBlue dark:text-white">
            {username}
          </h4>
          <Text extra="relative text-lightBlue dark:text-lightGray">
            {email}
            {isVerified === 'true' && (
              <MdVerified className="absolute top-1 -right-5" />
            )}
          </Text>
          {isVerified === 'false' && (
            <SubmitButton
              label="Confirm Email"
              extra="py-1 px-2 mt-2 !w-[130px] text-sm !bg-red-400 hover:!bg-normalBlue dark:hover:bg-darkBlue dark:hover:!bg-black/30"
            />
          )}
        </div>

        {/* Profile info */}
        <div className="mb-3 mt-6 flex justify-center gap-4 md:!gap-14">
          <div className="flex flex-col items-center justify-center">
            <p className="text-2xl font-bold text-darkBlue dark:text-white">
              {agentCount}
            </p>
            <p className="text-sm font-normal text-lightBlue dark:text-lightGray">
              Agents
            </p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <p className="text-2xl font-bold text-darkBlue dark:text-white">
              {chatCount}
            </p>
            <p className="text-sm font-normal text-lightBlue dark:text-lightGray">
              Chats
            </p>
          </div>
        </div>
        <div className="mb-3 mt-6 mx-auto">
          <SubmitButton label="Add Google Account" extra="py-2 px-3 dark:hover:!bg-black/30" />
        </div>
      </Card>
    </>
  );
};

export default Banner;
