import React, { useEffect, useRef } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import routes from './routes';
import { Navbar } from 'widgets/Navbar';
import { Sidebar } from './Sidebar';

export default function Admin(props: { [x: string]: any }) {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(window.innerWidth >= 1200);
  const [currentRoute, setCurrentRoute] = React.useState('Main Dashboard');

  const contentRef = useRef<HTMLDivElement>(null);

  const getActiveRoute = (routes: RoutesType[]): void => {
    for (let i = 0; i < routes.length; i++) {
      const regex = new RegExp(`${routes[i].layout}.*${routes[i].path}`);
      if (regex.test(location.pathname)) {
        setCurrentRoute(routes[i].name);
      }
    }
  };
  const getActiveNavbar = (routes: RoutesType[]): string | boolean => {
    const activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      const regex = new RegExp(`${routes[i].layout}.*${routes[i].path}`);
      if (regex.test(location.pathname)) {
        return routes[i].secondary || false;
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      return (
        <Route path={`/${prop.path}`} element={prop.component} key={key} />
      );
    });
  };

  useEffect(() => {
    window.addEventListener('resize', () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true),
    );
  }, []);

  useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
  }, [location.pathname]);

  document.documentElement.dir = 'ltr';

  return (
    <div className="fixed flex w-full h-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className="w-full h-full bg-white dark:!bg-navy-900 transition-all duration-500">
        {/* Main Content */}
        <main className={`h-full transition-all xl:ml-60`}>
          {/* Routes */}
          <div className="flex flex-col h-full">
            <div className="p-2">
              <Navbar
                onOpenSidenav={() => setOpen(true)}
                brandText={currentRoute}
                secondary={getActiveNavbar(routes)}
                {...rest}
              />
            </div>
            <div
              ref={contentRef}
              className="overflow-y-auto w-full h-full px-2 dark:text-gray-200 md:pr-2"
              style={{
                overscrollBehavior: 'contain',
                paddingBottom: '5px',
              }}
            >
              <Routes>
                {getRoutes(routes)}
                <Route
                  path="/"
                  element={<Navigate to="/admin/default" replace />}
                />
              </Routes>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
