import { useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { H1 } from 'shared/H1';
import { Modal } from 'shared/Modal';
import { SubmitButton } from 'shared/SubmitButton';
import { ESizeText, Text } from 'shared/Text';

interface IContentWrapper {
  title: string;
  description: string;
  sumbitLabel?: string;
  sumbitHandler?: () => void;
  isSubmitActive?: boolean;
  submitLoading?: boolean;
  onBackClick?: () => void;
  children: React.ReactNode;
  modalTitle?: string;
  modalButton?: React.ReactNode;
  modalContent?: React.ReactNode;
  modalSubmitHandler?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  modalCloseHandler?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  showSubmitButton?: boolean;
}

export const ContentWrapper: React.FC<IContentWrapper> = ({
  title,
  description,
  sumbitLabel,
  sumbitHandler,
  onBackClick,
  isSubmitActive = true,
  submitLoading = false,
  children,
  modalTitle = 'Modal',
  modalButton,
  modalContent,
  modalSubmitHandler,
  modalCloseHandler,
  showSubmitButton = true,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div className="relative flex flex-col w-full h-full">
        <header className="p-2 sticky top-0 z-30 flex justify-between bg-white dark:!bg-navy-900">
          <div className="flex gap-2">
            {onBackClick && (
              <div
                onClick={onBackClick}
                className="flex items-center pl-1 pr-1.5 hover:bg-lightBlue/20 rounded-full transition duration-200 cursor-pointer"
              >
                <IoIosArrowBack className="h-5 w-5" />
              </div>
            )}
            <div className="flex flex-col">
              <H1 extra="sm-max:text-lg">{title}</H1>
              <Text size={ESizeText.SMALL}>{description}</Text>
            </div>
            {modalButton && (
              <button
                onClick={() => {
                  setIsModalOpen(true);
                }}
                className="text-sm"
              >
                {modalButton}
              </button>
            )}
          </div>
          {showSubmitButton && sumbitLabel && sumbitHandler && (
            <div className="w-32 h-full flex items-center">
              <SubmitButton
                label={sumbitLabel}
                isLoading={submitLoading}
                onClick={sumbitHandler}
                isActive={isSubmitActive && !submitLoading}
                extra="w-32 text-sm"
              />
            </div>
          )}
        </header>
        <div
          className="flex-1 flex flex-col overflow-auto scrollbar-none py-2"
          style={{
            overscrollBehavior: 'contain',
            paddingBottom: '10px',
          }}
        >
          {children}
        </div>
      </div>
      {modalContent && isModalOpen && (
        <Modal
          title={modalTitle}
          submitHandler={() => {
            modalSubmitHandler && modalSubmitHandler();
            setIsModalOpen(false);
          }}
          isOpen={isModalOpen}
          onClose={() => {
            modalCloseHandler && modalCloseHandler();
            setIsModalOpen(false);
          }}
        >
          {modalContent}
        </Modal>
      )}
    </>
  );
};
