import React, { useState } from 'react';
import { FaEllipsisH } from 'react-icons/fa';

interface ComponentRowProps {
  id: string;
  title: string;
  description: string;
  username: string;
  creationDate: string;
  columns: number;
  onClick: () => void;
  onDelete?: () => void;
  onEmbed?: () => void;
  menuChildren?: React.ReactNode;
  additionalFields?: { value: string; key: string }[];
  isMenuDisabled?: boolean;
}

const ComponentRow: React.FC<ComponentRowProps> = ({
  id,
  title,
  description,
  creationDate,
  username,
  additionalFields = [],
  onClick,
  onDelete,
  onEmbed,
  menuChildren,
  columns,
}) => {
  const [showMenu, setShowMenu] = useState<string | null>(null);

  const fields = [
    { value: title, key: 'title' },
    { value: description, key: 'description' },
    ...additionalFields,
    { value: creationDate, key: 'creationDate' },
    { value: username, key: 'username' },
  ];

  return (
    <div
      className={`grid grid-cols-${columns} gap-4 pl-2 pr-6 py-4 text-sm border-b border-lightGray cursor-pointer transition duration-200 lg-max:text-xs hover:bg-lightGray/20 dark:hover:bg-normalGray dark:hover:bg-opacity-20`}
      onClick={onClick}
    >
      {fields.map((field, index) => (
        <div
          key={index}
          className="w-full whitespace-nowrap overflow-hidden overflow-ellipsis"
        >
          <p>{field.value}</p>
        </div>
      ))}
      <div className="relative w-full flex justify-end">
        {onDelete && (
          <button
            className="text-normalGray hover:text-normalBlue"
            onClick={(e) => {
              e.stopPropagation();
              setShowMenu(showMenu === id ? null : id);
            }}
          >
            <FaEllipsisH />
          </button>
        )}
        {showMenu === id && (
          <div
            className="absolute right-0 top-3 z-10 mt-2 w-48 rounded-md bg-white shadow-lg"
            onMouseLeave={() => setShowMenu(null)}
          >
            {menuChildren}
            {onEmbed && (
              <button
                className="block w-full px-4 py-2 text-left text-darkBlue rounded-md hover:bg-lightBlue/20"
                onClick={(e) => {
                  e.stopPropagation();
                  onEmbed();
                }}
              >
                Embed
              </button>
            )}
            <button
              className="block w-full px-4 py-2 text-left rounded-md text-red-500 hover:bg-red-100"
              onClick={(e) => {
                e.stopPropagation();
                onDelete && onDelete();
              }}
            >
              Delete
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ComponentRow;
