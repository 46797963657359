import { Chat } from 'widgets/Chat';
import { H1, ESizeHeader } from 'shared/H1';

const Assistant = () => {
  return (
    <div className="flex flex-col h-full mx-auto w-full">
      <H1 size={ESizeHeader.LARGE} extra="mb-6 text-center">
        ShmoneLoops Chat Assistant
      </H1>
      <Chat extra="h-full rounded-md md:w-[40rem] md-max:w-[100%]" showTooltip={false} />
    </div>
  );
};

export default Assistant;
