import React, { useRef, useEffect } from 'react';

interface AdvancedTextareaProps {
  prompt: {
    content: string;
  };
  values: { [key: string]: string };
  handleTextareaChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const AdvancedTextarea: React.FC<AdvancedTextareaProps> = ({
  prompt,
  values,
  handleTextareaChange,
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const syncScroll = (source: HTMLElement, target: HTMLElement) => {
    target.scrollTop = source.scrollTop;
  };

  useEffect(() => {
    const divElement = divRef.current;
    const textareaElement = textareaRef.current;

    if (divElement && textareaElement) {
      const handleDivScroll = () => syncScroll(divElement, textareaElement);
      const handleTextareaScroll = () =>
        syncScroll(textareaElement, divElement);

      divElement.addEventListener('scroll', handleDivScroll);
      textareaElement.addEventListener('scroll', handleTextareaScroll);

      return () => {
        divElement.removeEventListener('scroll', handleDivScroll);
        textareaElement.removeEventListener('scroll', handleTextareaScroll);
      };
    }
  }, []);

  const renderHighlightedText = (text: string): string => {
    const regex = /(\{\{[^}]*\}\})/g;
    const parts = text.split(regex);

    return parts
      .map((part) => {
        if (regex.test(part)) {
          const cleanText = part
            .replace(/[^a-zA-Z_{}]/g, '')
            .replace(/[0-9]/g, '')
            .replace(/_+/g, '_')
            .toLowerCase();

          return values[cleanText.replace(/[{}]/g, '')]
            ? `<span class="text-blue-500">${cleanText}</span>`
            : `<span class="text-yellow-500">${cleanText}</span>`;
        }
        return part;
      })
      .join('');
  };

  return (
    <div className="relative w-full h-32">
      <div
        ref={divRef}
        className="z-0 absolute h-32 overflow-y-auto inset-0 p-2 whitespace-pre-wrap break-words pointer-events-none bg-white dark:bg-darkBlue"
        dangerouslySetInnerHTML={{
          __html: renderHighlightedText(prompt.content),
        }}
      ></div>
      <textarea
        ref={textareaRef}
        className="absolute z-0 overflow-y-auto inset-0 w-full h-full p-2 bg-transparent rounded-lg border border-lightGray/50 resize-none outline-none dark:border-lightBlue/30"
        style={{
          background: 'transparent',
          color: 'transparent',
          caretColor: 'black',
        }}
        value={prompt.content}
        onChange={handleTextareaChange}
      />
    </div>
  );
};
