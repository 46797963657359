import { IChatResponse } from 'app/types';
import { fetchData } from 'app/utils/fetch/request';
import Loader from 'entities/Loader/Loader';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { IoIosArrowBack, IoIosSettings, IoMdClose } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { H1 } from 'shared/H1';
import { Chat } from 'widgets/Chat';

export const ChatWindow = (props: {
  id: string;
  setChatMobileView: React.Dispatch<React.SetStateAction<boolean>>;
  setChatId: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { id, setChatMobileView, setChatId } = props;

  const [loading, setLoading] = useState(false);
  const [chat, setChat] = useState<IChatResponse>();
  const navigate = useNavigate();

  const handleBackClick = () => {
    setChatMobileView(false);
  };

  const handleSettingsClick = () => {
    navigate(`/admin/dashboard/agents/${chat?.agent}`);
  };
  const getChat = async () => {
    const token = Cookies.get('accessToken');

    try {
      setLoading(true);
      const chat = await fetchData<IChatResponse>(
        `${import.meta.env.VITE_APP_USER_API}/chats/${id}`,
        'GET',
        token,
      );
      setLoading(false);
      setChat(chat);
    } catch (error) {
      setLoading(false);
      console.error('Error on getting chats:', error);
    }
  };

  useEffect(() => {
    getChat();
  }, [id]);

  return (
    <div className="w-full h-full">
      {loading ? (
        <Loader />
      ) : (
        <div className="h-full flex flex-col max-w-4xl mx-auto">
          <div className="flex h-16 py-4 px-2 w-full items-center justify-between space-x-4">
            <div>
              <button
                onClick={handleBackClick}
                className="flex md:hidden items-center space-x-2 py-2 pl-1 pr-1.5 hover:bg-lightBlue hover:bg-opacity-20 transition duration-200"
              >
                <IoIosArrowBack className="h-5 w-6" />
              </button>
              <button
                onClick={() => {
                  setChatId('');
                }}
                  className="hidden md:flex items-center space-x-2 py-2 pl-[5px] pr-1.5 rounded-lg hover:bg-lightBlue/20 transition duration-200"
              >
                <IoMdClose className="h-5 w-6" />
              </button>
            </div>

            <div className="flex items-center justify-center">
              <H1>Chat with {chat?.name}</H1>
            </div>
            <button
              onClick={handleSettingsClick}
              className="flex items-center space-x-2 py-2 px-2 rounded-lg hover:bg-lightBlue/20 transition duration-200"
            >
              <IoIosSettings className="h-5 w-5" />
            </button>
          </div>

          <div className="flex-grow overflow-y-hidden">
            <Chat chatId={id} extra="h-full" />
          </div>
        </div>
      )}
    </div>
  );
};
