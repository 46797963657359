import { useState, useRef, useEffect } from 'react';

const Popover = (props: {
  trigger: JSX.Element;
  extra?: string;
  children: JSX.Element;
}) => {
  const { extra, trigger, children } = props;
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);

  const handleDocumentClick = (event: MouseEvent) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentClick);

    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, []);

  const handleTriggerClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="relative inline-block z-30" ref={popoverRef}>
      <div onClick={handleTriggerClick}>{trigger}</div>
      {isOpen && (
        <div
          
          className={`absolute transform -translate-x-[95%] md:translate-x-0  mt-2 w-max rounded-xl bg-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:bg-navy-700 dark:shadow-none ${extra}`}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default Popover;
