export default function FooterAuth() {
  return (
    <div className="z-[5] mx-auto flex w-full max-w-screen-sm flex-col items-center justify-between px-[20px] pb-4 lg:mb-6 lg:max-w-[100%] lg:flex-row xl:mb-2 xl:max-w-[87%] xl:pl-0 xl:ml-10 xl:pb-6 2xl:max-w-[88%] 3xl:max-w-[95%] 4xl:max-w-[100%]">
      <p className="text-center text-sm text-lightBlue md:text-base lg:mb-0">
        ©{new Date().getFullYear()} ShmoneLoops. All Rights Reserved.
      </p>
      <ul className="flex items-center justify-between gap-14 3xl-max:gap-10 sm-max:!gap-6">
        <li className="">
          <a
            target="blank"
            href="mailto:hello@blank.com"
            className="text-sm text-lightBlue hover:text-normalBlue md:text-base lg:text-white lg:hover:text-white"
          >
            Support
          </a>
        </li>
        <li className="">
          <a
            target="blank"
            href="https://shmoneloops.com/terms"
            className="text-sm text-lightBlue hover:text-normalBlue md:text-base lg:text-white lg:hover:text-white"
          >
            Terms of Use
          </a>
        </li>
        <li className="">
          <a
            target="blank"
            href="https://shmoneloops.com/privacy"
            className="text-sm text-lightBlue hover:text-normalBlue md:text-base lg:text-white lg:hover:text-white"
          >
            Privacy Policy
          </a>
        </li>
        <li>
          <a
            target="blank"
            href="/"
            className="text-sm text-lightBlue hover:text-normalBlue md:text-base lg:text-white lg:hover:text-white"
          >
            Blog
          </a>
        </li>
      </ul>
    </div>
  );
}
