import React from 'react';
import { useNavigate } from 'react-router-dom';

type Target = '_self' | '_blank' | '_parent' | '_top';

interface LinkProps {
  to: string;
  target?: Target;
  children: React.ReactNode;
}

const Link: React.FC<LinkProps> = ({ to, target = '_self', children }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (target === '_blank') {
      window.open(to, '_blank');
    } else {
      navigate(to);
    }
  };

  return (
    <span onClick={handleClick} className="hover:cursor-pointer">
      {children}
    </span>
  );
};

export default Link;
