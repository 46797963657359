import { Link, useLocation } from 'react-router-dom';
import DashIcon from './DashIcon';
import { Text } from 'shared/Text';
// chakra imports

interface SidebarLinksProps {
  routes: RoutesType[];
  onLinkClick: () => void;
}

export const SidebarLinks = (props: SidebarLinksProps): JSX.Element => {
  // Chakra color mode
  const location = useLocation();

  const { routes, onLinkClick } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (layout: string, path: string): boolean => {
    const regex = new RegExp(`${layout}.*${path}`);
    return regex.test(location.pathname);
  };

  let currentGroup = '';
  const createLinks = (routes: RoutesType[]): React.ReactNode[] => {
    return routes.map((route, index) => {
      const result: React.ReactNode[] = [];
      if (
        route.layout === '/admin' ||
        route.layout === '/auth' ||
        route.layout === '/rtl'
      ) {
        if (route.group && route.group !== currentGroup) {
          currentGroup = route.group;
          result.push(
            <div className="pl-0 my-5 text-[13px] md:text-sm">
              <Text key={`group-${currentGroup}`} extra="text-normalGray">{currentGroup}</Text>
            </div>,
          );
        }
        result.push(
          <Link
            key={index}
            to={route.layout + '/' + route.path}
            onClick={onLinkClick}
          >
            <div className="relative flex my-3 hover:cursor-pointer text-[13px] md:text-sm">
              <li
                className={`flex w-[90%] cursor-pointer items-center px-4 py-2 rounded-md ${
                  activeRoute(route.layout, route.path)
                  ? 'bg-lightBlue/40'
                  : ''
                }`}
                key={index}
              >
                <span
                  className={`${
                    activeRoute(route.layout, route.path)
                      ? 'font-bold text-darkBlue dark:text-white'
                    : 'font-medium text-darkBlue dark:text-lightGray'
                  }`}
                >
                  {route.icon ? route.icon : <DashIcon />}{' '}
                </span>
                <p
                  className={`leading-1 ml-4 flex ${
                    activeRoute(route.layout, route.path)
                    ? 'font-bold text-darkBlue dark:text-white'
                    : 'text-darkBlue dark:text-lightGray'
                  }`}
                >
                  {route.name}
                </p>
              </li>
              {activeRoute(route.layout, route.path) ? (
                <div className="absolute right-0 top-0 h-10 w-1 rounded-lg bg-darkBlue dark:bg-lightBlue/70" />
              ) : null}
            </div>
          </Link>,
        );
        return result;
      }
    });
  };
  // BRAND
  return <>{createLinks(routes)}</>;
};

export default SidebarLinks;
